import Radio from './Radio.vue'
import Checkbox from './Checkbox.vue'
import MultiCheckbox from './MultiCheckbox.vue'

const plugin = {
  install (Vue, {loadComponent = true} = {}) {
    Vue.component('input-radio', Radio)
    Vue.component('input-checkbox', Checkbox)
    Vue.component('input-multi-checkbox', MultiCheckbox)
  }
}

typeof window.Vue === 'function' && window.Vue.use(plugin)

export default plugin
