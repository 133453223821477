<template>
	<div
		:id="idName"
		@click="generate">
		<slot>
			Download {{name}}
		</slot>
	</div>
</template>

<script>
import download from "downloadjs";
export default {
  props: {
    // mime type [xls, csv]
    type: {
      type: String,
      default: "xls"
    },
    // Json to download
    data: {
      type: Array,
      required: false,
      default: null
    },
  },
};
</script>
