import Vue from 'vue'

// Load PLugins
import plugins from '@/plugins'
Vue.use(plugins)

// Load App
import "@/assets/scss/style.scss"
import App from './App.vue'

Vue.config.productionTip = false

// Router
import router from '@/router'
import store from '@/store'


import "./registerServiceWorker";

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
