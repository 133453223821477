import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import config from '@/config';
import layout from './modules/layout';
import cached from './modules/cached';
import session from './modules/session';

const modules = {
  layout,
  cached,
  session,
};

import app from './models/app';
import analytics from './models/analytics';
import user from './models/user';
import store from './models/manager.store';
import storeSearch from './models/manager.store.search';
import host from './models/manager.host';
import holiday from './models/manager.holiday';
import receipt from './models/luggage.receipt';
import luggage from './models/luggage.ticket';
import locker from './models/locker.ticket';
import delivery from './models/delivery.ticket';
import products from './models/service.ticket';
import staff from './models/staff';
import mail from './models/lugstay.mail';
import template from './models/content.template';
import page from './models/content.page';
import post from './models/content.post';
import review from './models/content.review';
import resource from './models/resource';
import invite from './models/campaign.invite';
import promotion from './models/promotion';
import coupon from './models/promotion.coupon';
import maillogs from './models/log.mailgun';
import settlement from './models/settlement';
import bankaccount from './models/settlement.bank.account';
import payment from './models/payment';
import keyword from './models/keyword';
import regions from './models/recommended.regions';
import transaction from './models/transaction';
import translation from './models/translation';
import tickets from './models/tickets';
import prepaidcard from './models/prepaid.card';
import agent from './models/agent';

const models = {
  app,
  analytics,
  user,
  store,
  storeSearch,
  host,
  agent,
  holiday,
  receipt,
  tickets,
  luggage,
  locker,
  delivery,
  products,
  staff,
  mail,
  template,
  page,
  post,
  review,
  resource,
  invite,
  prepaidcard,
  promotion,
  coupon,
  maillogs,
  settlement,
  bankaccount,
  payment,
  keyword,
  regions,
  transaction,
  translation,
};

const getters = {
  config: (state) => {
    return config;
  },
  isCollapsedSideBar: (state) => state.layout.isCollapsedSideBar,
  layoutMode: (state) => state.layout.layoutMode,
  expendedSideMenuItem: (state) => state.layout.expendedSideMenuItem,
  cachedViews: (state) => state.cached.cachedViews,
  isAuthenticated: (state) => state.session.isAuthenticated,
  profile: (state) => state.session.profile,
};

export default new Vuex.Store({
  modules: {
    ...modules,
    ...models,
  },
  getters: getters,
});
