import Loader from './Loader.vue'

const plugin = {
  install (Vue, {loadComponent = true} = {}) {
    Vue.component('loader', Loader)
  }
}

typeof window.Vue === 'function' && window.Vue.use(plugin)

export default plugin
