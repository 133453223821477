import _ from 'lodash'
import moment from 'moment-timezone'
import request from '@/utils/network'
import bankAccount from "@/store/models/settlement.bank.account"

const groups = [
  { label: "전체", value: "" },
  { label: "정산생성", value: "NEW" },
  { label: "정산요청", value: "PROGRESS" },
  { label: "정산완료", value: "SETTLED" },
  { label: "정산취소", value: "CANCELLED" }
]

const fields = [
  { key: 'uid', type: 'checked', label: '', listing: true },
  { key: 'uid', type: 'uid', label: '#', listing: true, primaryKey: true },

  { key: 'user_id', type: 'link', label: 'User ID', readonly: true, link: (item, { $router }) => {
    $router.push({
      name: 'EditUser',
      params: { uid: item.user_id }
    })
  } },

  { key: 'store_name', type: 'text', label: 'Store Name', listing: true, alias: (field, row) => {
    let value = row[field.key]
    try {
      let json = JSON.parse(value || '{}')
      return (json['ko'] || json['base'] || value || '')
    }
    catch(e) {}
    return value || ''
  }, hidden: true },

  { key: 'store_id', type: 'link', label: 'Store ID', readonly: true, link: (item, { $router }) => {
    $router.push({
      name: 'EditStore',
      params: { uid: item.store_id }
    })
  } },

  { key: 'exchange_rate', type: 'text', label: '환율', readonly: true },
  { key: 'expenses_unit', type: 'text', label: '비용계산 화폐단위', readonly: true },
  { key: 'expenses_per_order', type: 'text', label: '결제완료 주문당 제반비용(원)', readonly: true },
  { key: 'expenses_per_order_comment', type: 'text', label: '결제완료 주문당 제반비용 내용', readonly: true },
  { key: 'expenses_per_cancel', type: 'text', label: '취소당 제반비용(원)', readonly: true },
  { key: 'expenses_per_cancel_comment', type: 'text', label: '취소당 제반비용 내용', readonly: true },
  { key: 'expenses_etc', type: 'text', label: '기타 제반비용(원)', readonly: true },
  { key: 'expenses_etc_comment', type: 'text', label: '기타 제반비용 내용', readonly: true },
  { key: 'profit_rate', type: 'text', label: '수익률(%)', readonly: true },
  
  { key: 'total_unit', type: 'text', label: '계산 화폐단위', readonly: true },
  { key: 'total_ordered', type: 'text', label: '총 주문금액', readonly: true },
  { key: 'total_pending', type: 'text', label: '총 미처리금액', readonly: true },
  { key: 'total_paid', type: 'text', label: '총 결제금액', readonly: true },
  { key: 'total_cancelled', type: 'text', label: '총 취소금액', readonly: true },
  
  // { key: 'total_tax', type: 'text', label: '전체 결제금액의 세금', readonly: true },
  // { key: 'total_fee', type: 'text', label: '전체 결제금액의 fee', readonly: true },
  // { key: 'total_amount', type: 'text', label: '전체 결제금액의 공급가액', readonly: true },

  { key: 'total_count', type: 'text', label: '총 주문건수', readonly: true },
  { key: 'total_order_count', type: 'text', label: '총 결제건수', readonly: true },
  { key: 'total_cancel_count', type: 'text', label: '총 취소건수', readonly: true },

  { key: 'total_profit', type: 'text', label: '호스트 매출', readonly: true },
  
  { key: 'total_payment_fee', type: 'text', label: '결제 수수료 전체', readonly: true },
  { key: 'total_expenses_basic', type: 'text', label: '기본 제반비용 전체', readonly: true },
  { key: 'total_expenses_per_order', type: 'text', label: '결제완료 주문당 제반비용 전체', readonly: true },
  { key: 'total_expenses_per_cancel', type: 'text', label: '취소당 제반비용 전체', readonly: true },
  { key: 'total_expenses', type: 'text', label: '총 제반비용', readonly: true },

  { key: 'total_income', type: 'text', label: '호스트 수익', readonly: true },
  // { key: 'total_income_tax', type: 'text', label: '호스트 수익의 세금', readonly: true },
  // { key: 'total_income_amount', type: 'text', label: '호스트 수익의 공급가액', readonly: true },

  // { key: 'total_luggage_quantity', type: 'text', label: '결제된 총 작은물품 개수', readonly: true },
  // { key: 'total_luggage_over_quantity', type: 'text', label: '결제된 총 큰물품 개수', readonly: true },
 
  { key: 'settled_date_start', type: 'text', label: '정산 시작일', readonly: true },
  { key: 'settled_date_end', type: 'text', label: '정산 종료일', readonly: true },

  { key: 'settled_date', type: 'text', label: 'Settled Date', alias: (field, row) => {
    let date_start = row['settled_date_start']
    let date_end = row['settled_date_end']
    return `${date_start} ~ ${date_end}`
  }, listing: true, hidden: true },

  { key: 'settled_method', type: 'text', label: '정산 방법', listing: true, readonly: true },
  { key: 'settled_bank_owner_name', type: 'text', label: '예금주', readonly: true },
  { key: 'settled_bank_name', type: 'text', label: '은행', readonly: true },
  { key: 'settled_bank_account', type: 'text', label: '계좌', readonly: true },

  { key: 'settled_unit', type: 'text', label: '정산 통화', readonly: true },
  { key: 'settled_total', type: 'text', label: '정산금액', listing: true, readonly: true, alias: (feild, row) => {
    return row.settled_total ? row.settled_total + '' : ''
  }  },
  { key: 'extras', type: 'text', label: '알림톡 발송여부', complete_talk: true, hidden: true, alias: (feild, row) => {
    let extras = row['extras']
    return extras.complete_talk + '' || '0'
  } },
  { key: 'extras', type: 'text', label: '알림톡 발송여부', settlement_talk: true, hidden: true, alias: (feild, row) => {
    let extras = row['extras']
    return extras.settlement_talk + '' || '0'
  } },

  { key: 'status', type: 'text', label: '상태', listing: true, readonly: true,
    options: _.filter(groups, (group, index) => index > 0),
    locale: (value) => {
      let items = groups.filter(item => item.value === value)
      return items.length > 0 ? items[0].label : value
    }
  },

  { key: 'updated_at', type: 'field', dataType: 'datetime', label: 'Timestamp', format:'YYYY.MM.DD HH:mm:ss', listing: true, hidden: true },
  { key: 'created_at', type: 'field', dataType: 'datetime', label: 'Created At', format:'YYYY.MM.DD HH:mm:ss', readonly: true },
  { key: 'updated_at', type: 'field', dataType: 'datetime', label: 'Updated At', format:'YYYY.MM.DD HH:mm:ss', readonly: true },
  { key: 'action', type: 'action', label: 'Actions', listing: true, actions: ["view",
      {
        action: "manage",
        class: "btn-warning",
        label: "관리",
        onAction: (row, { $router, model }) => {
          $router.push({
            name: model.views.manage.name,
            params: model.views.manage.params(row)
          })
        }
      }
    ], hidden: true }
]

const modelName = "Settlement"

const modelData = {
  name: modelName,
  namespace: modelName.toLowerCase(),
  icon: 'archive'
}

const actions = {
  list: [],
  form: ['submit']
}

const model = {
  name: modelData.name,
  groups: groups,
  fields: fields,
  actions: actions,
  getters: {

  },
  types: {
    list: {
      action: `${modelData.namespace}/list`,
      method: 'get',
      endpoint: () => '/v2/admin/settlement/settlements'
    },
    // create: {
    //   action: `${modelData.namespace}/create`,
    //   method: 'post',
    //   endpoint: () => `/v2/admin/settlement/settlements`
    // },
    edit: {
      action: `${modelData.namespace}/edit`,
      method: 'put',
      endpoint: (options) => `/v2/admin/settlement/settlements/${options.uid}`
    },
    get: {
      action: `${modelData.namespace}/get`,
      method: 'get',
      endpoint: (options) => `/v2/admin/settlement/settlements/${options.uid}`
    },
    delete: {
      action: `${modelData.namespace}/delete`,
      method: 'delete',
      endpoint: (options) => `/v2/admin/settlement/settlements/${options.uid}`
    },
    request: {
      action: `${modelData.namespace}/request`,
      method: 'put',
      endpoint: (options) => `/v2/admin/settlement/settlements/${options.uid}/request`
    },
    complete: {
      action: `${modelData.namespace}/complete`,
      method: 'put',
      endpoint: (options) => `/v2/admin/settlement/settlements/${options.uid}/complete`
    },
    cancel: {
      action: `${modelData.namespace}/cancel`,
      method: 'put',
      endpoint: (options) => `/v2/admin/settlement/settlements/${options.uid}/cancel`
    },
    send:{ 
      action: `${modelData.namespace}/send`,
      method: 'post',
      endpoint: (options) => `/v2/admin/settlement/settlements/${options.uid}/send`
    },
    batchNew: {
      action: `${modelData.namespace}/batchNew`,
      method: 'post',
      endpoint: (options) => `/v2/admin/settlement/settlements/batch/schedule`
    },
    batchRequest: {
      action: `${modelData.namespace}/batchRequest`,
      method: 'put',
      endpoint: (options) => `/v2/admin/settlement/settlements/batch/request`
    },
    batchDelete: {
      action: `${modelData.namespace}/batchDelete`,
      method: 'delete',
      endpoint: (options) => `/v2/admin/settlement/settlements/batch/delete`
    },
    batchComplete: {
      action: `${modelData.namespace}/batchComplete`,
      method: 'put',
      endpoint: (options) => `/v2/admin/settlement/settlements/batch/complete`
    },
    batchCancel: {
      action: `${modelData.namespace}/batchCancel`,
      method: 'put',
      endpoint: (options) => `/v2/admin/settlement/settlements/batch/cancel`
    },
    batchSend:{ 
      action: `${modelData.namespace}/batchSend`,
      method: 'post',
      endpoint: (options)=> `/v2/admin/settlement/settlements/batch/send`
    },
    export: {
      action: `${modelData.namespace}/export`,
      method: 'get',
      endpoint: (options) => `/v2/admin/settlement/settlements/export`,
      fields: {
        '#': 'uid',

        '상점 ID': 'store_id',
        '상점명': {
          field: 'store_name',
          callback: (value) => {
            try {
              let json = JSON.parse(value || '{}')
              return (json['ko'] || json['base'] || value || '')
            }
            catch(e) {}
            return value || ''
          }
        },
        '상점 연락처': 'store_notify_phone',
        '정산 시작일': {
          field: 'settled_date_start',
          callback: (value) => {
            return moment(value).tz("Asia/Seoul").format("YYYY-MM-DD")
          }
        },
        '정산 종료일': {
          field: 'settled_date_end',
          callback: (value) => {
            return moment(value).tz("Asia/Seoul").format("YYYY-MM-DD")
          }
        },

        '달러 / 기간내 구매 건수': 'extras.usd.count',
        '달러 / 기간내 보관된 물품 개수': 'extras.usd.luggage_quantity',
        '달러 / 매출': 'extras.usd.paid',
        '달러 / PG 결제 수수료': 'extras.usd.payment_fee',

        '원화 / 기간내 구매 건수': 'extras.krw.count',
        '원화 / 기간내 보관된 물품 개수': 'extras.krw.luggage_quantity',
        '원화 / 매출': 'extras.krw.paid',
        '원화 / PG 결제 수수료': 'extras.krw.payment_fee',
        
        '총 / 기간내 구매 건수': 'total_count',
        '총 / 기간내 보관된 물품 개수': 'total_luggage_quantity',
        '총 / 매출': 'total_paid',
        '총 / PG 결제 수수료': 'total_payment_fee',

        '호스트 수익': 'total_profit',
        '주문당 제반비용 합계': 'total_expenses_per_order',
        '주문당 제반비용 비고': 'expenses_per_order_comment',
        '취소당 제반비용 합계': 'total_expenses_per_cancel',
        '취소당 제반비용 비고': 'expenses_per_cancel_comment',
        '기타 제반비용': 'expenses_etc',
        '기타 제반비용 비고': 'expenses_etc_comment',

        '환율': 'exchange_rate',

        '호스트 총 수익': 'total_income',
        '호스트 세액': 'total_income_tax',
        '호스트 공급가액': 'total_income_amount',

        '은행명': 'settled_bank_name',
        '계좌번호': 'settled_bank_account',
        '예금주': 'settled_bank_owner_name',

        '정산 상태': 'status',

        'created At': {
          field: 'created_at',
          callback: (value) => {
            return moment(value).tz("Asia/Seoul").format("YYYY-MM-DD HH:mm:ss")
          }
        },
        'updated At': {
          field: 'updated_at',
          callback: (value) => {
            return moment(value).tz("Asia/Seoul").format("YYYY-MM-DD HH:mm:ss")
          }
        }
      }
    },
    requestBulk: {
      action: `${modelData.namespace}/requestBulk`,
      method: 'get',
      endpoint: (options) => `/v2/admin/settlement/settlements/request/bulk`,
      fields: {
        '은행코드': {
          field: 'settled_bank_code',
          callback: (value) => { 
            // return `${value}\n` 
            return value + ''
          }
        },
        '계좌번호': {
          field: 'settled_bank_account',
          callback: (value) => { 
            return value + ''
          }
        },
        '세전상점수익': 'total_profit',
        '이체금액': 'settled_total',
        '상점명': 'store_name_localed',
        '상점연락처': {
          field: 'store_notify_phone',
          callback: (value) => {
            return value + '';
          }
        },
        '정산방식': 'settled_method',
        '정산기간': {
          field: 'settled_date_start',
          callback: (value) => {
            const str = value + '';
            const result = str.split('-')[1] + '월';
            return result;
          }
        },
        '정산 횟수': {
          field: 'settled_count',
          callback: (value) => {
            return value + '';
          }
        }
      }
    }
  },
  views: {
    list: { name: `List${modelData.name}`, title: `${modelData.name} List` },
    create: { name: `Create${modelData.name}`, title: `Create ${modelData.name}`, params: (item) => { return { uid: item.uid } } },
    view: { name: `View${modelData.name}`, title: `View ${modelData.name}`, params: (item) => { return { uid: item.uid } } },
    manage: { name: `Manage${modelData.name}`, title: `Manage ${modelData.name}`, params: (item) => { return { uid: item.uid } } },
    result: { name: `Result${modelData.name}`, title: `Result ${modelData.name}`, params: (item) => { return { uid: item.uid } } },
  }
}

export default {
  namespaced: true,
  model: model,
  routes: [
    {
      path: `/${modelData.namespace}`,
      component: () => import('@/views/layouts/Layout'),
      redirect: `/${modelData.namespace}/bank/account/list`,
      name: `${modelData.name}`,
      meta: { title: `${modelData.name}`, icon: `${modelData.icon}`, admin_level: 2 },
      children: [
        ...bankAccount.routes,
        {
          path: 'view/:uid(\\d+)',
          component: () => import('@/views/basic/Form'),
          name: model.views.view.name,
          meta: { mode: 'view', title: model.views.view.title, model: model, noCache: true },
          hidden: true
        },
        {
          path: 'manage/:uid(\\d+)',
          component: () => import('@/views/pages/settlement/Settlement'),
          name: model.views.manage.name,
          meta: { mode: 'manage', title: model.views.manage.title, model: model, noCache: true },
          hidden: true
        },
        {
          path: 'list',
          component: () => import('@/views/pages/settlement/List'),
          name: model.views.list.name,
          meta: { mode: 'list', title: model.views.list.title, model: model, noCache: true }
        }
      ]
    },
    {
      path: `/${modelData.namespace}/manage/:uid(\\d+)/result`,
      component: () => import('@/views/pages/settlement/Result'),
      name: model.views.result.name,
      meta: { mode: 'result', title: model.views.result.title, model: model, noCache: true },
      hidden: true
    },
  ],
  state: {

  },
  mutations: {

  },
  getters: {

  },
  actions: {
    list({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let { group } = options
          let response = await request[model.types.list.method](model.types.list.endpoint(options), { params: options })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    // create({commit, rootGetters}, options) {
    //   return new Promise( async (resolve, reject) => {
    //     try {
    //       let response = await request[model.types.create.method](model.types.create.endpoint(options), options)
    //       let { error, data } = response.data
    //       if (error) {
    //         return reject(new Error(error))
    //       }
    //       resolve(data)
    //     }
    //     catch(e) {
    //       reject(e)
    //     }
    //   })
    // },
    view({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.view.method](model.types.view.endpoint(options), options)
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    get({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.get.method](model.types.get.endpoint(options), { params: options })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    delete({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.delete.method](model.types.delete.endpoint(options), { params: options })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    request({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.request.method](model.types.request.endpoint(options), options)
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    complete({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.complete.method](model.types.complete.endpoint(options), options)
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    cancel({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.cancel.method](model.types.cancel.endpoint(options), { params: options })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    send({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.send.method](model.types.send.endpoint(options), options)
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    batchNew({ commit, rootGetters }, options) {
      return new Promise(async (resolve, reject) => {
        try {
          let response = await request[model.types.batchNew.method](model.types.batchNew.endpoint(options), options);
          let { error, data } = response.data;
          
          if (error) {
            return reject(new Error(error));
          }
          resolve(data);
        } catch (e) {
          reject(e);
        }
      })
    },
    batchRequest({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.batchRequest.method](model.types.batchRequest.endpoint(options), options)
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    batchDelete({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.batchDelete.method](model.types.batchDelete.endpoint(options), { params: options })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    batchComplete({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.batchComplete.method](model.types.batchComplete.endpoint(options), options)
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    batchCancel({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.batchCancel.method](model.types.batchCancel.endpoint(options), options)
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    batchSend({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {      
        try {
          let response = await request[model.types.batchSend.method](model.types.batchSend.endpoint(options), options)     
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))     
          }
          resolve(data)
        }
        catch(e) {  
          reject(e)
        }    
      })
    },
    requestBulk({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.requestBulk.method](model.types.requestBulk.endpoint(options), { params: options })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    export({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.export.method](model.types.export.endpoint(options), { params: options })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    }
  }
}
