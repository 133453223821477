import "@/assets/icons"
import VueSvgIcon from "./lib/SvgIcon"

const plugin = {
  install (Vue, {loadComponent = true} = {}) {
    Vue.component('svg-icon', VueSvgIcon)
  }
}

typeof window.Vue === 'function' && window.Vue.use(plugin)

export default plugin
