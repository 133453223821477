import _ from 'lodash'
import request from '@/utils/network'

const options = [
  { key : 'active', value: 'active' },
  { key : 'translated', value: 'translated' }
]

const fields = [
  { key: 'uid', type: 'uid', label: '#', listing: true, primaryKey: true },
  { key: 'language_code', type: 'text', label: 'Key', listing: true, editable: false, class: 'ellipsis' },
  { key: 'ko', type: 'text', label: "KO"},
  { key: 'ko', type: 'text', label: "KO", listing: true, hidden: true,
    alias: (field, row) => {
      try{
        let value = ''

        if(row.language_data){
          value = JSON.parse(row.language_data)
          return value.ko
        }
        
        return value
      }
      catch(e){
        return ''
      }
    },
  class: 'ellipsis'},
  { key: 'en', type: 'text', label: "EN", listing: true, hidden: true,
    alias: (field, row) => {
      try{
        let value = ''

        if(row.language_data){
          value = JSON.parse(row.language_data)
          return value.en
        }
        
        return value
      }
      catch(e){
        return ''
      }
    }, 
  class: 'ellipsis' },
  { key: 'cn', type: 'text', label: "CN", hidden: true,
    alias: (field, row) => {
      try{
        let value = ''

        if(row.language_data){
          value = JSON.parse(row.language_data)
          return value.cn
        }
        
        return value
      }
      catch(e){
        return ''
      }
    }, 
  class: 'ellipsis' },
  { key: 'hk', type: 'text', label: "HK", hidden: true,
    alias: (field, row) => {
      try{
        let value = ''

        if(row.language_data){
          value = JSON.parse(row.language_data)
          return value.hk
        }
        
        return value
      }
      catch(e){
        return ''
      }
    }, 
  class: 'ellipsis' },
  { key: 'jp', type: 'text', label: "JP", hidden: true,
    alias: (field, row) => {
      try{
        let value = ''

        if(row.language_data){
          value = JSON.parse(row.language_data)
          return value.jp
        }
        
        return value
      }
      catch(e){
        return ''
      }
    }, 
  class: 'ellipsis' },
  { key: 'vn', type: 'text', label: "VN", hidden: true,
    alias: (field, row) => {
      try{
        let value = ''

        if(row.language_data){
          value = JSON.parse(row.language_data)
          return value.vn
        }
        
        return value
      }
      catch(e){
        return ''
      }
    }, 
  class: 'ellipsis' },
  { key: 'active', type: 'checkbox', title: '사용 여부', label: "사용중", falseLabel: '사용 안함', options: options[0], listing: false, hidden: true,  },
  { key: 'active', type: 'text', label: "Active", listing: true, hidden: true,
    alias: (field, row) => {
      if(row.active == 1){
        return 'O'
      }else if(row.active == 0){
        return 'X'
      }

      return ''
    } 
  },
  { key: 'translated', type: 'checkbox', title: '번역 여부', label: "번역됨", falseLabel: '번역 안됨', options: options[1], listing: false, hidden: true, },  
  { key: 'translated', type: 'text', label: "Translated", listing: true, hidden: true,
    alias: (field, row) => {
      if(row.translated == 1){
        return 'O'
      }else if(row.translated == 0){
        return 'X'
      }

      return ''
    } 
  },
  { key: 'updated_at', type: 'field', dataType: 'datetime', label: 'Updated At', format: 'YYYY-MM-DD HH:mm', readonly: true },
  { key: 'actions', type: 'action', actions: [
    { action: 'manage',
      class: 'btn-info',
      label: '관리',
      onAction: (row, { $router, model }) => {
        $router.push({
          name: model.views.manage.name,
          params: model.views.manage.params(row),
        });
      }
    }], label: '관리', listing: true, hidden: true }
]

const modelName = "Translation"

const modelData = {
  name: modelName,
  namespace: modelName.toLowerCase(),
  icon: 'language'
}

const actions = {
  list: ['exportJson', 'create'],
  form: ['submit', 'toManage']
}

const model = {
  name: modelData.name,
  fields: fields,
  actions: actions,
  types: {
    list: {
      action: `${modelData.namespace}/list`,
      method: 'get',
      endpoint: () => '/v2/admin/translation'
    },
    create: {
      action: `${modelData.namespace}/create`,
      method: 'post',
      endpoint: () => `/v2/admin/translation`
    },
    edit: {
      action: `${modelData.namespace}/edit`,
      method: 'put',
      endpoint: (options) => `/v2/admin/translation/${options.uid}`
    },
    get: {
      action: `${modelData.namespace}/get`,
      method: 'get',
      endpoint: (options) => `/v2/admin/translation/${options._id}`
    },
    delete: {
      action: `${modelData.namespace}/delete`,
      method: 'delete',
      endpoint: (options) => `/v2/admin/translation/${options.uid}`
    },
    exportJson: {
      action: `${modelData.namespace}/export`,
      method: 'get',
      endpoint: () => `/v2/admin/translation/export`,
    },
    batch: {
      action: `${modelData.namespace}/batch`,
      method: 'post',
      endpoint: () =>  `v2/admin/translation/batch`
    }
  },
  views: {
    list: { name: `List${modelData.name}`, title: `${modelData.name} List` },
    create: { name: `Create${modelData.name}`, title: `Create ${modelData.name}`},
    // edit: { name: `Edit${modelData.name}`, title: `Edit ${modelData.name}`, params: (item) => { return { _id: item._id } } },
    batch: { name: `Batch${modelData.name}`, title: `Batch ${modelData.name}` },
    manage: { name: `Manage${modelData.name}`, title: `Manage ${modelData.name}`, params: (item) => {return {_id: item.uid}}}
  }
}

export default {
  namespaced: true,
  model: model,
  routes: [
    {
      path: `/${modelData.namespace}`,
      component: () => import('@/views/layouts/Layout'),
      redirect: `/${modelData.namespace}/list`,
      name: `${modelData.name}`,
      meta: { title: `${modelData.name}`, icon: `${modelData.icon}`, admin_level: 2 },
      children: [
        {
          path: 'create',
          component: () => import('@/views/basic/Form'),
          name: model.views.create.name,
          meta: { mode: 'create', title: model.views.create.title, model: model, noCache: true }
        },
        {
          path: 'edit/:_id(\\d+)',
          // component: () => import('@/views/basic/Form'),
          // name: model.views.edit.name,
          // meta: { mode: 'edit', title: model.views.edit.title, model: model, noCache: true },
          hidden: true
        },
        {
          path: 'manage/:_id(\\d+)',
          component: () => import('@/views/pages/translation/Manage'),
          name: model.views.manage.name,
          meta: { mode: 'manage', title: model.views.manage.title, model: model, noCache: true },
          hidden: true,
        },
        {
          path: 'list',
          component: () => import('@/views/basic/List'),
          name: model.views.list.name,
          meta: { mode: 'list', title: model.views.list.title, model: model, noCache: true }
        },
        {
					path: 'batch',
					component: () => import('@/views/pages/translation/Batch'),
					name: model.views.batch.name,
					meta: { mode: 'batch', title: model.views.batch.title, model: model, noCache: true },
				},
      ]
    }
  ],
  state: {
    cached: {},
    items: []
  },
  mutations: {
    LIST(state, { page, size, items }) {
      items.forEach(item => {
        state.cached[item._id] = item
      })

      state.items = items
    },
    CREATE(state, { item }) {

    },
    EDIT(state, { item }) {

    },
    GET(state, { item }) {
      state.cached[item._id] = item
    },
    DELETE(state, { item }){

    }
  },
  actions: {
    list({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let { group } = options
          let response = await request[model.types.list.method](model.types.list.endpoint(options), { params: options })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          commit('LIST', data)
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    create({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.create.method](model.types.create.endpoint(options), options )
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          commit('CREATE', data)
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    edit({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.edit.method](model.types.edit.endpoint(options), options)
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          commit('EDIT', { item: data })
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    get({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.get.method](model.types.get.endpoint(options), { params: options })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          commit('GET', { item: data })
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    delete({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.delete.method](model.types.delete.endpoint(options), { params: options })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          commit('DELETE', { item: data })
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    batch({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {        
          let response = await request[model.types.batch.method](model.types.batch.endpoint(options), options)
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }

          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    export({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {        
          let response = await request[model.types.exportJson.method](model.types.exportJson.endpoint(options), options)
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }

          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
  }
}
