import request from '@/utils/network';

const fields = [
	{ key: 'uid', type: 'uid', label: '#', listing: true, primaryKey: true },
	{ key: 'resource_type', type: 'text', label: 'Type', listing: true },
	{ key: 'resource_key', type: 'text', label: 'Key' },
	{ key: 'resource_tag', type: 'text', label: 'Tag', listing: true },
	{ key: 'resource_status', type: 'text', label: 'Status', listing: true },
	{ key: 'resource_hash', type: 'text', label: 'Hash' },
	{ key: 'resource_url', type: 'resource', label: 'Resource', listing: true },
	{ key: 'resource_order', type: 'text', label: 'Order' },
	{ key: 'filetype', type: 'text', label: 'File Type' },
	{ key: 'filesize', type: 'text', label: 'File Size' },
	{ key: 'filename', type: 'text', label: 'File Name' },
	{ key: 'extension', type: 'text', label: 'File Extension' },
	{ key: 'natural_width', type: 'text', label: 'Natural Width' },
	{ key: 'natural_height', type: 'text', label: 'Natural Height' },
	{ key: 'uploader', type: 'text', label: 'Uploader' },
	{ key: 'permission', type: 'text', label: 'Permission' },
	{ key: 'action', type: 'action', label: 'Actions', listing: true, actions: ['view'], hidden: true },
];

const modelName = 'Resource';

const modelData = {
	name: modelName,
	namespace: modelName.toLowerCase(),
	icon: 'save',
};

const actions = {
	list: ['create'],
	form: ['submit'],
};

const model = {
	name: modelData.name,
	fields: fields,
	actions: actions,
	types: {
		list: {
			action: `${modelData.namespace}/list`,
			method: 'get',
			endpoint: () => '/v2/admin/resources',
		},
		upload: {
			action: `${modelData.namespace}/upload`,
			method: 'post',
			endpoint: () => `/v2/admin/resources/upload`,
		},
		get: {
			action: `${modelData.namespace}/get`,
			method: 'get',
			endpoint: (options) => `/v2/admin/resources/${options.uid}`,
		},
		delete: {
			action: `${modelData.namespace}/delete`,
			method: 'delete',
			endpoint: (options) => `/v2/admin/resources/${options.uid}`,
		},
	},
	views: {
		list: { name: `List${modelData.name}`, title: `${modelData.name} List` },
		view: {
			name: `View${modelData.name}`,
			title: `View ${modelData.name}`,
			params: (item) => {
				return { uid: item.uid };
			},
		},
	},
};

export default {
	namespaced: true,
	model: model,
	routes: [
		{
			path: `/${modelData.namespace}`,
			component: () => import('@/views/layouts/Layout'),
			redirect: model.types.list,
			name: `${modelData.name}`,
			meta: { title: `${modelData.name}`, icon: modelData.icon, admin_level: 2 },
			children: [
				{
					path: 'list',
					component: () => import('@/views/basic/List'),
					name: model.views.list.name,
					meta: { mode: 'list', title: model.views.list.title, model: model, noCache: true },
				},
				{
					path: ':uid(\\d+)',
					component: () => import('@/views/basic/Form'),
					name: model.views.view.name,
					meta: { mode: 'view', title: model.views.view.title, model: model, noCache: true },
					hidden: true,
				},
			],
		},
	],
	state: {
		cached: {},
		items: [],
	},
	mutations: {
		LIST(state, { page, size, items }) {
			items.forEach((item) => {
				state.cached[item.uid] = item;
			});

			state.items = items;
		},
		CREATE(state, { item }) {},
		DELETE(state, { item }) {},
		GET(state, { item }) {
			state.cached[item.uid] = item;
		},
	},
	actions: {
		list({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.list.method](model.types.list.endpoint(options), { params: options });
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					commit('LIST', data);
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},

		upload({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.upload.method](model.types.upload.endpoint(options), options);
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					commit('CREATE', data);
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},

		get({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.get.method](model.types.get.endpoint(options), { params: options });
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					commit('GET', { item: data });
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},

		delete({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.delete.method](model.types.delete.endpoint(options), { params: options });
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					commit('DELETE', { item: data });
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
	},
};
