import request from '@/utils/network';
import utils from '@/utils';
import moment from 'moment';

const groups = [
	{ label: '전체', value: '' },
	{ label: '예약생성', value: 'NEW' },
	{ label: '예약완료', value: 'RESERVE' },
	{ label: '체크인완료', value: 'PROGRESS' },
	{ label: '결제완료', value: 'PAID' },
	{ label: '예약오류', value: 'EXCEPTION' },
	{ label: '예약취소', value: 'CANCELLED' },
	{ label: '예약마감', value: 'CLOSED' },
	{ label: '정산완료', value: 'SETTLED' },
];

const cancel_reasons = [
	{ label: '날짜를 잘못 입력했어요', value: "cancel_reason.misinput-date"},
	{ label: '시간을 잘못 입력했어요.', value: "cancel_reason.misinput-time"},
	{ label: '보관 상점을 변경하고 싶어요.', value: "cancel_reason.wrong-store"},
	{ label: '일정이 변경되었어요.', value: "cancel_reason.plan-changed"},
	{ label: '호스트가 예약 취소를 원해요.', value: "cancel_reason.host-unavailable"},
	{ label: '기타.', value: "cancel_reason.etc"},
]

const fields = [
	{ key: 'uid', type: 'uid', label: '#', listing: true, primaryKey: true, tab: '*' },
	{ key: 'product_type', label: '서비스 타입', type: 'text', listing: true, tab: 'info',
		alias: (field, row) => {
			if(!row.product_type) return;

			if(row.product_type.indexOf('luggage') > -1){
				return '물품보관'
			}else if(row.product_type.indexOf('delivery') > -1){
				return '배송'
			}
		}
	},
	{
		key: 'reserve_name',
		type: 'link',
		label: '예약자',
		alias: (field, row) => {
			return `${utils.value(row, 'reserve.user_first_name') || ''} ${utils.value(row, 'reserve.user_last_name') || ''}`;
		},
		listing: true,
		link: (item, { $router }) => {
			$router.push({
				name: 'EditUser',
				params: { uid: item.user_id },
			});
		},
	},
	{
		key: 'store_name_luggage',
		type: 'link',
		label: '상점명',
		alias: (field, row) => {
			return utils.value(row, 'product_data.store_name');
		},
		link: (item, {$router}) => {
			$router.push({
				name: 'EditStore',
				params: {uid: item.store_id}
			})
		},
		condition: item => item.product_type ? item.product_type.indexOf('luggage') > -1 : false
	},
	{
		key : 'sender_store_name',
		label: '출발 상점',
		type: 'link',
		description: '',
		readonly: true,
		link: (item, { $router }) => {
			$router.push({
				name: 'EditStore',
				params: {
					uid : utils.value(item, 'product_data.sender_store_id')
				}
			})
		},
		alias: (field, row) => {
			return utils.value(row, 'product_data.sender_store_name')
		},
		condition: item => parseInt(utils.value(item, 'product_data.sender_store_id')) > 0 && item.product_type.indexOf('delivery') > -1
	},
	{
		key : 'sender_place',
		label: '출발지',
		type: 'text',
		description: '',
		readonly: true,
		alias: (field, row) => {
			return utils.value(row, 'product_data.sender_place')
		},
		condition: item =>  parseInt(utils.value(item, 'product_data.sender_store_id')) < 1 && item.product_type.indexOf('delivery') > -1
	},
	{
		key : 'recipient_store_name',
		label: '도착 상점',
		type: 'link',
		description: '',
		readonly: true,
		link: (item, { $router }) => {
			$router.push({
				name: 'EditStore',
				params: {
					uid : utils.value(item, 'product_data.recipient_store_id')
				}
			})
		},
		alias: (field, row) => {
			return utils.value(row, 'product_data.recipient_store_name')
		},
		condition: item => parseInt(utils.value(item, 'product_data.recipient_store_id')) > 0 && item.product_type.indexOf('delivery') > -1
	},
	{
		key : 'recipient_place',
		label: '도착지',
		type: 'text',
		description: '',
		readonly: true,
		alias: (field, row) => {
			return utils.value(row, 'product_data.recipient_place')
		},
		condition: item =>  parseInt(utils.value(item, 'product_data.recipient_store_id')) < 1 && item.product_type.indexOf('delivery') > -1
	},
	{
		key: 'used_at',
		type: 'text',
		label: '사용처',
		alias: (field, row) => {
			if(!row.product_type) return;

			if (row.product_type.indexOf('luggage') > -1) {
				return utils.value(row, 'product_data.store_name')
			} 
			else if (row.product_type.indexOf('delivery') > -1) {
				let used_at = ''

				used_at = used_at + (utils.value(row, 'product_data.sender_store_name') || '*')
				used_at += ' - '
				used_at = used_at + (utils.value(row, 'product_data.recipient_store_name') || '*')

				return used_at
			}
		},
		listing: true,
		hidden: true
	},
	{
		key: 'usage_time',
		type: 'text',
		label: '이용시간',
		alias: (field, row) => {
			let start, end;

			if (utils.value(row, 'product_data.datetime_check_in')){
				start = moment(utils.value(row, 'product_data.datetime_check_in')).format('YYYY-MM-DD HH:mm')
			}else if (utils.value(row, 'datetime_check_in')){
				start = moment(utils.value(row, 'datetime_check_in')).format('YYYY-MM-DD HH:mm')
			}

			if (utils.value(row, 'product_data.datetime_check_out')){
				end = moment(utils.value(row, 'product_data.datetime_check_out')).format('YYYY-MM-DD HH:mm')
			} else if (utils.value(row, 'datetime_check_out')){
				end = moment(utils.value(row, 'datetime_check_out')).format('YYYY-MM-DD HH:mm')
			}

			if (!start) return '예약중'
			else if (!end) return `${start} - 이용중`
			else return `${start} ~ ${end}`
		},
		listing: true,
		hidden: true,
	},
	{
		key: 'payment_total',
		type: 'text',
		label: '결제금액',
		hidden: true,
		alias: (field, row) => {
			if(row.payment_total){
				return row.payment_total + '₩';
			}
		},
		listing: true,
	},
	{
		key: 'issuer',
		type: 'text',
		label: '발급처',
		alias: (field, row) => {
			if(row.product_data.issuer){
				return row.product_data.issuer
			}
		},
		listing: true,
		hidden: true,
	},
	{
		key: 'reserve',
		type: 'extra-editor',
		label: '예약자 정보',
		fields: [
			{ name: 'user_first_name', type: 'text', label: '이름', description: '', readonly: true },
			{ name: 'user_last_name', type: 'text', label: '성', description: '', readonly: true },
			{ name: 'user_email', type: 'text', label: '이메일', description: '', readonly: true },
			{ name: 'user_iso_code', type: 'text', label: 'ISO Code', description: '', readonly: true },
			{ name: 'user_dial_code', type: 'text', label: 'Dial Code', description: '', readonly: true },
			{ name: 'user_phone', type: 'text', label: '전화번호', description: '', readonly: true },
		],
		tab: 'reserve',
	},
	{ key: 'reserve_datetime', type: 'text', label: '예약시각', readonly: true, tab: 'reserve', alias: (field, row) => `${moment(row.reserve_date_start).format('YYYY-MM-DD HH:mm')} ~ ${moment(row.reserve_date_end).format('YYYY-MM-DD HH:mm')}` },
	{ key: 'usage_datetime', type: 'text', label: '이용시각', readonly: true, tab: 'reserve',
		alias: (field, row) => {
			let start, end;

			if (utils.value(row, 'product_data.datetime_check_in')){
				start = moment(utils.value(row, 'product_data.datetime_check_in')).format('YYYY-MM-DD HH:mm')
			}else if (utils.value(row, 'datetime_check_in')){
				start = moment(utils.value(row, 'datetime_check_in')).format('YYYY-MM-DD HH:mm')
			}

			if (utils.value(row, 'product_data.datetime_check_out')){
				end = moment(utils.value(row, 'product_data.datetime_check_out')).format('YYYY-MM-DD HH:mm')
			} else if (utils.value(row, 'datetime_check_out')){
				end = moment(utils.value(row, 'datetime_check_out')).format('YYYY-MM-DD HH:mm')
			}

			if (!start) return '예약중'
			else if (!end) return `${start} - 이용중`
			else return `${start} ~ ${end}`
		}
	},
	{ key: 'reserve_quantity', type: 'text', label: 'Small 물품', readonly: true, tab: ['reserve'] },
	{ key: 'reserve_over_quantity', type: 'text', label: 'Large 물품', readonly: true, tab: ['reserve'] },
	{ key: 'campaign_invite_code', type: 'text', label: 'Invite 코드', readonly: true, tab: ['reserve'] },
	{ key: 'billing_id', type: 'text', label: '빌링 ID', description: '', readonly: true, tab: 'payment', alias: (field, row) => utils.value(row, 'billing.billing_id')},
	{ key: 'payment_method', type: 'text', label: '결제 수단', readonly: true, tab: 'payment' },
	{ key: 'paid_unit', type: 'text', label: '금액 단위', readonly: true, tab: ['info', 'payment'] },
	{ key: 'predicted_amount', type: 'text', label: '예상금액', readonly: true, tab: ['payment'] },
	{ key: 'payment_origin', type: 'text', label: '초기 금액', readonly: true, tab: 'payment' },
	{ key: 'payment_discount', type: 'text', label: '할인 금액', readonly: true, tab: 'payment' },
	{ key: 'paid_total', type: 'text', label: '결제 금액', readonly: true, tab: ['info', 'payment'] },
	{ key: 'base_tid', type: 'link', label: 'Base TID', description: '', readonly: true, tab: 'payment',
		link: (item, {$router}) => {
			if(!item.base_tid) return;

			$router.push({
				name: 'ListTransaction',
				query: {
					filter : 'id',
					keyword : item.base_tid.toString()
				}
			})
		}
	},
	{ key: 'extra_tid', type: 'link', label: 'Extra TID', description: '', readonly: true, tab: 'payment',
		link: (item, {$router}) => {
			if(!item.extra_tid) return;

			$router.push({
				name: 'ListTransaction',
				query: {
					filter: 'id',
					keyword: item.extra_tid.toString()
				}
			})
		}
	},
	{
		key: 'promotion_id',
		type: 'link',
		label: '프로모션 ID',
		description: '',
		readonly: true,
		tab: 'payment',
		link: (item, {$router}) => {
			if(!utils.value(item, "promotion_id")) return;

			$router.push({
				name: 'EditPromotion',
				params: {uid: utils.value(item, "promotion_id")}
			})
		}
	},
	{ key: 'promotion_discount_code', type: 'text', label: '할인 코드', readonly: true, tab: 'payment' },
	{ key: 'promotion_coupon_code', type: 'text', label: '할인 쿠폰 코드', readonly: true, tab: 'payment' },
	{
		key: 'status',
		type: 'text',
		label: '상태',
		listing: true,
		readonly: true,
		options: _.filter(groups, (group, index) => index > 0),
		locale: (value) => {
			let item = groups.find((item) => item.value === value);
			return item ? item.label : value;
		},
		alias: (field, row) => {
			let value = row.status;
			let item = groups.find((item) => item.value === value);
			return item ? item.label : value;
		}
	},

	{ key: 'paid_at', type: 'field', dataType: 'datetime', label: '구매일', format: 'YYYY.MM.DD HH:mm:ss', readonly: true },
	{ key: 'created_at', type: 'field', dataType: 'datetime', label: '생성일', listing: true, format: 'YYYY.MM.DD HH:mm:ss', readonly: true },
	{ key: 'updated_at', type: 'field', dataType: 'datetime', label: '수정일', format: 'YYYY.MM.DD HH:mm:ss', readonly: true },
	{ key: 'cancelled_at', type: 'field', dataType: 'datetime', label: '취소일', format: 'YYYY.MM.DD HH:mm:ss', readonly: true },
	{ key: 'cancel_reason', type: 'text',  label: '취소 사유', readonly: true,
	    alias: (field, row) => {
		    const cancel_reason = cancel_reasons.find( o => o.value == row.cancel_reason);

		    return cancel_reason ? cancel_reason.label : row.cancel_reason
	    }
	},
	{ key: 'cancel_reason_detail', type: 'text',  label: '취소 사유 상세', readonly: true },
	// { key: 'refunded_at', type: 'field', dataType: 'datetime', label: '환불일', format: 'YYYY.MM.DD HH:mm:ss', readonly: true },
	{
		key: 'action',
		type: 'action',
		label: 'Actions',
		listing: true,
		actions: [
			'view'
		],
		hidden: true,
	},
];

const modelName = 'Receipt';

const modelData = {
	name: modelName,
	namespace: modelName.toLowerCase(),
	icon: 'receipt',
};

const actions = {
	list: ['create'],
	form: ['submit'],
};

const model = {
	name: modelData.name,
	fields: fields,
	actions: actions,
	tabs: [
		{ id: 'info', name: 'Info' },
		{ id: 'reserve', name: 'Reserve' },
		{ id: 'payment', name: 'Payment' }
	],
	groups: groups,
	types: {
		list: {
			action: `${modelData.namespace}/list`,
			method: 'get',
			endpoint: () => '/v2/admin/luggage/receipts',
		},
		get: {
			action: `${modelData.namespace}/get`,
			method: 'get',
			endpoint: (options) => `/v2/admin/luggage/receipts/${options.uid}`,
		},
		complete: {
			action: `${modelData.namespace}/complete`,
			method: 'put',
			endpoint: (options) => `/v2/admin/luggage/receipts/${options.uid}/complete`,
		},
		change: {
			action: `${modelData.namespace}/change`,
			method: 'put',
			endpoint: (options) => `/v2/admin/luggage/receipts/${options.uid}/change`,
		},
		cancel: {
			action: `${modelData.namespace}/cancel`,
			method: 'put',
			endpoint: (options) => `/v2/admin/luggage/receipts/${options.uid}/cancel`,
		},
	},
	views: {
		list: { name: `List${modelData.name}`, title: `${modelData.name} List` },
		view: {
			name: `View${modelData.name}`,
			title: `View ${modelData.name}`,
			params: (item) => {
				return { uid: item.uid };
			},
		},
		manage: {
			name: `Manage${modelData.name}`,
			title: `Manage ${modelData.name}`,
			params: (item) => {
				return { uid: item.uid };
			},
		},
	},
};

export default {
	namespaced: true,
	model: model,
	routes: [
		{
			path: `/${modelData.namespace}`,
			component: () => import('@/views/layouts/Layout'),
			redirect: `/${modelData.namespace}/list`,
			name: `${modelData.name}`,
			meta: { title: `${modelData.name}`, icon: `${modelData.icon}`, admin_level: 2 },
			children: [
				{
					path: 'view/:uid(\\d+)',
					component: () => import('@/views/basic/Form'),
					name: model.views.view.name,
					meta: { mode: 'view', title: model.views.view.title, model: model, noCache: true },
					hidden: true,
				},
				{
					path: 'manage/:uid(\\d+)',
					component: () => import('@/views/pages/receipt/Manage'),
					name: model.views.manage.name,
					meta: { mode: 'manage', title: model.views.manage.title, model: model, noCache: true },
					hidden: true,
				},
				{
					path: 'list',
					component: () => import('@/views/basic/List'),
					name: model.views.list.name,
					meta: { mode: 'list', title: model.views.list.title, model: model, noCache: true },
				},
			],
		},
	],
	state: {
		cached: {},
		items: [],
	},
	mutations: {
		LIST(state, { page, size, items }) {
			items.forEach((item) => {
				state.cached[item.uid] = item;
			});

			state.items = items;
		},
		GET(state, { item }) {
			state.cached[item.uid] = item;
		},
	},
	actions: {
		list({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let { group } = options;
					let response = await request[model.types.list.method](model.types.list.endpoint(options), { params: { status: group, ...options } });
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					commit('LIST', data);
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		get({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.get.method](model.types.get.endpoint(options), { params: options });
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					commit('GET', { item: data });
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		change({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.change.method](model.types.change.endpoint(options), options);
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		complete({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.complete.method](model.types.complete.endpoint(options), options);
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		cancel({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.cancel.method](model.types.cancel.endpoint(options), options);
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
	},
};
