import _ from 'lodash'
import request from '@/utils/network'

const types = [
  { label: "Master", value: "master" },
  { label: "Manager", value: "manager" },
  { label: "Staff", value: "staff" }
]

const fields = [
  { key: '_id', type: 'uid', label: '#', listing: true, primaryKey: true },
  { key: 'admin_id', type: 'text', label: '아이디', listing: true, editable: false },
  { key: 'admin_password', type: 'text', secure: true, label: '비밀번호', },
  { key: 'admin_name', type: 'text', label: '이름', listing: true },
  { key: 'admin_type', type: 'select', label: '구분', listing: true,
    options: types, defaultValue: 'staff',
    locale: (value) => {
      let locales = _.filter(types, type => type.value === value )
      return locales.length > 0 ? locales[0].label : ''
    }
  },
  { key: 'admin_position', type: 'text', label: '직책', listing: true, optional: true },
  { key: 'admin_department', type: 'text', label: '부서', listing: true, optional: true },
  { key: 'actions', type: 'action', actions: ['edit', 'delete'], label: '관리', listing: true, hidden: true }
]

const modelName = "Staff"

const modelData = {
  name: modelName,
  namespace: modelName.toLowerCase(),
  icon: 'user-tie'
}

const actions = {
  list: ['create'],
  form: ['submit']
}

const model = {
  name: modelData.name,
  fields: fields,
  actions: actions,
  types: {
    list: {
      action: `${modelData.namespace}/list`,
      method: 'get',
      endpoint: () => '/v2/admin/managers'
    },
    create: {
      action: `${modelData.namespace}/create`,
      method: 'post',
      endpoint: () => `/v2/admin/managers`
    },
    edit: {
      action: `${modelData.namespace}/edit`,
      method: 'put',
      endpoint: (options) => `/v2/admin/managers/${options._id}`
    },
    get: {
      action: `${modelData.namespace}/get`,
      method: 'get',
      endpoint: (options) => `/v2/admin/managers/${options.uid}`
    },
    delete: {
      action: `${modelData.namespace}/delete`,
      method: 'delete',
      endpoint: (options) => `/v2/admin/managers/${options._id}`
    }
  },
  views: {
    list: { name: `List${modelData.name}`, title: `${modelData.name} List` },
    create: { name: `Create${modelData.name}`, title: `Create ${modelData.name}`, params: (item) => { return { uid: item.uid } } },
    edit: { name: `Edit${modelData.name}`, title: `Edit ${modelData.name}`, params: (item) => { return { uid: item._id } } }
  }
}

export default {
  namespaced: true,
  model: model,
  routes: [
    {
      path: `/${modelData.namespace}`,
      component: () => import('@/views/layouts/Layout'),
      redirect: `/${modelData.namespace}/list`,
      name: `${modelData.name}`,
      meta: { title: `${modelData.name}`, icon: `${modelData.icon}`, admin_level: 2 },
      children: [
        {
          path: 'create',
          component: () => import('@/views/basic/Form'),
          name: model.views.create.name,
          meta: { mode: 'create', title: model.views.create.title, model: model, noCache: true }
        },
        {
          path: 'edit/:uid(\\d+)',
          component: () => import('@/views/basic/Form'),
          name: model.views.edit.name,
          meta: { mode: 'edit', title: model.views.edit.title, model: model, noCache: true },
          hidden: true
        },
        {
          path: 'list',
          component: () => import('@/views/basic/List'),
          name: model.views.list.name,
          meta: { mode: 'list', title: model.views.list.title, model: model, noCache: true }
        }
      ]
    }
  ],
  state: {
    cached: {},
    items: []
  },
  mutations: {
    LIST(state, { page, size, items }) {
      items.forEach(item => {
        state.cached[item._id] = item
      })

      state.items = items
    },
    CREATE(state, { item }) {

    },
    EDIT(state, { item }) {

    },
    GET(state, { item }) {
      state.cached[item._id] = item
    },
    DELETE(state, { item }){

    }
  },
  actions: {
    list({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let { group } = options
          let response = await request[model.types.list.method](model.types.list.endpoint(options), { params: options })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          commit('LIST', data)
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    create({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.create.method](model.types.create.endpoint(options), options )
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          commit('CREATE', data)
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    edit({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.edit.method](model.types.edit.endpoint(options), options)
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          commit('EDIT', { item: data })
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    get({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.get.method](model.types.get.endpoint(options), { params: options })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          commit('GET', { item: data })
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    delete({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.delete.method](model.types.delete.endpoint(options), { params: options })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          commit('DELETE', { item: data })
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
  }
}
