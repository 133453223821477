import request from '@/utils/network'

const fields = [
  { key: 'uid', type: 'uid', label: '#', listing: true, primaryKey: true, tab: "*", },

  { key: 'user_id', type: 'user-select', label: 'User ID'},
  { key: 'agent_name', type: 'text', label: 'Agent Name', listing: true, hidden: true },
  { key: 'agent_code', type: 'text', label: 'Agent Code', listing: true, readonly: true },
  { key: 'agent_description', type: 'text', label: 'Agent Description' },
  
  { key: 'extras', type: 'extra-editor', label: 'Extras', hidden: true,
    fields: [
        // { key: '__v', type: 'text', label: '__v'}
    ]
  },
  
  { key: 'updated_at', type: 'datetime', label: 'Timestamp', format:'YYYY.MM.DD HH:mm:ss', listing: true, hidden: true },
  { key: 'created_at', type: 'field', dataType: 'datetime', label: 'Created At', format:'YYYY.MM.DD HH:mm:ss', readonly: true },
  { key: 'updated_at', type: 'field', dataType: 'datetime', label: 'Updated At', format:'YYYY.MM.DD HH:mm:ss', readonly: true },

  { key: 'action', type: 'action', label: 'Actions', listing: true, actions: [ {
    action: 'view',
    class: 'btn-info',
    label: '호스트 내역',
    onAction: (row, { $router, model }) => {
      $router.push({
        name: model.views.view.name,
        params: model.views.view.params(row),
        query: { agent_code : row.agent_code }
      })
    },
  }, "edit", "delete" ], hidden: true }
]

const modelName = "Agent"

const modelData = {
  name: modelName,
  namespace: modelName.toLowerCase(),
  icon: 'hat-cowboy'
}

const actions = {
  list: ['create'],
  form: ['submit']
}

const model = {
  name: modelData.name,
  fields: fields,
  actions: actions,
//   tabs: tabs,
  types: {
    list: {
      action: `${modelData.namespace}/list`,
      method: 'get',
      endpoint: () => '/v2/admin/agents'
    },
    hostList: {
      action: `${modelData.namespace}/hostList`,
      method: 'get',
      endpoint: (options) => `/v2/admin/agents/${options.agent_code}/hosts`
    },
    create: {
      action: `${modelData.namespace}/create`,
      method: 'post',
      endpoint: () => `/v2/admin/agents`
    },
    hostLinked: {
      action: `${modelData.namespace}/hostLinked`,
      method: 'post',
      endpoint: (options) => `/v2/admin/agents/${options.agent_code}/hosts/${options.host_id}`
    },
    edit: {
      action: `${modelData.namespace}/edit`,
      method: 'put',
      endpoint: (options) => `/v2/admin/agents/${options.uid}`
    },
    delete: {
      action: `${modelData.namespace}/delete`,
      method: 'delete',
      endpoint: (options) => `/v2/admin/agents/${options.uid}`
    },
    hostUnLinked: {
      action: `${modelData.namespace}/hostUnLinked`,
      method: 'delete',
      endpoint: (options) => `/v2/admin/agents/${options.agent_code}/hosts/${options.host_id}`
    },
    get: {
      action: `${modelData.namespace}/get`,
      method: 'get',
      endpoint: (options) => `/v2/admin/agents/${options.uid}`
    }
  },
  views: {
    list: { name: `List${modelData.name}`, title: `${modelData.name} List` },
    create: { name: `Create${modelData.name}`, title: `Create ${modelData.name}`, params: (item) => { return { uid: item.uid } } },
    edit: { name: `Edit${modelData.name}`, title: `Edit ${modelData.name}`, params: (item) => { return { uid: item.uid } } },
    view: { name: `View${modelData.name}`, title: `View ${modelData.name}`, params: (item) => { return { uid: item.uid, agent_code : item.agent_code } } }
  }
}

export default {
  namespaced: true,
  model: model,
  routes: [
    {
      path: `/${modelData.namespace}`,
      component: () => import('@/views/layouts/Layout'),
      redirect: `/${modelData.namespace}/list`,
      name: `${modelData.name}`,
      meta: { title: `${modelData.name}`, icon: `${modelData.icon}`, admin_level: 2 },
      children: [
        {
          path: 'create',
          component: () => import('@/views/basic/Form'),
          name: model.views.create.name,
          meta: { mode: 'create', title: model.views.create.title, model: model, noCache: true }
        },
        {
          path: 'edit/:uid(\\d+)',
          component: () => import('@/views/basic/Form'),
          name: model.views.edit.name,
          meta: { mode: 'edit', title: model.views.edit.title, model: model, noCache: true },
          hidden: true
        },
        {
          path: 'list',
          component: () => import('@/views/basic/List'),
          name: model.views.list.name,
          meta: { mode: 'list', title: model.views.list.title, model: model, noCache: true }
        },
        {
          path: 'view/:uid(\\d+)',
          component: () => import('@/views/pages/agent/View'),
          name: model.views.view.name,
          meta: { mode: "view", title: model.views.view.title, model: model, noCache: true },
          hidden: true,
        }
      ]
    }
  ],
  state: {
    cached: {},
    items: []
  },
  mutations: {
    LIST(state, { page, size, items }) {
      items.forEach(item => {
        state.cached[item.uid] = item
      })

      state.items = items
    },
    CREATE(state, { item }) {

    },
    EDIT(state, { item }) {

    },
    GET(state, { item }) {
      state.cached[item.uid] = item
    },
    DELETE(state, { item }){

    }
  },
  actions: {
    list({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let { group } = options
          let response = await request[model.types.list.method](model.types.list.endpoint(options), { params: options })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          commit('LIST', data)
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    hostList({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {

          let response = await request[model.types.hostList.method](model.types.hostList.endpoint(options), { params: options })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          commit('LIST', data)
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    create({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.create.method](model.types.create.endpoint(options), options)
          let { error, data } = response.data
          if (error) {
            return reject(error)
          }
          commit('CREATE', data)
          resolve(data)
        }
        catch(e) {
          console.error("그럼 여기??", e)
          reject(e)
        }
      })
    },
    edit({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.edit.method](model.types.edit.endpoint(options), options)
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          commit('EDIT', { item: data })
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    delete({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.delete.method](model.types.delete.endpoint(options), options)
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          commit('DELETE', { item: data })
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    hostUnLinked({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.hostUnLinked.method](model.types.hostUnLinked.endpoint(options), options)
          let { error, data } = response.data

          if (error) {
            return reject(new Error(error))
          }
          commit('DELETE', { item: data })
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    get({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.get.method](model.types.get.endpoint(options), { params: options })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          commit('GET', { item: data })
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    }
  }
}
