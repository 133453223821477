import _ from 'lodash'
import request from '@/utils/network'

const groups = [
  { label: "전체", value: "" },
  { label: "발송요청", value: "accepted" },
  { label: "발송완료", value: "delivered" },
  { label: "메일읽음", value: "opened" },
  { label: "링크클릭", value: "clicked" },
  { label: "거절", value: "rejected" },
  { label: "발송지연", value: "failed" }
]

const fields = [
  { key: '_key', type: 'text', label: '#', hidden: true },
  { key: 'recipient', type: 'text', label: 'Account', alias: 'recipient', listing: true, editable: false },
  { key: 'event_type', type: 'text', label: 'Type', listing: true, editable: false },
  { key: 'event_data', type: 'json', label: 'Data', editable: false },
  { key: 'raw', type: 'textarea', label: 'Raw', editable: false },
  { key: 'event_timestamp', type: 'datetime', label: 'Timestamp', listing: true, format:'YY/MM/DD HH:mm:ss', editable: false },
  { key: 'tags', type: 'text', label: 'Tags', listing: true, editable: false }
]

const modelName = "MailLogs"

const modelData = {
  name: modelName,
  namespace: modelName.toLowerCase(),
  icon: 'file-alt'
}

const actions = {
  list: ['view'],
  form: []
}

const model = {
  name: modelData.name,
  fields: fields,
  actions: actions,
  groups: groups,
  getters: {
    items: ( component ) => component.$store.getters[`${modelData.namespace}/GET_ITEMS`],
    total: ( component ) => component.$store.getters[`${modelData.namespace}/GET_TOTAL`],
  },
  types: {
    list: {
      action: `${modelData.namespace}/list`,
      method: 'get',
      endpoint: () => '/v2/admin/mailgun/logs'
    },
    get: {
      action: `${modelData.namespace}/get`,
      method: 'get',
      endpoint: (options) => `/v2/admin/mailgun/logs/${options.key}`
    },
    sync: {
      action: `${modelData.namespace}/sync`,
      method: 'post',
      endpoint: (options) => `/v2/admin/mailgun/logs/sync`
    },
    syncNext: {
      action: `${modelData.namespace}/sync/next`,
      method: 'post',
      endpoint: (options) => `/v2/admin/mailgun/logs/sync/${options.paging_key}`
    },
    syncBackground: {
      action: `${modelData.namespace}/syncBackground`,
      method: 'post',
      endpoint: (options) => `/v2/admin/mailgun/logs/sync/background`
    }
  },
  views: {
    list: { name: `List${modelData.name}`, title: `${modelData.name} List` },
    view: { name: `View${modelData.name}`, title: `View ${modelData.name}`, params: (item) => { return { key: item.key } } },
    sync: { name: `Sync${modelData.name}`, title: `Sync ${modelData.name}`, params: (item) => { return { key: item.key } } }
  }
}

export default {
  namespaced: true,
  model: model,
  routes: [
    {
      path: `/${modelData.namespace}`,
      component: () => import('@/views/layouts/Layout'),
      redirect: `/${modelData.namespace}/list`,
      name: `${modelData.name}`,
      meta: { title: `${modelData.name}`, icon: `${modelData.icon}` },
      children: [
        {
          path: 'view/:key',
          component: () => import('@/views/basic/Form'),
          name: model.views.view.name,
          meta: { mode: "view", title: model.views.view.title, model: model, noCache: true },
          hidden: true
        },
        {
          path: 'sync',
          component: () => import('@/views/pages/logs/Sync'),
          name: model.views.sync.name,
          meta: { mode: "sync", title: model.views.sync.title, model: model, noCache: true }
        },
        {
          path: 'list',
          component: () => import('@/views/basic/List'),
          name: model.views.list.name,
          meta: { mode: "list", title: model.views.list.title, model: model, noCache: true }
        }
      ]
    }
  ],
  state: {
    total: 0,
    items: []
  },
  mutations: {
    LIST(state, { items, total }) {
      state.items = items
      state.total = total
    }
  },
  getters: {
    GET_ITEMS: ( state ) => {
      return state.items;
    },
    GET_TOTAL: ( state ) => {
      return state.total;
    }
  },
  actions: {
    list({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let { group } = options
          let response = await request[model.types.list.method](model.types.list.endpoint(options), { params: { event_type: group, ...options } })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          commit('LIST', data)
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    get({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.get.method](model.types.get.endpoint(options), { params: options })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    sync({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.sync.method](model.types.sync.endpoint(options), options)
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    syncNext({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.syncNext.method](model.types.syncNext.endpoint(options), options)
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    syncBackground({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.syncBackground.method](model.types.syncBackground.endpoint(options), options)
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    }
  }
}
