import VueCookies from 'vue-cookies';
import _ from 'lodash';
import request from '@/utils/network';
import qs from 'qs';
import moment from 'moment-timezone';
import utils from '@/utils';

const tabs = [
	{ id: 'info', name: 'Info' },
	{ id: 'contact', name: 'Contact' },
	{ id: 'timetable', name: 'TimeTable' },
	{ id: 'resource', name: 'Resource' },
	{ id: 'price', name: 'Price' },
	{ id: "memo", name: "Memo" }
];

const categoryItems = [
	{ label: '카페', value: 'SC_CAFE' },
	{ label: '편의시설', value: 'SC_CONVENIENCE' },
	{ label: '레스토랑', value: 'SC_RESTAURANT' },
	{ label: '기타', value: 'SC_ETC' },
];

const facilities = { 
	key : 'facilities', 
	label : '편의시설',
	options: [	
		{ label: '와이파이', value: 'SF_WIFI' },
		{ label: '화장실', value: 'SF_TOILET' },
		{ label: '전원 충전', value: 'SF_CHARGEABLE' },
		{ label: '엘리베이터', value: 'SF_ELEVATOR' },
		{ label: '냉동/냉장 보관', value: 'SF_FRIDGE' },
		{ label: '탈의실', value: 'SF_FITTIN_GROOM' },
	]
}

const multiLingualService = {
	key: 'multilingual_service',
	label: '대응가능언어',
	options: [
		{ label: '한국어', value: 'SL_KO' },
		{ label: '영어', value: 'SL_EN' },
		{ label: '중국어', value: 'SL_CN' },
		{ label: '일본어', value: 'SL_JP'},
		{ label: '베트남어', value: 'SL_VN'}
	]
}

const etcService = {
	key: 'etc_service',
	label: '기타서비스',
	options: [
		{ label: '보조배터리 대여', value: 'SE_BATTERY_RENTAL' },
		{ label: '주변 여행/쇼핑 등 추가 정보 제공 가능', value: 'SE_INFORMATION' },
		{ label: '상시 근로자', value: 'SE_MANAGER' },
	]
}

const status = [
	{ label: '등록 대기', value: 'READY' },
	{ label: '공개 / 티켓 판매 가능', value: 'PUBLIC' },
	{ label: '비공개 / 티켓 판매 가능', value: 'PRIVATE' },
	{ label: '차단', value: 'BLOCKED' },
];

let hostOptions = [];

const fields = [
	{ key: 'uid', type: 'uid', label: '#', listing: true, primaryKey: true, tab: '*' },
	{
		key: 'store_category',
		type: 'select',
		label: 'Store Category',
		listing: true,
		options: categoryItems,
		defaultValue: '',
		locale: (value) => {
			let items = categoryItems.filter((item) => item.value === value);
			return items.length > 0 ? items[0].label : value;
		},
	},
  {
		key: 'thumbnail_image',
		type: 'resource_clickable',
    listing: true,
		label: 'Thumbnail Image',
		alias: (field, row) => {
			try{
				return row.resources.thumbnail_image.resource_url || row.resources.thumbnail_image.resource_thumbnail_url;
			}catch(e){}
			return null;
		}
	},
	{
		key: 'store_name',
		type: 'locale-editor',
		label: 'Store Name',
		listing: true,
		alias: (field, row) => {
			let value = row[field.key];
			return utils.getLocale(value, 'ko');
		},
		localeType: 'text',
		tab: 'info',
	},
	{
		key: 'store_name_localed',
		type: 'field',
		label: 'Store Name',
		readonly: true,
		alias: (field, row) => {
			let value = row.store_name;
			return utils.getLocale(value, 'ko');
		},
		tab: ['contact', 'timetable', 'resource', 'price'],
	},
	{ key: 'store_descript', type: 'textarea', label: 'Store Description' },
	{ key: 'store_phone', type: 'text', label: 'Store Phone', validation: ['number'], tab: 'contact' },
	{
		key: 'store_zipcode',
		type: 'postcode-select',
		label: 'Store PostCode',
		onComplete: async (component, field, row, data) => {
			let { 
				roadAddress, 
				roadAddressEnglish, 
				roadname,
				roadnameEnglish,
				jibunAddress, 
				jibunAddressEnglish,
				sido,
				sidoEnglish,
				sigungu,
				sigunguEnglish,
				bname1,
				bname1English,
				bname2,
				bname2English,
				userSelectedType, 
				geoCodeProvider,
				callback,
			} = data;

			let address = component.data.store_address_localed;
			let address_english = component.data.store_address;
			
			if (userSelectedType === 'J') {
				address = jibunAddress;
				address_english = jibunAddressEnglish;
			} else if (userSelectedType === 'R') {
				address = roadAddress;
				address_english = roadAddressEnglish;
			}
			
			let store_address_stringified = JSON.stringify({
				ko: address,
				base: address_english,
				roadAddress, 
				roadAddressEnglish, 
				roadname,
				roadnameEnglish,
				jibunAddress, 
				jibunAddressEnglish,
				sido,
				sidoEnglish,
				sigungu,
				sigunguEnglish,
				eupMyeon: bname1,
				eupMyeonEnglish: bname1English,
				dongRi: bname2,
				dongRiEnglish: bname2English,
			})
			
			if(component.$data.data.store_address == undefined){
				component.$set(component.$data.data, 'store_address', store_address_stringified)
			}

			component.$data.data.store_address = store_address_stringified
			component.$data.data.store_address_localed = address;
			component.$data.data.timestamp = +new Date();
			
			let district_main = sido;
			let district_sub = sigungu;

			let siInSigungu = sigungu.split(" ").filter((o) => o[o.length - 1] == "시");

			if (siInSigungu.length > 0) {
				district_main += " " + siInSigungu;

				district_sub = sigungu.replace(siInSigungu, "").trim();

				if (district_sub == "") {
					district_sub = bname1 || bname2;
				}
			}

			component.$data.data.extra.district_main = district_main;
			component.$data.data.extra.district_sub = district_sub;

			try {
				let res,
					geoResult = {
						naver: {
							geo_latitude: 0,
							geo_longitude: 0,
						},
						google: {
							geo_latitude: 0,
							geo_longitude: 0,
						},
					};
				
				try{
					res = await component.$store.dispatch('store/geocodeInKorea', { address });

					geoResult.naver.geo_latitude = res.geometry.location.lat;
					geoResult.naver.geo_longitude = res.geometry.location.lng;
				}catch(e){}

				try{
					res = await component.$store.dispatch('store/geocode', { address });

					geoResult.google.geo_latitude = res.geometry.location.lat;
					geoResult.google.geo_longitude = res.geometry.location.lng;
				}
				catch(e){}

				geoResult.loaded = true;

				if (typeof callback === 'function') {
					callback(geoResult, (geodata) => {
						component.$data.data.geo_latitude = geodata.geo_latitude;
						component.$data.data.geo_longitude = geodata.geo_longitude;
					});
				}
			} catch (e) {
				component.$toast.error(e.message);
			}

		},
		tab: 'contact',
	},
	{
		key: 'store_address',
		type: 'locale-editor',
		label: 'Store Address',
    listing: true,
		tab: 'contact',
		alias: (field, row) => {
			let value = row[field.key];
			return utils.getLocale(value, 'ko');
		},
		localeType: 'text',
	},
	{
		key: 'store_address_detail',
		type: 'locale-editor',
		label: 'Store Address Detail',
		tab: 'contact',
		alias: (field, row) => {
			let value = row[field.key];
			return utils.getLocale(value, 'ko');
		},
		localeType: 'text',
	},
	{
		key: 'floor',
		type: 'text',
		label: 'Floor',
		tab: 'contact',
		value: ( row ) => {
			let value = utils.parseJSON(row['store_address_detail']);

			return value.floor
		},
		onInput: (row, e) => {
			if(!row['store_address_detail']) row['store_address_detail'] = {};
			
			let value = utils.parseJSON(row['store_address_detail'])
			
			value.floor = e.target.value;
			
			value = {...utils.parseJSON(row['store_address_detail']), ...value}

			 row['store_address_detail'] = JSON.stringify(value)
		},
		validation: ['number'],
		validateTarget: (row) => utils.parseJSON(row['store_address_detail'])['floor']
	},

	{ key: 'geo_latitude', type: 'text', label: 'Geo Latitude', tab: 'contact' },
	{ key: 'geo_longitude', type: 'text', label: 'Geo Longitude', tab: 'contact' },
	{ key: 'geo_zone', type: 'text', label: 'Geo Zone', readonly: true, tab: 'contact' },

	{
		key: 'store_directions',
		type: 'locale-editor',
		label: 'Store Directions',
		description: '찾아오시는 길',
		alias: (field, row) => {
			let value = row[field.key];
			return utils.getLocale(value, 'ko');
		},
		localeType: 'textarea',
		tab: 'contact',
	},

	{ key: 'host_id', type: 'host-select', label: 'Host ID' },
	{ key: 'host_name', type: 'text', label: 'Host Name', listing: true, hidden: true },

	{ key: 'notify_email', type: 'text', label: 'Notify Email' },
	{ key: 'notify_phone', type: 'text', label: 'Notify Phone', validation: ['number'] },

	{ key: 'facilities', type: 'multi-checkbox', label: 'Facilities', options: [facilities, multiLingualService, etcService], defaultValue: '' },	
	{ key: 'tags', type: 'text', label: 'Tags' },

	{ key: 'lug_limit', type: 'text', label: 'Lug Limit', tab: 'price' },
	{ key: 'lug_bigable_limit', type: 'text', label: 'Lug Bigable Limit', tab: 'price' },

	{ key: 'base_price_unit', type: 'text', label: 'Base Price Unit', tab: 'price' },
	{ key: 'base_price', type: 'text', label: 'Base Price', tab: 'price' },
	{ key: 'time_price', type: 'text', label: 'Time Price', tab: 'price' },
	{ key: 'size_price_rate', type: 'text', label: 'Size Price Rate', tab: 'price' },

	{ key: 'time_table', type: 'timetable-editor', label: 'Time Table', tab: 'timetable' },
	{ key: 'status', type: 'select', label: 'Status', options: status, defaultValue: '' },
	{ key: 'memo', type: 'text', label: 'Memo', tab: 'memo' },

	{
		key: 'extra',
		type: 'extra-editor',
		label: 'Extras',
		fields: [
			{ name: 'store_directions', type: 'textarea', label: 'Store Directions', description: '찾아오시는 길' },
			{ name: 'time_24hour', type: 'checkbox', label: '24/7', description: '연중무휴' },
			{ name: 'district_main', type: 'text', label: 'District Main', description: '시/도' },
			{ name: 'district_sub', type: 'text', label: 'District Sub', description: '군/구/동' },

			{ name: 'category', type: 'text', label: 'Category', description: '매장구분' },
			{ name: 'place_name', type: 'text', label: 'Place Name', description: '장' },
			{ name: 'area_name', type: 'text', label: 'Area Name', description: '지역' },
			{ name: 'nearest_station', type: 'text', label: 'Nearest Station', description: '가까운역' },
		],
		tab: ['contact', 'timetable'],
	},
  { key: 'district_main', type: 'text', label: 'District Main', listing: true },
  { key: 'district_sub', type: 'text', label: 'District Sub' },

	{
		key: 'resources',
		type: 'resource-editor',
		label: 'Resources',
		fields: [
			{ name: 'thumbnail_image', type: 'single', label: 'Thumbnail Image', description: '' },
			{ name: 'detail_images', type: 'list', label: 'Detail Images', description: '' },
			{ name: 'directions_images', type: 'list', label: 'Directions Images', description: '' },
		],
		tab: 'resource',
	},



	{ key: 'updated_at', type: 'datetime', label: 'Timestamp', format: 'YYYY.MM.DD HH:mm:ss', hidden: true },
	{ key: 'created_at', type: 'field', dataType: 'datetime', label: 'Created At', format: 'YYYY.MM.DD HH:mm:ss', readonly: true },
	{ key: 'updated_at', type: 'field', dataType: 'datetime', label: 'Updated At', format: 'YYYY.MM.DD HH:mm:ss', readonly: true },
	// {
	// 	key: 'action',
	// 	type: 'action',
	// 	label: 'Actions',
	// 	// listing: true,
	// 	actions: [
	// 		// 'edit',
	// 		// {
	// 		// 	action: 'schedule',
	// 		// 	class: 'btn-info',
	// 		// 	label: '스케쥴',
	// 		// 	onAction: (row, { $router, model }) => {
	// 		// 		$router.push({
	// 		// 			name: model.views.schedule.name,
	// 		// 			params: model.views.schedule.params(row),
	// 		// 		});
	// 		// 	},
	// 		// },
	// 		// {
	// 		// 	action: 'profit',
	// 		// 	class: 'btn-warning',
	// 		// 	label: '정산',
	// 		// 	onAction: (row, { $router, model }) => {
	// 		// 		$router.push({
	// 		// 			name: model.views.profit.name,
	// 		// 			params: model.views.profit.params(row),
	// 		// 		});
	// 		// 	},
	// 		// },
	// 		// 'delete',
	// 	],
	// 	hidden: true,
	// },
];

const modelName = 'StoreSearch';

const modelData = {
	name: modelName,
	namespace: modelName.toLowerCase(),
	icon: 'store',
};

const actions = {
	list: [
		// {
		// 	action: 'batchProfit',
		// 	class: 'btn-warning',
		// 	label: '일괄 정산',
		// 	onAction: (row, { $router, model }) => {
		// 		$router.push({
		// 			name: model.views.batchProfit.name,
		// 		});
		// 	},
		// },
		'create',
	],
	form: ['submit'],
};

const model = {
	name: modelData.name,
	fields: fields,
	actions: actions,
	tabs: tabs,
	types: {
		list: {
			action: `storeSearch/list`,
			method: 'get',
			endpoint: () => '/v2/admin/stores',
		},
		// export: {
		// 	action: `${modelData.namespace}/export`,
		// 	method: 'get',
		// 	endpoint: (options) => `/v2/admin/stores/export`,
		// 	fields: {
		// 		'#': 'uid',
		// 		'Store Category': 'store_category',

		// 		'Store Name (한글)': {
		// 			field: 'store_name',
		// 			callback: (value) => {
		// 				return utils.getLocale(value, 'ko');
		// 			},
		// 		},
		// 		'Store Name (Base)': {
		// 			field: 'store_name',
		// 			callback: (value) => {
		// 				return utils.getLocale(value, 'base');
		// 			},
		// 		},

		// 		'Store Description': 'store_descript',
		// 		'Store Phone': 'store_phone',

		// 		'Store Address (한글)': {
		// 			field: 'store_address',
		// 			callback: (value) => {
		// 				return utils.getLocale(value, 'ko');
		// 			},
		// 		},
		// 		'Store Address (Base)': {
		// 			field: 'store_address',
		// 			callback: (value) => {
		// 				return utils.getLocale(value, 'base');
		// 			},
		// 		},
		// 		'Store Address Detail (한글)': {
		// 			field: 'store_address_detail',
		// 			callback: (value) => {
		// 				return utils.getLocale(value, 'ko');
		// 			},
		// 		},
		// 		'Store Address Detail (Base)': {
		// 			field: 'store_address_detail',
		// 			callback: (value) => {
		// 				return utils.getLocale(value, 'base');
		// 			},
		// 		},
		// 		'Store Floor': {
		// 			field: 'store_address_detail',
		// 			callback: (value) => {
		// 				const address_detail = utils.parseJSON(value);
		// 				if(address_detail.floor) return address_detail.floor
		// 				return ''
		// 			},
		// 		},
		// 		'Store ZipCode': 'store_zipcode',

		// 		'Store Directions (한글)': {
		// 			field: 'extra.store_directions',
		// 			callback: (value) => {
		// 				return utils.getLocale(value, 'ko');
		// 			},
		// 		},
		// 		'Store Directions (Base)': {
		// 			field: 'extra.store_directions',
		// 			callback: (value) => {
		// 				return utils.getLocale(value, 'base');
		// 			},
		// 		},

		// 		연중무휴: 'extra.time_24hour',

		// 		'District Main': 'extra.district_main',
		// 		'District Sub': 'extra.district_sub',
		// 		Category: 'extra.category',
		// 		'Place Name': 'extra.place_name',
		// 		'Area Name': 'extra.area_name',
		// 		'Nearest Station': 'extra.nearest_station',

		// 		'Host ID': 'host_id',
		// 		'Host Name': 'host_name',
		// 		'Notify Email': 'notify_email',
		// 		'Notify Phone': 'notify_phone',
		// 		'Geo Latitude': 'geo_latitude',
		// 		'Geo Longitude': 'geo_longitude',
		// 		'Geo Zone': 'geo_zone',
		// 		Facilities: 'facilities',
		// 		Tags: 'tags',
		// 		'Lug Limit': 'lug_limit',
		// 		'Lug Bigable Limit': 'lug_bigable_limit',
		// 		'Base Price Unit': 'base_price_unit',
		// 		'Base Price': 'base_price',
		// 		'Time Price': 'time_price',
		// 		'Size Price Rate': 'size_price_rate',
		// 		'Time Table': {
		// 			field: 'time_table',
		// 			callback: (value) => {
		// 				return JSON.stringify(value);
		// 			},
		// 		},
		// 		Status: 'status',
		// 		Memo: 'memo',
		// 		// 'Resources': {
		// 		//   field: 'resources',
		// 		//   callback: (value) => {
		// 		//     return JSON.stringify(value)
		// 		//   }
		// 		// },
		// 		// 'Extras': {
		// 		//   field: 'extra',
		// 		//   callback: (value) => {
		// 		//     return JSON.stringify(value)
		// 		//   }
		// 		// },
		// 		'created At': {
		// 			field: 'created_at',
		// 			callback: (value) => {
		// 				return moment(value)
		// 					.tz('Asia/Seoul')
		// 					.format('YYYY-MM-DD HH:mm:ss');
		// 			},
		// 		},
		// 		'updated At': {
		// 			field: 'updated_at',
		// 			callback: (value) => {
		// 				return moment(value)
		// 					.tz('Asia/Seoul')
		// 					.format('YYYY-MM-DD HH:mm:ss');
		// 			},
		// 		},
		// 	},
		// },
	},
	views: {
		list: { name: `List${modelData.name}`, title: `${modelData.name} List` },
	},
};

export default {
	namespaced: true,
	model: model,
	routes: [
		{
			path: `/${modelData.namespace}`,
			component: () => import('@/views/layouts/Layout'),
			redirect: `/${modelData.namespace}/list`,
			name: `${modelData.name}`,
			meta: { title: `${modelData.name}`, icon: `${modelData.icon}` },
			children: [
				{
					path: 'list',
					component: () => import('@/views/basic/List'),
					name: model.views.list.name,
					meta: { mode: 'list', title: model.views.list.title, model: model, noCache: true },
				},
			],
		},
	],
	state: {
		cached: {},
		items: [],
		batch_profit_default_params: {
			date_start: '',
			date_end: '',
		},
		exchangeRate: VueCookies.get('CURRENT_EXCHANGE_RATE') || 1100,
	},
	mutations: {
		LIST(state, { page, size, items }) {
			items.forEach((item) => {
				state.cached[item.uid] = item;
			});

			state.items = items;
		},
	},
	getters: {
		batchProfitDefaultParams: (state) => {
			return state.batch_profit_default_params;
		},
		exchangeRate: (state) => {
			return state.exchangeRate;
		},
	},
	actions: {
		list({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let { group } = options;
					let response = await request[model.types.list.method](model.types.list.endpoint({...options, status: 'search'}), { params: {...options, status: 'search'} });
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					commit('LIST', data);
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		// export({ commit, rootGetters }, options) {
		// 	return new Promise(async (resolve, reject) => {
		// 		try {
		// 			let response = await request[model.types.export.method](model.types.export.endpoint(options), { params: options });
		// 			let { error, data } = response.data;
		// 			if (error) {
		// 				return reject(new Error(error));
		// 			}
		// 			resolve(data);
		// 		} catch (e) {
		// 			reject(e);
		// 		}
		// 	});
		// },
	},
};
