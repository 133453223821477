import axios from "axios"
import axiosDefaults from "axios/lib/defaults"
import router from "@/router"
import config from "@/config"

axiosDefaults.baseURL = config.apiServer

axios.interceptors.request.use(
  config => {
    if (localStorage.accessToken) {
      config.headers.Authorization = 'bearer ' + localStorage.accessToken
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  response => { return response },
  error => {
    const { status } = error.response || {}
    if (status == 401) {
      localStorage.removeItem("accessToken")
      router.push("/login")
    }

    return Promise.reject(error)
  }
)

export default axios
