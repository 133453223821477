import VueCookies from 'vue-cookies';
import _ from 'lodash';
import request from '@/utils/network';
import qs from 'qs';
import moment from 'moment-timezone';
import utils from '@/utils';
import storeSearch from "./manager.store.search"

const tabs = [
	{ id: 'info', name: 'Info' },
	{ id: 'contact', name: 'Contact' },
	{ id: 'timetable', name: 'TimeTable' },
	{ id: 'resource', name: 'Resource' },
	{ id: 'price', name: 'Price' },
	{ id: "memo", name: "Memo" }
];

const categoryItems = [
	{ label: '카페', value: 'SC_CAFE' },
	{ label: '편의시설', value: 'SC_CONVENIENCE' },
	{ label: '레스토랑', value: 'SC_RESTAURANT' },
	{ label: '기타', value: 'SC_ETC' },
];

const facilities = { 
	key : 'facilities', 
	label : '편의시설',
	options: [	
		{ label: '와이파이', value: 'SF_WIFI' },
		{ label: '화장실', value: 'SF_TOILET' },
		{ label: '전원 충전', value: 'SF_CHARGEABLE' },
		{ label: '엘리베이터', value: 'SF_ELEVATOR' },
		{ label: '냉동/냉장 보관', value: 'SF_FRIDGE' },
		{ label: '탈의실', value: 'SF_FITTIN_GROOM' },
	]
}

const multiLingualService = {
	key: 'multilingual_service',
	label: '대응가능언어',
	options: [
		{ label: '한국어', value: 'SL_KO' },
		{ label: '영어', value: 'SL_EN' },
		{ label: '중국어', value: 'SL_CN' },
		{ label: '일본어', value: 'SL_JP'},
		{ label: '베트남어', value: 'SL_VN'}
	]
}

const etcService = {
	key: 'etc_service',
	label: '기타서비스',
	options: [
		{ label: '보조배터리 대여', value: 'SE_BATTERY_RENTAL' },
		{ label: '주변 여행/쇼핑 등 추가 정보 제공 가능', value: 'SE_INFORMATION' },
		{ label: '상시 근로자', value: 'SE_MANAGER' },
	]
}

const groups = [
  { label: "전체", value: "" },
  { label: "READY", value: "READY" },
  { label: "PUBLIC", value: "PUBLIC" },
  { label: "REFUSE", value: "REFUSE"},
  { label: "HOLD", value: "HOLD"},
  { label: "SHUT_DOWN", value: "SHUT_DOWN" },
  { label: "UNKNOWN", value: "UNKNOWN" },
	{ label: "TEST", value: "TEST" }
]

const status = [
	{ label: '등록 대기 (READY)', value: 'READY' },
	{ label: '정상 운영 (PUBLIC)', value: 'PUBLIC' },
	{ label: '미운영 (REFUSE)', value: 'REFUSE' },
	{ label: '등록 보류 (HOLD)', value: 'HOLD' },
	{ label: '폐업 (SHUT_DOWN)', value: 'SHUT_DOWN' },
	{ label: "알 수 없음 (UNKNOWN)", value: "UNKNOWN" },
	{ label: "테스트 (TEST)", value: "TEST" }
];

let hostOptions = [];

const fields = [
	{ key: 'uid', type: 'uid', label: '#', listing: true, primaryKey: true, tab: '*' },
	{
		key: 'store_category',
		type: 'select',
		label: 'Store Category',
		listing: true,
		options: categoryItems,
		defaultValue: '',
		locale: (value) => {
			let items = categoryItems.filter((item) => item.value === value);
			return items.length > 0 ? items[0].label : value;
		},
	},
	{
		key: 'store_name',
		type: 'locale-editor',
		label: 'Store Name',
		listing: true,
		alias: (field, row) => {
			let value = row[field.key];
			return utils.getLocale(value, 'ko');
		},
		localeType: 'text',
		tab: 'info',
	},
	{
		key: 'store_name_localed',
		type: 'field',
		label: 'Store Name',
		readonly: true,
		alias: (field, row) => {
			let value = row.store_name;
			return utils.getLocale(value, 'ko');
		},
		tab: ['contact', 'timetable', 'resource', 'price'],
	},
	{ key: 'store_descript', type: 'textarea', label: 'Store Description' },
	{ key: 'store_phone', type: 'text', label: 'Store Phone', validation: ['number'], tab: 'contact' },
	{
		key: 'store_zipcode',
		type: 'postcode-select',
		label: 'Store PostCode',
		onComplete: async (component, field, row, data) => {
			let { 
				roadAddress, 
				roadAddressEnglish, 
				roadname,
				roadnameEnglish,
				jibunAddress, 
				jibunAddressEnglish,
				sido,
				sidoEnglish,
				sigungu,
				sigunguEnglish,
				bname1,
				bname1English,
				bname2,
				bname2English,
				userSelectedType, 
				geoCodeProvider,
				callback,
			} = data;

			let address = component.data.store_address_localed;
			let address_english = component.data.store_address;
			
			if (userSelectedType === 'J') {
				address = jibunAddress;
				address_english = jibunAddressEnglish;
			} else if (userSelectedType === 'R') {
				address = roadAddress;
				address_english = roadAddressEnglish;
			}
			
			let store_address_stringified = JSON.stringify({
				ko: address,
				base: address_english,
				roadAddress, 
				roadAddressEnglish, 
				roadname,
				roadnameEnglish,
				jibunAddress, 
				jibunAddressEnglish,
				sido,
				sidoEnglish,
				sigungu,
				sigunguEnglish,
				eupMyeon: bname1,
				eupMyeonEnglish: bname1English,
				dongRi: bname2,
				dongRiEnglish: bname2English,
			})
			
			if(component.$data.data.store_address == undefined){
				component.$set(component.$data.data, 'store_address', store_address_stringified)
			}

			component.$data.data.store_address = store_address_stringified
			component.$data.data.store_address_localed = address;
			component.$data.data.timestamp = +new Date();
			
			let district_main = sido;
			let district_sub = sigungu;

			let siInSigungu = sigungu.split(" ").filter((o) => o[o.length - 1] == "시");

			if (siInSigungu.length > 0) {
				district_main += " " + siInSigungu;

				district_sub = sigungu.replace(siInSigungu, "").trim();

				if (district_sub == "") {
					district_sub = bname1 || bname2;
				}
			}

			component.$data.data.extra.district_main = district_main;
			component.$data.data.extra.district_sub = district_sub;
    
			try {
				let res,
					geoResult = {
						naver: {
							geo_latitude: 0,
							geo_longitude: 0,
						},
						google: {
							geo_latitude: 0,
							geo_longitude: 0,
						},
					};
				
				try{
					res = await component.$store.dispatch('store/geocodeInKorea', { address });

					geoResult.naver.geo_latitude = res.geometry.location.lat;
					geoResult.naver.geo_longitude = res.geometry.location.lng;
				} catch(e){}

				try{
					res = await component.$store.dispatch('store/geocode', { address });

					geoResult.google.geo_latitude = res.geometry.location.lat;
					geoResult.google.geo_longitude = res.geometry.location.lng;
				} catch(e){}

				geoResult.loaded = true;

				if (typeof callback === 'function') {
					callback(geoResult, (geodata) => {
						component.$data.data.geo_latitude = geodata.geo_latitude;
						component.$data.data.geo_longitude = geodata.geo_longitude;
					});
				}
			} catch (e) {
				component.$toast.error(e.message);
			}

		},
		tab: 'contact',
	},
	{
		key: 'store_address',
		type: 'locale-editor',
		label: 'Store Address',
		tab: 'contact',
		alias: (field, row) => {
			let value = row[field.key];
			return utils.getLocale(value, 'ko');
		},
		localeType: 'text',
	},
	{
		key: 'store_address_detail',
		type: 'locale-editor',
		label: 'Store Address Detail',
		tab: 'contact',
		alias: (field, row) => {
			let value = row[field.key];
			return utils.getLocale(value, 'ko');
		},
		localeType: 'text',
	},
	{
		key: 'floor',
		type: 'text',
		label: 'Floor',
		tab: 'contact',
		value: ( row ) => {
			let value = utils.parseJSON(row['store_address_detail']);

			return value.floor
		},
		onInput: (row, e) => {
			if(!row['store_address_detail']) row['store_address_detail'] = {};
			
			let value = utils.parseJSON(row['store_address_detail'])
			
			value.floor = e.target.value;
			
			value = {...utils.parseJSON(row['store_address_detail']), ...value}

			row['store_address_detail'] = JSON.stringify(value)
		},
		validation: ['number'],
		validateTarget: (row) => utils.parseJSON(row['store_address_detail'])['floor']
	},

	{ key: 'geo_latitude', type: 'text', label: 'Geo Latitude', tab: 'contact' },
	{ key: 'geo_longitude', type: 'text', label: 'Geo Longitude', tab: 'contact' },
	{ key: 'geo_zone', type: 'text', label: 'Geo Zone', readonly: true, tab: 'contact' },

	{
		key: 'store_directions',
		type: 'locale-editor',
		label: 'Store Directions',
		description: '찾아오시는 길',
		alias: (field, row) => {
			let value = row[field.key];
			return utils.getLocale(value, 'ko');
		},
		localeType: 'textarea',
		tab: 'contact',
	},

	{ key: 'host_id', type: 'host-select', label: 'Host ID' },
	{ key: 'host_name', type: 'text', label: 'Host Name', listing: true, hidden: true },

	{ key: 'notify_email', type: 'text', label: 'Notify Email' },
	{ key: 'notify_phone', type: 'text', label: 'Notify Phone', validation: ['number'] },

	{ key: 'facilities', type: 'multi-checkbox', label: 'Facilities', options: [facilities, multiLingualService, etcService], defaultValue: '' },	
	{ key: 'tags', type: 'text', label: 'Tags' },

	{ key: 'lug_limit', type: 'text', label: 'Lug Limit', tab: 'price' },
	{ key: 'lug_bigable_limit', type: 'text', label: 'Lug Bigable Limit', tab: 'price' },

	{ key: 'base_price_unit', type: 'text', label: 'Base Price Unit', tab: 'price' },
	{ key: 'base_price', type: 'text', label: 'Base Price', tab: 'price' },
	{ key: 'time_price', type: 'text', label: 'Time Price', tab: 'price' },
	{ key: 'size_price_rate', type: 'text', label: 'Size Price Rate', tab: 'price' },

	{ key: 'time_table', type: 'timetable-editor', label: 'Time Table', tab: 'timetable' },
	{ key: 'status', type: 'select', label: 'Status', listing: true, options: status, defaultValue: '' },
	{ key: 'memo', type: 'text', label: 'Memo', tab: 'memo' },

	{
		key: 'extra',
		type: 'extra-editor',
		label: 'Extras',
		fields: [
			{ name: 'store_directions', type: 'textarea', label: 'Store Directions', description: '찾아오시는 길' },
			{ name: 'time_24hour', type: 'checkbox', label: '24/7', description: '연중무휴' },
			{ name: 'district_main', type: 'text', label: 'District Main', description: '시/도' },
			{ name: 'district_sub', type: 'text', label: 'District Sub', description: '군/구/동' },

			{ name: 'category', type: 'text', label: 'Category', description: '매장구분' },
			{ name: 'place_name', type: 'text', label: 'Place Name', description: '장' },
			{ name: 'area_name', type: 'text', label: 'Area Name', description: '지역' },
			{ name: 'nearest_station', type: 'text', label: 'Nearest Station', description: '가까운역' },
		],
		tab: ['contact', 'timetable'],
	},

	{
		key: 'resources',
		type: 'resource-editor',
		label: 'Resources',
		fields: [
			{ name: 'thumbnail_image', type: 'single', label: 'Thumbnail Image', description: '' },
			{ name: 'detail_images', type: 'list', label: 'Detail Images', description: '' },
			{ name: 'directions_images', type: 'list', label: 'Directions Images', description: '' },
		],
		tab: 'resource',
	},

	{
		key: 'thumbnail_image',
		type: 'resource_clickable',
		label: 'Thumbnail Image',
		listing: true,
		alias: (field, row) => {
			try{
				return row.resources.thumbnail_image.resource_url || row.resources.thumbnail_image.resource_thumbnail_url;
			}catch(e){}
			return null;
		}
	},

	{ key: 'updated_at', type: 'datetime', label: 'Timestamp', format: 'YYYY.MM.DD HH:mm:ss', listing: true, hidden: true },
	{ key: 'created_at', type: 'field', dataType: 'datetime', label: 'Created At', format: 'YYYY.MM.DD HH:mm:ss', readonly: true },
	{ key: 'updated_at', type: 'field', dataType: 'datetime', label: 'Updated At', format: 'YYYY.MM.DD HH:mm:ss', readonly: true },
	{
		key: 'action',
		type: 'action',
		label: 'Actions',
		listing: true,
		actions: [
			'edit',
			{
				action: 'schedule',
				class: 'btn-info',
				label: '스케쥴',
				onAction: (row, { $router, model }) => {
					$router.push({
						name: model.views.schedule.name,
						params: model.views.schedule.params(row),
					});
				},
			},
			{
				action: 'profit',
				class: 'btn-warning',
				label: '정산',
				onAction: (row, { $router, model }) => {
					$router.push({
						name: model.views.profit.name,
						params: model.views.profit.params(row),
					});
				},
			},
			'delete',
		],
		hidden: true,
	},
];

const modelName = 'Store';

const modelData = {
	name: modelName,
	namespace: modelName.toLowerCase(),
	icon: 'store',
};

const actions = {
	list: [
		{
			action: 'sendMessage',
			class: 'btn-info',
			label: '알림톡 전송',
			onAction: ({ $router, $store, model, $dialog }) => {
				$dialog
				.confirm(`알림톡을 일괄 전송하시겠습니까 ?`, {
					okText: 'Ok',
					cancelText: 'Cancel'
				})
				.then(async (dialog) => {
					let response = await request[model.types.sendMessage.method](model.types.sendMessage.endpoint());
					let { error, data } = response.data;
					if (error) {
						$dialog
						.confirm(error)
					}
				})
			}
		},
		{
			action: 'batchProfit',
			class: 'btn-warning',
			label: '일괄 정산',
			onAction: (row, { $router, model }) => {
				$router.push({
					name: model.views.batchProfit.name
				});
			},
		},
		'create'
	],
	form: ['submit'],
};

const model = {
	name: modelData.name,
	fields: fields,
	actions: actions,
  groups: groups,
	tabs: tabs,
	types: {
		list: {
			action: `${modelData.namespace}/list`,
			method: 'get',
			endpoint: () => '/v2/admin/stores',
		},
		create: {
			action: `${modelData.namespace}/create`,
			method: 'post',
			endpoint: () => `/v2/admin/stores`,
		},
		edit: {
			action: `${modelData.namespace}/edit`,
			method: 'put',
			endpoint: (options) => `/v2/admin/stores/${options.uid}`,
		},
		get: {
			action: `${modelData.namespace}/get`,
			method: 'get',
			endpoint: (options) => `/v2/admin/stores/${options.uid}`,
		},
		delete: {
			action: `${modelData.namespace}/delete`,
			method: 'delete',
			endpoint: (options) => `/v2/admin/stores/${options.uid}`,
		},
		geocode: {
			action: `${modelData.namespace}/geocode`,
			method: 'get',
			endpoint: (options) => `/v2/admin/stores/geocode`,
		},
		geocodeInKorea: {
			action: `${modelData.namespace}/geocode/inkorea`,
			method: 'get',
			endpoint: (options) => `/v2/admin/stores/geocode/inkorea`,
		},
		profit: {
			action: `${modelData.namespace}/profit`,
			method: 'get',
			endpoint: (options) => `/v2/admin/stores/${options.uid}/profits`,
		},
		settlement: {
			action: `${modelData.namespace}/settlement`,
			method: 'post',
			endpoint: (options) => `/v2/admin/settlement/settlements/new`,
    },
		latestSettlement: {
			action: `${modelData.namespace}/latestSettlement`,
			method: 'get',
			endpoint: (options) => `/v2/admin/settlement/settlements/latest`,
		},
		batchProfit: {
			action: `${modelData.namespace}/batchProfit`,
			method: 'get',
			endpoint: (options) => `/v2/admin/stores/batch/profits`,
		},
		batchSettlement: {
			action: `${modelData.namespace}/batchSettlement`,
			method: 'post',
			endpoint: (options) => `/v2/admin/settlement/settlements/batch/new`,
		},
		batchUpload: {
			action: `${modelData.namespace}/batchUpload`,
			method: 'post',
			endpoint: (options) => `/v2/admin/stores/batch`,
		},
		schedule: {
			list: {
				action: `${modelData.namespace}/scheduleList`,
				method: 'get',
				endpoint: (options) => `/v2/admin/stores/${options.uid}/schedule`,
			},
			add: {
				action: `${modelData.namespace}/scheduleAdd`,
				method: 'post',
				endpoint: (options) => `/v2/admin/stores/${options.uid}/schedule/closed`,
			},
			delete: {
				action: `${modelData.namespace}/scheduleDelete`,
				method: 'delete',
				endpoint: (options) => `/v2/admin/stores/${options.uid}/schedule/closed/${options.schedule_id}`,
			},
		},
		export: {
			action: `${modelData.namespace}/export`,
			method: 'get',
			endpoint: (options) => `/v2/admin/stores/export`,
			fields: {
				'#': 'uid',
				'Store Category': 'store_category',

				'Store Name (한글)': {
					field: 'store_name',
					callback: (value) => {
						return utils.getLocale(value, 'ko');
					},
				},
				// 'Store Name (Base)': {
				// 	field: 'store_name',
				// 	callback: (value) => {
				// 		return utils.getLocale(value, 'base');
				// 	},
				// },

				// 'Store Description': 'store_descript',
				// 'Store Phone': 'store_phone',

				'Store Address (한글)': {
					field: 'store_address',
					callback: (value) => {
						return utils.getLocale(value, 'ko');
					},
				},
				// 'Store Address (Base)': {
				// 	field: 'store_address',
				// 	callback: (value) => {
				// 		return utils.getLocale(value, 'base');
				// 	},
				// },
				'Store Address Detail (한글)': {
					field: 'store_address_detail',
					callback: (value) => {
						return utils.getLocale(value, 'ko');
					},
				},
				// 'Store Address Detail (Base)': {
				// 	field: 'store_address_detail',
				// 	callback: (value) => {
				// 		return utils.getLocale(value, 'base');
				// 	},
				// },
				'Store Floor': {
					field: 'store_address_detail',
					callback: (value) => {
						const address_detail = utils.parseJSON(value);
						if(address_detail.floor) return address_detail.floor
						return ''
					},
				},
				'Store ZipCode': 'store_zipcode',

				// 'Store Directions (한글)': {
				// 	field: 'extra.store_directions',
				// 	callback: (value) => {
				// 		return utils.getLocale(value, 'ko');
				// 	},
				// },
				// 'Store Directions (Base)': {
				// 	field: 'extra.store_directions',
				// 	callback: (value) => {
				// 		return utils.getLocale(value, 'base');
				// 	},
				// },

				연중무휴: 'extra.time_24hour',

				'District Main': 'district_main',
				'District Sub': 'district_sub',
				// Category: 'extra.category',
				// 'Place Name': 'extra.place_name',
				// 'Area Name': 'extra.area_name',
				// 'Nearest Station': 'extra.nearest_station',

				// 'Host ID': 'host_id',
				// 'Host Name': 'host_name',
				'Notify Email': 'notify_email',
				'Notify Phone': 'notify_phone',
				// 'Geo Latitude': 'geo_latitude',
				// 'Geo Longitude': 'geo_longitude',
				// 'Geo Zone': 'geo_zone',
				// Facilities: 'facilities',
				Tags: 'tags',
				// 'Lug Limit': 'lug_limit',
				// 'Lug Bigable Limit': 'lug_bigable_limit',
				// 'Base Price Unit': 'base_price_unit',
				// 'Base Price': 'base_price',
				// 'Time Price': 'time_price',
				// 'Size Price Rate': 'size_price_rate',
				// 'Time Table': {
				// 	field: 'time_table',
				// 	callback: (value) => {
				// 		return JSON.stringify(value);
				// 	},
				// },
				Status: 'status',
				Memo: 'memo',
				// 'Resources': {
				//   field: 'resources',
				//   callback: (value) => {
				//     return JSON.stringify(value)
				//   }
				// },
				// 'Extras': {
				//   field: 'extra',
				//   callback: (value) => {
				//     return JSON.stringify(value)
				//   }
				// },
				'created At': {
					field: 'created_at',
					callback: (value) => {
						return moment(value)
							.tz('Asia/Seoul')
							.format('YYYY-MM-DD HH:mm:ss');
					},
				},
				'updated At': {
					field: 'updated_at',
					callback: (value) => {
						return moment(value)
							.tz('Asia/Seoul')
							.format('YYYY-MM-DD HH:mm:ss');
					},
				},
			},
		},
		profitExport: {
			action: `${modelData.namespace}/profitExport`,
			method: 'get',
			endpoint: (options) => `/v2/admin/stores/batch/profits`,
			fields: {
				'#': 'store_id',
				'상점명': 'store_name_localed',
				'호스트 수익분배(%)': 'profit_rate',
				'호스트 연락처': 'store_notify_phone',
				'호스트 이메일': 'store_notify_email',
				'실 결제금액': 'paid_total',
				'매출 기준액': 'settlement_total',
				'정산 비율': 'profit_rate',
				'호스트 정산금액': 'total_profit',
				'세금계산서 방식': 'tax_bill',
				'원천징수 방식': 'withholding_tax'
			},
		},
		sendMessage: {
			action: `${modelData.namespace}/sendMessage`,
			method: 'get',
			endpoint: (options) => `v2/admin/stores/sendMessage`
		}
	},
	views: {
		list: { name: `List${modelData.name}`, title: `${modelData.name} List` },
    searchList: { name: `Search List`, title: `Search List` },
		create: {
			name: `Create${modelData.name}`,
			title: `Create ${modelData.name}`,
			params: (item) => {
				return { uid: item.uid };
			},
		},
		edit: {
			name: `Edit${modelData.name}`,
			title: `Edit ${modelData.name}`,
			params: (item) => {
				return { uid: item.uid };
			},
		},
		schedule: {
			name: `${modelData.name}Schedule`,
			title: `${modelData.name} Schedule`,
			params: (item) => {
				return { uid: item.uid };
			},
		},
		batch: { name: `${modelData.name}Batch`, title: `${modelData.name} Batch` },
		profit: {
			name: `${modelData.name}Profit`,
			title: `${modelData.name} Profit`,
			params: (item) => {
				return { uid: item.uid };
			},
		},
		batchProfit: { name: `${modelData.name}BatchProfit`, title: `${modelData.name} Batch Profit` },
		sendMessage: { name: `${modelData.name}SendMessage`, title: `${modelData.name} Send Message` }
	},
};

export default {
	namespaced: true,
	model: model,
	routes: [
		{
			path: `/${modelData.namespace}`,
			component: () => import('@/views/layouts/Layout'),
			redirect: `/${modelData.namespace}/list`,
			name: `${modelData.name}`,
			meta: { title: `${modelData.name}`, icon: `${modelData.icon}`, admin_level: 1 },
			children: [
				{
					path: 'create',
					component: () => import('@/views/basic/Form'),
					name: model.views.create.name,
					meta: { mode: 'create', title: model.views.create.title, model: model, noCache: true },
				},
				{
					path: 'schedule/:uid(\\d+)',
					component: () => import('@/views/pages/store/Schedule'),
					name: model.views.schedule.name,
					meta: { mode: 'schedule', title: model.views.schedule.title, model: model, noCache: true },
					hidden: true,
				},
				{
					path: 'batch',
					component: () => import('@/views/pages/store/Batch'),
					name: model.views.batch.name,
					meta: { mode: 'batchProfit', title: model.views.batch.title, model: model, noCache: true },
				},
				{
					path: 'profit/batch',
					component: () => import('@/views/pages/settlement/BatchProfit'),
					name: model.views.batchProfit.name,
					meta: { mode: 'batchProfit', title: model.views.batchProfit.title, model: model, noCache: true },
				},
				{
					path: 'profit/:uid(\\d+)',
					component: () => import('@/views/pages/settlement/Profit'),
					name: model.views.profit.name,
					meta: { mode: 'profit', title: model.views.profit.title, model: model, noCache: true },
					hidden: true,
				},
				{
					path: 'edit/:uid(\\d+)',
					component: () => import('@/views/basic/Form'),
					name: model.views.edit.name,
					meta: { mode: 'edit', title: model.views.edit.title, model: model, noCache: true },
					hidden: true,
				},
				{
					path: 'list',
					component: () => import('@/views/basic/List'),
					name: model.views.list.name,
					meta: { mode: 'list', title: model.views.list.title, model: model, noCache: true },
				},
				{
					path: 'sendMessage',
					component: () => import('@/views/basic/List'),
					name: model.views.sendMessage.name,
					meta: { mode: 'sendMessage', title: model.views.sendMessage.title, model: model, noCache: true },
				},
        ...storeSearch.routes[0].children.map( o => { return { ...o, meta: { ...o.meta, title: 'Store Search'}, path: 'search/'+o.path } }).filter( o => o.path.includes('list') || o.path.includes('manage'))
        // {
				// 	path: 'list/search',
				// 	component: () => import('@/views/basic/List'),
				// 	name: model.views.searchList.name,
				// 	meta: { mode: 'searchList', title: model.views.searchList.title, model: model, noCache: true },
				// },
			],
		},
	],
	state: {
		cached: {},
		items: [],
		batch_profit_default_params: {
			date_start: '',
			date_end: '',
		},
		exchangeRate: VueCookies.get('CURRENT_EXCHANGE_RATE') || 1100,
	},
	mutations: {
		LIST(state, { page, size, items }) {
			items.forEach((item) => {
				state.cached[item.uid] = item;
			});

			state.items = items;
		},
		CREATE(state, { item }) {},
		EDIT(state, { item }) {},
		GET(state, { item }) {
			state.cached[item.uid] = item;
		},
		BATCH_PROFIT(state, payload) {
			let { date_start, date_end } = payload;

			state.batch_profit_default_params.date_start = new Date(date_start);
			state.batch_profit_default_params.date_end = new Date(date_end);
		},
		SET_EXCHANGE_RATE(state, payload) {
			let { exchange_rate } = payload;
			state.exchangeRate = exchange_rate;

			VueCookies.set('CURRENT_EXCHANGE_RATE', exchange_rate, '90d');
		},
	},
	getters: {
		batchProfitDefaultParams: (state) => {
			return state.batch_profit_default_params;
		},
		exchangeRate: (state) => {
			return state.exchangeRate;
		},
	},
	actions: {
		list({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let { group } = options;
					let response = await request[model.types.list.method](model.types.list.endpoint(options), { params: options });
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					commit('LIST', data);
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		create({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.create.method](model.types.create.endpoint(options), options);
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					commit('CREATE', data);
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		edit({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
          let response = await request[model.types.edit.method](model.types.edit.endpoint(options), options);
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					commit('EDIT', { item: data });
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		get({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.get.method](model.types.get.endpoint(options), { params: options });
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					commit('GET', { item: data });
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		delete({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.delete.method](model.types.delete.endpoint(options), { params: options });
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		geocode({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.geocode.method](model.types.geocode.endpoint(options), { params: options });
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		geocodeInKorea({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.geocodeInKorea.method](model.types.geocodeInKorea.endpoint(options), { params: options });
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		profit({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.profit.method](model.types.profit.endpoint(options), { params: options });
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		settlement({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.settlement.method](model.types.settlement.endpoint(options), options);
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		latestSettlement({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.latestSettlement.method](model.types.latestSettlement.endpoint(options), { params: options });
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		batchProfit({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					commit('BATCH_PROFIT', options);

					let response = await request[model.types.batchProfit.method](model.types.batchProfit.endpoint(options), { params: options });
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		batchSettlement({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.batchSettlement.method](model.types.batchSettlement.endpoint(options), options);
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		scheduleList({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let { group } = options;
					let response = await request[model.types.schedule.list.method](model.types.schedule.list.endpoint(options), { params: options });
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					commit('LIST', data);
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		scheduleAdd({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.schedule.add.method](model.types.schedule.add.endpoint(options), options);
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		scheduleDelete({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.schedule.delete.method](model.types.schedule.delete.endpoint(options), { params: options });
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		export({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.export.method](model.types.export.endpoint(options), { params: options });
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		profitExport({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.profitExport.method](model.types.profitExport.endpoint(options), { params: options });
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		batchUpload({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.batchUpload.method](model.types.batchUpload.endpoint(options), options);
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},

		setExchangeRate({ commit }, options) {
			commit('SET_EXCHANGE_RATE', options);
		},

		sendMessage({ commit }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.sendMessage.method](model.types.sendMessage.endpoint(options), { params: options });
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					resolve(data);
				} catch (e) {
					reject(e);
				}
			})
		}
	},
};
