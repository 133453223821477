<template>
  <div class="loader">
    <square-donut v-if="type == 'square-donut'"></square-donut>
    <square-scale-ripple v-if="type == 'square-scale-ripple'"></square-scale-ripple>
  </div>
</template>

<script>
import SquareDonut from "./animations/SquareDonut"
import SquareScaleRipple from "./animations/SquareScaleRipple"

export default {
  props: {
    type: String
  },
  components: {
    SquareDonut, SquareScaleRipple
  }
}
</script>
