<template>
<div>
  <input-checkbox
    v-for="(option, i) in options"
    :key="i"
    :name="name + '[]'"
    :expected="option.value"
    :value="items.includes(option.value) && option.value"
    :readonly="readonly"
    :disabled="disabled"
    :ripple="ripple"
    @change="onChange($event, option)">{{option.label}}</input-checkbox>
</div>
</template>

<script>
export default {
  mounted() {
    let items = typeof this.value === 'string' ? this.value.split(',') : this.value
    this.$data.items = this.filteredItems(items)
  },
  data() {
    return {
      debug: true,
      items: []
    }
  },
  props: {
    name: {
      type: String,
      default: 'check'
    },
    options: {
      type: Array,
      default: []
    },
    value: {
      type: [String, Array],
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    ripple: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    value(value, oldValue) {
      let items = typeof value === 'string' ? value.split(',') : value
      this.$data.items = this.filteredItems(items)
    }
  },
  methods: {
    filteredItems( items ) {
      let newItems = []
      if (this._.isArray(items)) {
        this._.forEach(items, item => {
          let trimmedItem = this._.trim(item)
          if (typeof trimmedItem !== 'undefined' && typeof trimmedItem === 'string' && trimmedItem !== '') {
            newItems.push(trimmedItem)
          }
        })
      }
      return newItems
    },

    setValue( items ) {
      if (typeof this.value === 'string') {
        this.$emit("input", items.join(','))
      }
      else {
        this.$emit("input", items)
      }
    },

    onChange(value, option) {

      let items = this.items

      if (typeof value === 'undefined' || typeof value === 'boolean' && value === false) {
        if (items.includes(option.value)) {
          let newItems = []
          this._.forEach(this.items, item => {
            if (item !== option.value) {
              newItems.push(item)
            }
          })
          items = newItems
        }
      }
      else {
        if (!items.includes(option.value)) {
          items.push(option.value)
        }
      }

      this.setValue( items )
    }
  }
}
</script>
