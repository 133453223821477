import Multipane from './multipane.vue'
import MultipaneResizer from './multipane-resizer.vue'

export { Multipane, MultipaneResizer }

const plugin = {
  install (Vue, {loadComponent = true} = {}) {
    Vue.component('multipane', Multipane)
    Vue.component('multipane-resizer', MultipaneResizer)
  }
}

typeof window.Vue === 'function' && window.Vue.use(plugin)

export default plugin
