import _ from 'lodash'
import request from '@/utils/network'

const fields = [
  { key: 'uid', type: 'uid', label: '#', listing: true, primaryKey: true },
  { key: 'store_name', type: 'text', label: 'Store Name', listing: true },
  { key: 'user_id', type: 'user-select', label: 'User ID', listing: true, editable: false },
  { key: 'bank_owner_name', type: 'text', label: 'Owner Name', listing: true },
  { key: 'bank_owner_address', type: 'text', label: 'Bank Owner Address' },
  { key: 'bank_country', type: 'select', label: 'Bank Country', listing: true, options: [
    { label: "KR", value: "KR" }
  ] },
  { key: 'bank_code', type: 'bank-select', label: 'Bank Code', onSelect: (component, value, data) => {
    let { name } = value
    component.$data.data['bank_name'] = name
  } },
  { key: 'bank_name', type: 'text', label: 'Bank Name', listing: true, readonly: true },
  { key: 'bank_account', type: 'text', label: 'Bank Account', listing: true },
  { key: 'bank_routing', type: 'text', label: 'Bank Routing' },
  { key: 'bank_swift_code', type: 'text', label: 'Bank Swift Code' },

  { key: 'is_primary', type: 'checkbox', label: 'Is Primary', listing: true, defaultValue: true, alt: "기본값으로 처리하려면 체크해주세요", alias: (field, row) => {
    let value = row[field.key] || ''
    return value === true ? 'PRIMARY' : ''
  }, },

  // { key: 'extra', type: 'extra-editor', label: 'Extras',
  //   fields: [
  //
  //   ]
  // },

  // { key: 'resources', type: 'resource-editor', label: 'Resources',
  //   fields: [
  //
  //   ]
  // },

  { key: 'actions', type: 'action', actions: ['edit','create'], label: '관리', listing: true, hidden: true }
]

const modelName = "BankAccount"

const modelData = {
  name: modelName,
  namespace: modelName.toLowerCase(),
  icon: 'university'
}

const actions = {
  list: ['create'],
  form: ['submit']
}

const model = {
  name: modelData.name,
  fields: fields,
  actions: actions,
  types: {
    bankList: {
      action: `${modelData.namespace}/bankList`,
      method: 'get',
      endpoint: () => '/v2/admin/settlement/bank/codes'
    },
    bank: {
      action: `${modelData.namespace}/bank`,
      method: 'get',
      endpoint: (options) => `/v2/admin/settlement/bank/${options.code}`
    },
    list: {
      action: `${modelData.namespace}/list`,
      method: 'get',
      endpoint: () => '/v2/admin/settlement/bank/account'
    },
    create: {
      action: `${modelData.namespace}/create`,
      method: 'post',
      endpoint: () => `/v2/admin/settlement/bank/account`
    },
    edit: {
      action: `${modelData.namespace}/edit`,
      method: 'put',
      endpoint: (options) => `/v2/admin/settlement/bank/account/${options.uid}`
    },
    get: {
      action: `${modelData.namespace}/get`,
      method: 'get',
      endpoint: (options) => `/v2/admin/settlement/bank/account/${options.uid}`
    }
  },
  views: {
    list: { name: `List${modelData.name}`, title: `${modelData.name} List` },
    create: { name: `Create${modelData.name}`, title: `Create ${modelData.name}`, params: (item) => { return { uid: item.uid } } },
    edit: { name: `Edit${modelData.name}`, title: `Edit ${modelData.name}`, params: (item) => { return { uid: item.uid } } }
  }
}

export default {
  namespaced: true,
  model: model,
  routes: [
    {
      path: 'bank/account/create',
      component: () => import('@/views/basic/Form'),
      name: model.views.create.name,
      meta: { mode: 'create', title: model.views.create.title, model: model, noCache: true }
    },
    {
      path: 'bank/account/edit/:uid(\\d+)',
      component: () => import('@/views/basic/Form'),
      name: model.views.edit.name,
      meta: { mode: 'edit', title: model.views.edit.title, model: model, noCache: true },
      hidden: true
    },
    {
      path: 'bank/account/list',
      component: () => import('@/views/basic/List'),
      name: model.views.list.name,
      meta: { mode: 'list', title: model.views.list.title, model: model, noCache: true }
    }
  ],
  state: {

  },
  mutations: {

  },
  actions: {
    bankList({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let { group } = options
          let response = await request[model.types.bankList.method](model.types.bankList.endpoint(options), { params: { ...options } })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    bank({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let { group } = options
          let response = await request[model.types.bank.method](model.types.bank.endpoint(options), { params: { ...options } })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    list({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let { group } = options
          let response = await request[model.types.list.method](model.types.list.endpoint(options), { params: { ...options } })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    create({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.create.method](model.types.create.endpoint(options), { ...options })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    edit({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.edit.method](model.types.edit.endpoint(options), { ...options })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    get({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.get.method](model.types.get.endpoint(options), { params: { ...options } })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    }
  }
}
