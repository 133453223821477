import _ from 'lodash'
import request from '@/utils/network'

const fields = []

const modelName = "Analytics"

const modelData = {
  name: modelName,
  namespace: modelName.toLowerCase(),
  icon: 'file-alt'
}

const actions = {
  list: ['create'],
  form: ['submit']
}

const model = {
  name: modelData.name,
  fields: fields,
  actions: actions,
  types: {
    dashboard: {
      action: `${modelData.namespace}/dashboard`,
      method: 'get',
      endpoint: (options) => `/v2/admin/analytics/dashboard`
    }
  },
  views: {

  }
}

export default {
  namespaced: true,
  model: model,
  routes: [

  ],
  state: {

  },
  mutations: {

  },
  actions: {
    dashboard({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.dashboard.method](model.types.dashboard.endpoint(options), { params: options })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    }
  }
}
