
import config from "@/config"

// Bootstrap
import VueBootstrap from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// Progressive Image
import VueProgressiveImage from "vue-progressive-image"

// Chart
import Raphael from 'raphael/raphael'
import VueSparkline from 'vue-sparklines'
import { DonutChart, BarChart, LineChart, AreaChart } from 'vue-morris'

global.Raphael = Raphael

// PostCode
import VueDaumPostcode from "vue-daum-postcode"

// Pace
import 'pace-progressbar'
import 'pace-progressbar/themes/blue/pace-theme-minimal.css'

// Resize
import VueResize from 'vue-resize'
import 'vue-resize/dist/vue-resize.css'

// Dialog
import VueDialog from 'vuejs-dialog'
import VueDialogMixin from 'vuejs-dialog/dist/vuejs-dialog-mixin.min.js'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'

// SvgIcon
import VueSvgIcon from './svg-icon'

// Portal
import VuePortal from 'portal-vue'

// Datetime
import VueDatetime from './datetime'

// Material
import VueMaterial from './material'

// Export
import VueExport from './export'

//ExportToJson
import VueExportJson from './exportJson'

// Toast
import VueToast from './toast'

// Loader
import VueLoader from "./loader"

// Cookie
import VueCookies from 'vue-cookies'

// Search Select
import { ModelSelect } from 'vue-search-select'
import 'vue-search-select/dist/VueSearchSelect.css'

// Editor
import VueQuillEditor from 'vue-quill-editor'
import VueCodeMirror from 'vue-codemirror'
import VueMultipane from './multipane'

// Utils
import moment from 'moment-timezone'
import lodash from 'lodash'
import request from '@/utils/network'
import utils from '@/utils'
import macro from 'node-macro'
import validator from '@/utils/validator';

import Viewer from 'v-viewer';

const plugin = {
  install (Vue, {loadComponent = true} = {}) {

    VueCookies.config('7d')

    for ( var iconName in fas ) {
      library.add(fas[iconName])
    }

    Vue.component('fa-icon', FontAwesomeIcon)
    Vue.component('chart-area', AreaChart)
    Vue.component('chart-bar', BarChart)
    Vue.component('chart-donut', DonutChart)
    Vue.component('chart-line', LineChart)
    Vue.component('search-select', ModelSelect)

    Vue.use(VueBootstrap)
    Vue.use(VueCodeMirror)
    Vue.use(VueCookies)
    Vue.use(VueDatetime)
    Vue.use(VueDaumPostcode, { name: 'daum-postcode' })
    Vue.use(VueDialog)
    Vue.use(VueExport)
    Vue.use(VueExportJson)
    Vue.use(VueLoader)
    Vue.use(VueMaterial)
    Vue.use(VueMultipane)
    Vue.use(VuePortal)
    Vue.use(VueProgressiveImage)
    Vue.use(VueQuillEditor)
    Vue.use(VueResize)
    Vue.use(VueSparkline)
    Vue.use(VueSvgIcon)
    Vue.use(VueToast)
    Vue.use(Viewer);

    Vue.directive('click-outside', {
      bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          // here I check that click was outside the el and his childrens
          if (!(el == event.target || el.contains(event.target))) {
            // and if it did, call method provided in attribute value
            vnode.context[binding.expression](event)
          }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      }
    })

    Vue.prototype._ = lodash
    Vue.prototype.$macro = function(options) { return new macro(options) }
    Vue.prototype.$moment = moment
    Vue.prototype.$progress = Pace
    Vue.prototype.$request = request
    Vue.prototype.$utils = utils
    Vue.prototype.$validator = validator
  }
}

typeof window.Vue === 'function' && window.Vue.use(plugin)

export default plugin
