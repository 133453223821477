import request from '@/utils/network';
import utils from '@/utils';

const region = {
	namespaced: true,
	state: {
		items: {},
	},
	mutations: {
		LIST(state, { items }) {
			state.items = items;
		},
		CREATE(state, { item }) {},
		EDIT(state, { item }) {},
		DELETE(state, { item }) {},
		GET(state, { item }) {},
	},
	getters: {
		GET_ITEMS: (state, getters) => (payload) => {
			//payload = { depth : 1 }
			let filteredItems = [];
			if (payload && payload.key && payload.value) {
				Object.keys(state.items).forEach((o) => {
					if (state.items[o][payload.key] == payload.value) {
						filteredItems = state.items[o];
					}
				});
			} else {
				filteredItems = state.items;
			}
			return filteredItems;
		},
	},
	actions: {
		list({ state, commit, dispatch, rootState }, payload) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request['get']('/v2/admin/recommendRegions');
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					commit('LIST', data);
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		create({ state, commit, dispatch, rootState }, payload) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request['post']('/v2/admin/recommendRegions', payload);
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					commit('CREATE', data);
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		edit({ state, commit, dispatch, rootState }, payload) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request['put'](`/v2/admin/recommendRegions/${payload.id}`, payload.data);
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					commit('EDIT', data);
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		delete({ state, commit, dispatch, rootState }, payload) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request['delete'](`/v2/admin/recommendRegions/${payload.id}`);
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		get({ state, commit, dispatch, rootState }, payload) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request['get'](`/v2/admin/recommendRegions/${payload.id}`);
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					commit('GET', data);
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
	},
};

export default region;
