import delivery from "./delivery.ticket"
import luggage from "./luggage.ticket"
import locker from "./locker.ticket"
import products from "./service.ticket"

let routes = [
  ...luggage.routes[0].children.map( o => { return { ...o, meta: { ...o.meta, title: 'Storage(물품보관)' }, path: 'luggage/'+o.path } }).filter( o => o.path.includes('list') || o.path.includes('manage')),
  ...delivery.routes[0].children.map( o => { return { ...o, meta: { ...o.meta, title: 'Delivery(배송)' }, path: 'delivery/'+o.path } }).filter( o => o.path.includes('list') || o.path.includes('manage')),
  ...locker.routes[0].children.map( o => { return { ...o, meta: { ...o.meta, title: 'Locker(락커)' }, path: 'locker/'+o.path } }).filter( o => o.path.includes('list') || o.path.includes('manage')),
  ...products.routes[0].children.map( o => { return { ...o, meta: { ...o.meta, title: 'Contents(콘텐츠)' }, path: 'products/'+o.path } }).filter( o => o.path.includes('list') || o.path.includes('manage'))
]



export default {
  routes : [
    {
      path: `/tickets`,
			component: () => import('@/views/layouts/Layout'),
			redirect: `/tickets/luggage/list`,
			name: `Tickets`,
      meta: { title: `Ticket`, icon: `ticket-alt`, admin_level: 1 },
      children: routes
    }
  ]
}