import request from '@/utils/network';
import utils from '@/utils';
import moment from 'moment';

const groups = [
	{ label: '전체', value: '' },
	{ label: '발급완료', value: 'ISSUED' },
  { label: '체크인', value: 'CHECK_IN' },
  { label: '배정중', value: 'ASSIGNING'},
  { label: '승인됨', value: 'APPROVED'},
  { label: '물품인수', value: 'PICK_UP'},
  { label: '전달완료', value: 'DROP_OFF'},
	{ label: '체크아웃', value: 'CHECK_OUT' },
	{ label: '예약취소', value: 'CANCELLED' },
	{ label: '만료', value: 'EXPIRED' }
];

const issue_groups = [
	{ label: '서비스API', value: 'DELIVERY'},
	{ label: '오픈API', value: 'DELIVERY_O'},
]

const fields = [
	{ key: 'uid', type: 'uid', label: '#', listing: true, primaryKey: true },
	{ key: 'ticket_type', type: 'text', label: 'Ticket Type', readonly: true },
	{ key: 'ticket_code', type: 'text', label: 'Ticket Code', listing: true, readonly: true },
	{
		key: 'sender_place',
		type: 'text',
		label: '출발지(상점명)',
		listing: true,
		alias: (field, row) => {
			let value = row.product_data;

			return value.sender_store_id > 0 ? value.sender_store_name : value.sender_place
		},
		hidden: true,
	},
	{
		key: 'recipient_place',
		type: 'text',
		label: '도착지(상점명)',
		listing: true,
		alias: (field, row) => {
			let value = row.product_data;

			return value.recipient_store_id > 0 ? value.recipient_store_name : value.recipient_place
		},
		hidden: true,
	},
  {
		key: 'user_name',
		type: 'text',
		label: '예약자',
		alias: (field, row) => {
      const value = utils.parseJSON(row.reserve);

			return value.user_first_name && value.user_last_name ? `${value.user_first_name} ${value.user_last_name}` : row.user_name;
		},
		listing: true,
		hidden: true,
	},
	{
		key: 'recipient_user',
		type: 'text',
		label: '받는사람',
		alias: (field, row) => {
      const value = utils.parseJSON(row.product_data);

			return value.user_first_name && value.user_last_name ? `${value.user_first_name} ${ value.user_last_name}` : value.recipient_name;
		},
		listing: true,
		hidden: true,
	},
	{
		key: 'user_id',
		type: 'link',
		label: 'User ID',
		readonly: true,
		link: (item, { $router }) => {
			$router.push({
				name: 'EditUser',
				params: { uid: item.user_id },
			});
		},
	},
	{
		key: 'store_id',
		type: 'link',
		label: 'Store ID',
		readonly: true,
		link: (item, { $router }) => {
			$router.push({
				name: 'EditStore',
				params: { uid: item.store_id },
			});
		},
  },
	{
		key: 'receipt_id',
		type: 'link',
		label: 'Receipt ID',
		readonly: true,
		link: (item, { $router }) => {
			$router.push({
				name: 'ViewReceipt',
				params: { uid: item.receipt_id },
			});
		},
	},
	{
		key: 'paid_total',
		type: 'text',
		label: '결제금액',
		alias: (field, row) => {
			if(row.hasOwnProperty('paid_total') && parseInt(row.paid_total) >= 0){
				return row.paid_total.toLocaleString() + '₩'
			}
		},
		listing: true,
		readonly: true
	},
	{
		key: 'ticket_date',
		type: 'text',
		label: 'Ticket Date',
		alias: (field, row) => {
			let start = moment(row.ticket_date_start);
			let end = moment(row.ticket_date_end);
			if (start.format('YYYYMMDD') === end.format('YYYYMMDD')) {
				return `${moment(row.ticket_date_start).format('YYYY-MM-DD HH시')} ~ ${moment(row.ticket_date_end).format('HH시')}`;
			}
			return `${moment(row.ticket_date_start).format('YYYY-MM-DD HH시')} ~ ${moment(row.ticket_date_end).format('YYYY-MM-DD HH시')}`;
		},
		listing: true,
		hidden: true,
	},
	{ key: 'ticket_date_start', type: 'text', label: 'Ticket Start', readonly: true },
	{ key: 'ticket_date_end', type: 'text', label: 'Ticket End', readonly: true },
	{ key: 'ticket_quantity', type: 'text', label: 'Ticket Quantity', readonly: true },
	{ key: 'ticket_over_quantity', type: 'text', label: 'Ticket Over Quantity', readonly: true },

	{ key: 'datetime_issued', type: 'field', dataType: 'datetime', label: '예약일', format: 'YYYY-MM-DD HH:mm', readonly: true, listing: true },
	{ key: 'datetime_check_in', type: 'field', dataType: 'datetime', label: 'CheckIn At', format: 'YYYY-MM-DD HH:mm', readonly: true },
	{ key: 'datetime_check_out', type: 'field', dataType: 'datetime', label: 'CheckOut At', format: 'YYYY-MM-DD HH:mm', readonly: true },
	{ key: 'datetime_cancelled', type: 'field', dataType: 'datetime', label: 'Canceled At', format: 'YYYY-MM-DD HH:mm', readonly: true },

	{
		key: 'status',
		type: 'text',
		label: 'Status',
		listing: true,
		readonly: true,
		options: _.filter(groups, (group, index) => index > 0),
		locale: (value) => {
			let items = groups.filter((item) => item.value === value);
			return items.length > 0 ? items[0].label : value;
		},
	},

	// { key: 'updated_at', type: 'datetime', label: 'Timestamp', format:'YYYY-MM-DD HH:mm', listing: true, hidden: true },
	{ key: 'created_at', type: 'field', dataType: 'datetime', label: '예약일', format: 'YYYY-MM-DD HH:mm', readonly: true },
	{ key: 'updated_at', type: 'field', dataType: 'datetime', label: 'Updated At', format: 'YYYY-MM-DD HH:mm', readonly: true },
	{
		key: 'action',
		type: 'action',
		label: 'Actions',
		listing: true,
		actions: [
			{
				action: 'manage',
				class: 'btn-info',
				label: '관리',
				onAction: (row, { $router, model }) => {
					$router.push({
						name: model.views.manage.name,
						params: model.views.manage.params(row),
					});
				},
      },
		],
		hidden: true,
	},
];

const modelName = 'Delivery';

const modelData = {
	name: modelName,
	namespace: modelName.toLowerCase(),
	icon: 'truck-loading',
};

const actions = {};

const model = {
	name: modelData.name,
	fields: fields,
	actions: actions,
	groups: groups,
	types: {
		list: {
			action: `${modelData.namespace}/list`,
			method: 'get',
			endpoint: () => '/v2/admin/delivery/tickets',
		},
		get: {
			action: `${modelData.namespace}/get`,
			method: 'get',
			endpoint: (options) => `/v2/admin/delivery/tickets/${options.uid}`,
		},
		checkin: {
			action: `${modelData.namespace}/checkin`,
			method: 'put',
      endpoint: (options) => `/v2/admin/luggage/tickets/${options.uid}/checkin`,
      message: (options) =>  `${options.uid} 티켓을 체크인으로 처리하시겠습니까?`
		},
		checkout: {
			action: `${modelData.namespace}/checkout`,
			method: 'put',
			endpoint: (options) => `/v2/admin/luggage/tickets/${options.uid}/checkout`,
		},
		checkoutAfter: {
			action: `${modelData.namespace}/checkoutAfter`,
			method: 'put',
			endpoint: (options) => `/v2/admin/luggage/tickets/${options.uid}/after/checkout`,
    },
    reverseStatus: {
			action: `${modelData.namespace}/reverseStatus`,
			method: 'post',
			endpoint: (options) => `/v2/admin/luggage/tickets/${options.uid}/reverseStatus`,
		},
	},
	views: {
		list: { name: `List${modelData.name}`, title: `${modelData.name} List` },
		edit: {
			name: `Edit${modelData.name}`,
			title: `Edit ${modelData.name}`,
			params: (item) => {
				return { uid: item.uid };
			},
		},
		manage: { name: `Manage${modelData.name}`, title: `${modelData.name} Manage`, params: (item) => { return { uid: item.uid }}},
	},
};

export default {
	namespaced: true,
	model: model,
	routes: [
		{
			path: `/${modelData.namespace}`,
			component: () => import('@/views/layouts/Layout'),
			redirect: `/${modelData.namespace}/list`,
			name: `${modelData.name}`,
			meta: { title: `${modelData.name}`, icon: `${modelData.icon}` },
			children: [
				{
					path: 'edit/:uid(\\d+)',
					component: () => import('@/views/basic/Form'),
					name: model.views.edit.name,
					meta: { mode: 'edit', title: model.views.edit.title, model: model, noCache: true },
					hidden: true,
				},
				{
					path: 'manage/:uid(\\d+)',
					component: () => import('@/views/pages/delivery/Manage'),
					name: model.views.manage.name,
					meta: { mode: 'manage', title: model.views.manage.title, model: model, noCache: true },
					hidden: true,
				},
				{
					path: 'list',
					component: () => import('@/views/basic/List'),
					name: model.views.list.name,
					meta: { mode: 'list', title: model.views.list.title, model: model, noCache: true },
				},
			],
		},
	],
	state: {
		cached: {},
		items: [],
	},
	mutations: {
		LIST(state, { page, size, items }) {
			items.forEach((item) => {
				state.cached[item.uid] = item;
			});

			state.items = items;
		},
		CREATE(state, { item }) {},
		EDIT(state, { item }) {},
		GET(state, { item }) {
			state.cached[item.uid] = item;
		},
	},
	actions: {
		list({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let { group } = options;
          let response = await request[model.types.list.method](model.types.list.endpoint(options), { params: { status: group, ...options } });
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					commit('LIST', data);
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		create({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.create.method](model.types.create.endpoint(options), options);
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					commit('CREATE', data);
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		edit({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.edit.method](model.types.edit.endpoint(options), options);
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					commit('EDIT', { item: data });
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		get({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.get.method](model.types.get.endpoint(options), { params: options });
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					commit('GET', { item: data });
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		checkin({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.checkin.method](model.types.checkin.endpoint(options), options);
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		checkout({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.checkout.method](model.types.checkout.endpoint(options), options);
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		checkoutAfter({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.checkoutAfter.method](model.types.checkoutAfter.endpoint(options), options);
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					resolve(data);
				} catch (e) {
					reject(e);
				}
      });
    },
    reverseStatus({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.reverseStatus.method](model.types.reverseStatus.endpoint(options), options);
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					resolve(data);
				} catch (e) {
					reject(e);
				}
      });
    },
		// export({ commit, rootGetters }, options) {
		// 	return new Promise(async (resolve, reject) => {
		// 		try {
		// 			let response = await request[model.types.export.method](model.types.export.endpoint(options), { params: options });
		// 			let { error, data } = response.data;
		// 			if (error) {
		// 				return reject(new Error(error));
		// 			}
		// 			resolve(data);
		// 		} catch (e) {
		// 			reject(e);
		// 		}
		// 	});
		// },
	},
};
