import ToastCenter from "./ToastCenter"

ToastCenter.install = (Vue, options = {}) => {

  let ToastComponent = Vue.extend({
    render: h => {
      return h(ToastCenter, {
        props: {
          options: options
        },
        ref: "vueToast"
      })
    }
  })

  var component = new ToastComponent().$mount()

  document.body.appendChild(component.$el)

  Vue.prototype.$toast = component.$refs.vueToast
}

// Install by default if using the script tag
// equal to Vue.use(window.vueToastr)
if (typeof window !== "undefined" && window.Vue) {
  // console.log(window.Vue)
  window.Vue.use(ToastCenter)
}

export default ToastCenter
