<template>
  <div>
    <div
      v-bind:class="'toast-container ' + position"
      v-for="(toasts, position) in list"
      :key="position"
    >
      <toast :data="toast" v-for="(toast, index) in toasts" :key="index">
        <slot></slot>
      </toast>
    </div>
  </div>
</template>

<script>
import toast from "./Toast.vue"
export default {
  name: "ToastCenter",
  props: {
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    var positions = [
      "toast-top-right",
      "toast-bottom-right",
      "toast-bottom-left",
      "toast-top-left",
      "toast-top-full-width",
      "toast-bottom-full-width",
      "toast-top-center",
      "toast-bottom-center"
    ]
    var list = {}
    for (var i = 0; i <= positions.length - 1; i++) {
      list[positions[i]] = {}
    }
    return {
      positions,
      defaultClassNames: this.processOption("defaultClassNames", []),
      defaultPosition: this.processOption("defaultPosition", "toast-top-right"),
      defaultType: this.processOption("defaultType", "success"),
      defaultCloseOnHover: this.processOption("defaultCloseOnHover", true),
      defaultTimeout: this.processOption("defaultTimeout", 5000),
      defaultProgressBar: this.processOption("defaultProgressBar", true),
      defaultProgressBarValue: this.processOption(
        "defaultProgressBarValue",
        null
      ),
      defaultPreventDuplicates: this.processOption(
        "defaultPreventDuplicates",
        false
      ),
      defaultStyle: this.processOption("defaultStyle", {}),
      list,
      index: 0,
      savedNames: {}
    }
  },
  created() {
    // console.log("Created");
  },
  mounted() {
    // console.log("ready", this.list);
  },
  components: {
    toast
  },
  methods: {
    addToast(data) {
      this.index++
      data["index"] = this.index
      this.$set(this.list[data.position], this.index, data)
      if (typeof data["name"] !== "undefined") {
        this.$set(this.savedNames, data["name"], data)
      }
      // if have onCreated
      if (typeof data.onCreated !== "undefined") {
        // wait doom update after call cb
        this.$nextTick(() => {
          data.onCreated()
        })
      }
    },
    removeByName(name) {
      if (typeof this.savedNames[name] !== "undefined") {
        this.Close(this.savedNames[name])
        this.$delete(this.savedNames, name)
      }
    },
    removeToast(data) {
      var item = this.list[data.position][data.index]
      // console.log("remove toast", data, item);
      if (typeof item !== "undefined") {
        this.$delete(this.list[data.position], data.index)
        // if have onClosed
        if (typeof data.onClosed !== "undefined") {
          // wait doom update after call cb
          this.$nextTick(() => {
            data.onClosed()
          })
        }
      }
    },
    setProgress(data, newValue) {
      var item = this.list[data.position][data.index]
      if (typeof item !== "undefined") {
        this.$set(item, "progressBarValue", newValue)
      }
    },
    Add(d) {
      return this.AddData(this.processObjectData(d))
    },
    AddData(data) {
      if (typeof data !== "object") {
        //console.log("AddData accept only Object", data);
        return false
      }
      if (data.preventDuplicates) {
        var listKeys = Object.keys(this.list[data.position])
        for (var i = 0; i < listKeys.length; i++) {
          if (
            this.list[data.position][listKeys[i]].title === data.title &&
            this.list[data.position][listKeys[i]].msg === data.msg
          ) {
            //console.log("Prevent Duplicates", data);
            return false
          }
        }
      }
      this.addToast(data)
      return data
    },
    processOption(optionValue, defaultValue) {
      if (!this.options) {
        return defaultValue
      }
      return typeof this.options[optionValue] !== "undefined"
        ? this.options[optionValue]
        : defaultValue
    },
    processObjectData(data) {
      // if Object
      if (typeof data === "object" && typeof data.msg !== "undefined") {
        if (typeof data.classNames === "undefined") {
          data.classNames = this.defaultClassNames
        }
        if (typeof data.position === "undefined") {
          data.position = this.defaultPosition
        }
        if (typeof data.type === "undefined") {
          data.type = this.defaultType
        }
        if (typeof data.timeout === "undefined") {
          data.timeout = this.defaultTimeout
        }
        // have progressBar ?
        if (typeof data.progressbar === "undefined") {
          data.progressbar = this.defaultProgressBar
        }
        // should progressBar be bound to timer or is set manually ?
        if (typeof data.progressBarValue === "undefined") {
          data.progressBarValue = this.defaultProgressBarValue
        }

        if (typeof data.closeOnHover === "undefined") {
          data.closeOnHover = this.defaultCloseOnHover
        }

        if (typeof data.preventDuplicates === "undefined") {
          data.preventDuplicates = this.defaultPreventDuplicates
        }

        if (typeof data.style === "undefined") {
          data.style = this.defaultStyle
        }
        return data
      }
      // if String
      return {
        msg: data.toString(),
        position: this.defaultPosition,
        type: this.defaultType,
        timeout: this.defaultTimeout,
        closeOnHover: this.defaultCloseOnHover,
        progressbar: this.defaultProgressBar,
        progressBarValue: this.defaultProgressBarValue,
        preventDuplicates: this.defaultPreventDuplicates,
        style: this.defaultStyle,
        classNames: this.defaultClassNames
      }
    },
    error(msg, title) {
      var data = this.processObjectData(msg)
      data["type"] = "error"
      if (typeof title !== "undefined") {
        data["title"] = title
      }
      return this.AddData(data)
    },
    success(msg, title) {
      var data = this.processObjectData(msg)
      data["type"] = "success"
      if (typeof title !== "undefined") {
        data["title"] = title
      }
      return this.AddData(data)
    },
    warning(msg, title) {
      var data = this.processObjectData(msg)
      data["type"] = "warning"
      if (typeof title !== "undefined") {
        data["title"] = title
      }
      return this.AddData(data)
    },
    info(msg, title) {
      var data = this.processObjectData(msg)
      data["type"] = "info"
      if (typeof title !== "undefined") {
        data["title"] = title
      }
      return this.AddData(data)
    },
    Close(data) {
      // console.log(data)
      this.removeToast(data)
    },
    removeByType(toastType) {
      for (var i = 0; i < this.positions.length; i++) {
        var listKeys = Object.keys(this.list[this.positions[i]])
        for (var j = 0; j < listKeys.length; j++) {
          if (this.list[this.positions[i]][listKeys[j]]["type"] === toastType) {
            this.Close(this.list[this.positions[i]][listKeys[j]])
          }
        }
      }
    },
    clearAll() {
      for (var i = 0; i < this.positions.length; i++) {
        var listKeys = Object.keys(this.list[this.positions[i]])
        for (var j = 0; j < listKeys.length; j++) {
          this.Close(this.list[this.positions[i]][listKeys[j]])
        }
      }
    }
  }
}
</script>
<style lang="scss">

// Mix-ins
@mixin borderRadius($radius) {
    -moz-border-radius: $radius;
    -webkit-border-radius: $radius;
    border-radius: $radius;
}

@mixin boxShadow($boxShadow) {
    -moz-box-shadow: $boxShadow;
    -webkit-box-shadow: $boxShadow;
    box-shadow: $boxShadow;
}

@mixin opacity($opacity) {
    $opacityPercent: $opacity * 100;
    opacity: $opacity;
}

@mixin wordWrap($wordWrap: break-word) {
    -ms-word-wrap: $wordWrap;
    word-wrap: $wordWrap;
}

// Variables
$black: #000000;
$grey: #999999;
$light-grey: #CCCCCC;
$white: #FFFFFF;
$near-black: #030303;
$green: #51A351;
$red: #BD362F;
$blue: #2F96B4;
$orange: #F89406;
$default-container-opacity: .8;

// Styles
.toast-title {
    font-weight: bold;
}

.toast-message {
    @include wordWrap();

    a,
    label {
        color: $white;
    }

    a:hover {
        color: $light-grey;
        text-decoration: none;
    }
}

.toast-close-button {
    position: relative;
    right: -0.3em;
    top: -0.3em;
    float: right;
    font-size: 20px;
    font-weight: bold;
    color: $white;
    -webkit-text-shadow: 0 1px 0 rgba(255, 255, 255, 1);
    text-shadow: 0 1px 0 rgba(255, 255, 255, 1);
    @include opacity(0.8);

    &:hover,
    &:focus {
        color: $black;
        text-decoration: none;
        cursor: pointer;
        @include opacity(0.4);
    }
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
}

//#endregion

.toast-top-center {
    top: 0;
    right: 0;
    width: 100%;
}

.toast-bottom-center {
    bottom: 0;
    right: 0;
    width: 100%;
}

.toast-top-full-width {
    top: 0;
    right: 0;
    width: 100%;
}

.toast-bottom-full-width {
    bottom: 0;
    right: 0;
    width: 100%;
}

.toast-top-left {
    top: 12px;
    left: 12px;
}

.toast-top-right {
    top: 12px;
    right: 12px;
}

.toast-bottom-right {
    right: 12px;
    bottom: 12px;
}

.toast-bottom-left {
    bottom: 12px;
    left: 12px;
}

.toast-container {
    position: fixed;
    z-index: 999999;
    // The container should not be clickable.
    pointer-events: none;

    * {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    >div {
        position: relative;
        pointer-events: auto;
        overflow: hidden;
        margin: 0 0 6px;
        padding: 15px 15px 15px 50px;
        width: 300px;
        @include borderRadius(3px 3px 3px 3px);
        background-position: 15px center;
        background-repeat: no-repeat;
        @include boxShadow(0 0 12px rgba(0,0,0,0.1));
        color: $white;
        @include opacity($default-container-opacity);
    }

    > :hover {
        @include boxShadow(0 0 12px rgba(0,0,0,0.1));
        @include opacity(1);
        cursor: pointer;
    }

    >.toast-info {
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=") !important;
    }

    >.toast-error {
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=") !important;
    }

    >.toast-success {
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==") !important;
    }

    >.toast-warning {
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=") !important;
    }

    /*overrides*/
    &.toast-top-center>div,
    &.toast-bottom-center>div {
        width: 300px;
        margin-left: auto;
        margin-right: auto;
    }

    &.toast-top-full-width>div,
    &.toast-bottom-full-width>div {
        width: 96%;
        margin-left: auto;
        margin-right: auto;
    }
}

.toast {
    background-color: $near-black;
}

.toast-success {
    background-color: $green;
}

.toast-error {
    background-color: $red;
}

.toast-info {
    background-color: $blue;
}

.toast-warning {
    background-color: $orange;
}

.toast-progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    background-color: $black;
    @include opacity(0.4);
}

/*Responsive Design*/

@media all and (max-width: 240px) {
    .toast-container {

        >div {
            padding: 8px 8px 8px 50px;
            width: 11em;
        }

        & .toast-close-button {
            right: -0.2em;
            top: -0.2em;
        }
    }
}

@media all and (min-width: 241px) and (max-width: 480px) {
    .toast-container {
        >div {
            padding: 8px 8px 8px 50px;
            width: 18em;
        }

        & .toast-close-button {
            right: -0.2em;
            top: -0.2em;
        }
    }
}

@media all and (min-width: 481px) and (max-width: 768px) {
    .toast-container {
        >div {
            padding: 15px 15px 15px 50px;
            width: 25em;
        }
    }
}

</style>
