import _ from "lodash";
import request from "@/utils/network";
import moment from "moment";

const groups = [
  { label: "전체", value: "" },
  { label: "PAID", value: "paid" },
  { label: "COMPLETED", value: "completed" },
  { label: "FAIL", value: "fail" },
  { label: "CANCELLED", value: "cancelled" },
];

const fields = [
  { key: "uid", type: "text", label: "#", listing: true, hidden: true },
  {
    key: "user_name",
    type: "text",
    label: "결제자(유저아이디)",
    listing: true,
    hidden: true,
    editable: false,
  },
  {
    key: "user_id",
    type: "user-select",
    label: "결제자(유저아이디)",
    listing: false,
    editable: false,
  },
  {
    key: "type",
    type: "text",
    label: "Type(결제카드, 카드번호 4자리)",
    listing: true,
    hidden: true,
    editable: false,
    alias: (field, row) => {
      if (row.type === 'KAKAOPAY'){
        if (row.data.payment_method_type === 'MONEY'){
          return `KAKAOPAY(카카오머니)`;
        } else {
          return `KAKAOPAY(${!row.data.card_info ? '' : row.data.card_info.kakaopay_purchase_corp})`;
        }
      }
      if (row.status === "FAIL" || row.status === "COMPLETED") {
        if (row.extras.billing) {
          return `${row.extras.billing.billing_gateway}(${
            row.extras.billing.card_name
          }, ${row.extras.billing.card_number.slice(-4) || ""})`;
        }
      } else {
        if (Object.keys(row.data).length > 0 && row["data"]["cardNo"]) {
          return `${row.type}(${
            row["data"]["cardco"] || row["data"]["CardName"]
          }, ${row["data"]["cardno4"] || row["data"]["CardNo"].slice(-4)})`;
        }

        return '';
      }
    },
  },
  {
    key: "paid_total",
    type: "text",
    label: "결제금액",
    listing: true,
    hidden: true,
  },
  {
    key: "transaction_name",
    type: "text",
    label: "Transaction Name(Target ID)",
    listing: true,
    hidden: true,
    description: "",
  },
  {
    key: "transaction_uid",
    type: "text",
    label: "Transaction UID",
    hidden: true,
    listing: true,
  },
  { key: "ref_id", type: "text", label: "Ref ID", listing: true, hidden: true },
  { key: "status", type: "text", label: "Status", listing: true, hidden: true, alias: (field, row) => {
    if (row.status === 'PAID') {
      if (row.extras.CancelAmt) {
        return row.status + " (부분취소)";
      }
    }
    return row.status;
  }, },
  {
    key: "etc",
    type: "text",
    label: "비고",
    listing: true,
    hidden: true,
    alias: (field, row) => {
      if (row.transaction_name === "extra-transaction") {
        return row.extras.reason;
      } else if (row.status === "FAIL" && row.extras.fail_reason) {
        let fail_reason = row.extras.fail_reason;

        if (row.type === "NICEPAY") {
          return fail_reason.ResultMsg;
        }

        if (row.type === "EXIMBAY")
          return `${fail_reason.resmsg}(${fail_reason.rescode})`;
      } else {
        return "";
      }
    },
  },
  {
    key: "created_at",
    type: "text",
    label: "결제일",
    format: "YYYY.MM.DD HH:mm:ss",
    listing: true,
    readonly: true,
    alias: (field, row) => {
      let value = row[field.key];
      try {
        value = moment(value).format("YYYY-MM-DD HH:mm");
      } catch (e) {}
      return value || "";
    },
  },
  {
    key: "action",
    type: "action",
    label: "Actions",
    listing: true,
    actions: [
      "cancel",
      {
        action: "complete",
        class: "btn-success",
        label: "조치",
        onAction: (
          row,
          { $router, $store, model, $dialog, $toast },
          loadData
        ) => {
          let res;

          $dialog
            .confirm(`${row.uid} 트랜잭션을 조치 완료 처리하시겠습니까?`, {
              okText: "Ok",
              cancelText: "Cancel",
            })
            .then(async (dialog) => {
              res = await $store.dispatch(model.types.complete.action, row);

              await loadData();
            })
            .catch((e) => {
              $toast.error(e.message);
            });
        },
      },
      // {
      //   action: 'complete',
      //   class: 'btn-success',
      //   label: '재결제',
      //   onAction: (row, {$router, $store, model, $dialog, $toast}, loadData) => {
      //     let res;

      //     $dialog
      //     .confirm(`${row.uid} 재결제하시겠습니까?`, {
      //       okText: 'Ok',
      //       cancelText: 'Cancel',
      //     })
      //     .then( async (dialog) => {
      //       res = await $store.dispatch(model.types.repayment.action, row)
      //       await loadData();
      //     })
      //     .catch( async (e) => {
      //       $toast.error(e.message)
      //       await loadData();
      //     })

      //   },
      // },
    ],
  },
  { key: "reason", type: "textarea", label: "결제사유", listing: false },
  { key: "store_id", type: "store-select", label: "상점", listing: false },
  { key: "amount", type: "text", label: "결제금액 (원)", listing: false },
];

const modelName = "Transaction";

const modelData = {
  name: modelName,
  namespace: modelName.toLowerCase(),
  icon: "money-check-alt",
};

const actions = {
  list: ["create"],
  form: ["submit", "toList"],
};

const model = {
  name: modelData.name,
  fields: fields,
  actions: actions,
  groups: groups,
  getters: {
    items: (component) =>
      component.$store.getters[`${modelData.namespace}/GET_ITEMS`],
    total: (component) =>
      component.$store.getters[`${modelData.namespace}/GET_TOTAL`],
  },
  types: {
    list: {
      action: `${modelData.namespace}/list`,
      method: "get",
      endpoint: () => "/v2/admin/transactions",
    },
    cancel: {
      action: `${modelData.namespace}/cancel`,
      method: "put",
      endpoint: (options) =>
        `/v2/admin/transactions/${options.uid || options.id}/cancel`,
      message: (options) => `${options.uid} 트랜젝션을 취소하시겠습니까?`,
    },
    create: {
      action: `${modelData.namespace}/create`,
      method: "post",
      endpoint: () => `/v2/admin/transactions`,
    },
    complete: {
      action: `${modelData.namespace}/complete`,
      method: "post",
      endpoint: (options) =>
        `/v2/admin/transactions/${options.uid || options.id}/completed`,
    },
    repayment: {
      action: `${modelData.namespace}/repayment`,
      method: "post",
      endpoint: (options) =>
        `/v2/admin/transactions/${options.uid || options.id}/repayment`,
    },
  },
  views: {
    list: { name: `List${modelData.name}`, title: `${modelData.name} List` },
    create: {
      name: `Create${modelData.name}`,
      title: `Create ${modelData.name}`,
      params: (item) => {
        return { uid: item.uid };
      },
    },
    // edit: { name: `Edit${modelData.name}`, title: `Edit ${modelData.name}`, params: (item) => { return { uid: item.uid } } }
  },
};

export default {
  namespaced: true,
  model: model,
  routes: [
    {
      path: `/${modelData.namespace}`,
      component: () => import("@/views/layouts/Layout"),
      redirect: `/${modelData.namespace}/list`,
      name: `${modelData.name}`,
      meta: {
        title: `${modelData.name}`,
        icon: `${modelData.icon}`,
        admin_level: 2,
      },
      children: [
        {
          path: "list",
          component: () => import("@/views/basic/List"),
          name: model.views.list.name,
          meta: {
            mode: "list",
            title: model.views.list.title,
            model: model,
            noCache: true,
          },
        },
        {
          path: "create",
          component: () => import("@/views/basic/Form"),
          name: model.views.create.name,
          meta: {
            mode: "create",
            title: model.views.create.title,
            model: model,
            noCache: true,
          },
        },
      ],
    },
  ],
  state: {
    total: 0,
    items: [],
  },
  mutations: {
    LIST(state, { items, total }) {
      state.items = items;
      state.total = total;
    },
  },
  getters: {
    GET_ITEMS: (state) => {
      return state.items;
    },
    GET_TOTAL: (state) => {
      return state.total;
    },
  },
  actions: {
    list({ commit, rootGetters }, options) {
      return new Promise(async (resolve, reject) => {
        try {
          let response = await request[model.types.list.method](
            model.types.list.endpoint(options),
            { params: options }
          );
          let { error, data } = response.data;
          if (error) {
            return reject(new Error(error));
          }
          commit("LIST", data);
          resolve(data);
        } catch (e) {
          reject(e);
        }
      });
    },
    cancel({ commit, rootGetters, dispatch }, options) {
      return new Promise(async (resolve, reject) => {
        try {
          let { group } = options;
          let response = await request[model.types.cancel.method](
            model.types.cancel.endpoint(options),
            {}
          );
          let { error, data } = response.data;
          if (error) {
            return reject(new Error(error));
          }

          resolve(data);
        } catch (e) {
          reject(e);
        }
      });
    },
    create({ commit, rootGetters }, options) {
      return new Promise(async (resolve, reject) => {
        try {
          options.user_id = options.user_id.toString();

          let response = await request[model.types.create.method](
            model.types.create.endpoint(options),
            options
          );
          let { error, data } = response.data;
          if (error) {
            return reject(new Error(error));
          }
          resolve(data);
        } catch (e) {
          reject(e);
        }
      });
    },
    complete({ commit, rootGetters }, options) {
      return new Promise(async (resolve, reject) => {
        try {
          let response = await request[model.types.complete.method](
            model.types.complete.endpoint(options),
            options
          );
          let { error, data } = response.data;
          if (error) {
            return reject(new Error(error));
          }
          resolve(data);
        } catch (e) {
          reject(e);
        }
      });
    },
    repayment({ commit, rootGetters }, options) {
      return new Promise(async (resolve, reject) => {
        try {
          let response = await request[model.types.repayment.method](
            model.types.repayment.endpoint(options),
            options
          );
          let { error, data } = response.data;
          if (error) {
            return reject(new Error(error));
          }
          resolve(data);
        } catch (e) {
          reject(e);
        }
      });
    },
    // edit({commit, rootGetters}, options) {
    //   options["latitude"] = Number(options.latitude)
    //   options["longitude"] = Number(options.longitude)
    //   return new Promise( async (resolve, reject) => {
    //     try {
    //       let response = await request[model.types.edit.method](model.types.edit.endpoint(options), options)
    //       let { error, data } = response.data
    //       if (error) {
    //         return reject(new Error(error))
    //       }
    //       data["latitude"] = data.extras.latitude
    //       data["longitude"] = data.extras.longitude
    //       commit('EDIT', { item: data })
    //       resolve(data)
    //     }
    //     catch(e) {
    //       reject(e)
    //     }
    //   })
    // },
    // get({commit, rootGetters}, options) {
    //   return new Promise( async (resolve, reject) => {
    //     try {
    //       let response = await request[model.types.get.method](model.types.get.endpoint(options), { params: options })
    //       let { error, data } = response.data
    //       if (error) {
    //         return reject(new Error(error))
    //       }
    //       data["latitude"] = data.extras.latitude
    //       data["longitude"] = data.extras.longitude
    //       commit('GET', { item: data })
    //       resolve(data)
    //     }
    //     catch(e) {
    //       reject(e)
    //     }
    //   })
    // },
    // delete({commit, rootGetters}, options) {
    //   return new Promise( async (resolve, reject) => {
    //     try {
    //       let response = await request[model.types.delete.method](model.types.delete.endpoint(options), { params: options })
    //       let { error, data } = response.data
    //       if (error) {
    //         return reject(new Error(error))
    //       }
    //       resolve(data)
    //     }
    //     catch(e) {
    //       reject(e)
    //     }
    //   })
    // },
  },
};
