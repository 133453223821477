
const SET_LAYOUT_MODE = "SET_LAYOUT_MODE"
const SET_COLLAPSED = "SET_COLLAPSED"
const SET_EXPENDED_SIDEMENU_ITEM = "SET_EXPENDED_SIDEMENU_ITEM"

const LAYOUT_MODE = {
  UNSET: 'unset',
  DESKTOP: 'desktop',
  TABLET: 'tablet',
  MOBILE: 'mobile'
}

export default {
  namespaced: true,
  state: {
    layoutMode: LAYOUT_MODE.UNSET,
    isCollapsedSideBar: false,
    expendedSideMenuItem: null
  },
  mutations: {
    [SET_LAYOUT_MODE](state, { layoutMode }) {
      state.layoutMode = layoutMode
    },
    [SET_COLLAPSED](state, { isCollapsed }) {
      state.isCollapsedSideBar = isCollapsed
    },
    [SET_EXPENDED_SIDEMENU_ITEM](state, { item }) {
      state.expendedSideMenuItem = state.expendedSideMenuItem !== item ? item : null
    }
  },
  actions: {
    resizeLayout({ commit, state }, options) {
      let width = document.body.clientWidth || 0
      let layoutMode = LAYOUT_MODE.DESKTOP

      if (width <= 768) {
        layoutMode = LAYOUT_MODE.MOBILE
      }
      else if (width <= 1024) {
        layoutMode = LAYOUT_MODE.TABLET
      }

      if (state.layoutMode !== layoutMode) {
        commit(SET_LAYOUT_MODE, {
          layoutMode: layoutMode
        })
      }
    },
    toggleSideBar({ commit, state }, options) {
      commit(SET_COLLAPSED, {
        isCollapsed: !state.isCollapsedSideBar
      })
    },
    closeSideBar({ commit }, options) {
      commit(SET_COLLAPSED, {
        isCollapsed: true
      })
    },
    toggleSideMenuItem({ commit }, options) {
      commit(SET_EXPENDED_SIDEMENU_ITEM, options)
    }
  }
}
