
import request from '@/utils/network'
import moment from 'moment-timezone'
import config from "@/config";
import countryCodes from "@/assets/json/country-codes.json";

let _code = countryCodes.map((o) => {
  return { name: o.name, code: o.code };
});
_code.sort((a, b) => {
  return a.code.toUpperCase() < b.code.toUpperCase() ? -1 : a.code.toUpperCase() > b.code.toUpperCase() ? 1 : 0;
});
_code.forEach(o => {
  if(o.name === 'Korea, Republic of'){
    _code.splice(_code.indexOf(o), 1)
    _code.unshift(o);
  }
  if(o.name === 'Others'){
    _code.splice(_code.indexOf(o), 1)
    _code.push(o);
  }
})

let _dial_code = countryCodes.map((o) => {
  return { name: o.name, code: o.dial_code };
});
_dial_code.sort((a, b) => {
  return a.code < b.code ? -1 : a.code > b.code ? 1 : 0;
});
_dial_code.forEach(o => {
  if(o.name === 'Korea, Republic of'){
    _dial_code.splice(_dial_code.indexOf(o), 1)
    _dial_code.unshift(o);
  }
  if(o.name === 'Others'){
    _dial_code.splice(_dial_code.indexOf(o), 1)
    _dial_code.push(o);
  }
})

const fields = [
  { key: 'uid', type: 'uid', label: '#', listing: true, primaryKey: true },
  { key: 'account', type: 'text', label: 'Account', listing: true },
  { key: 'user_email', type: 'text', label: 'User Email', listing: true,},
  { 
    key: 'user_iso_code',
    type: 'select',
    label: 'User Iso Code',
    options: _code.map((o) => {
      return { label: o.code + ' (' + o.name + ')', value: o.code };
    })
  },
  { 
    key: 'user_dial_code',
    type: 'select',
    label: 'User Dial Code',
    options: _dial_code.map((o) => {
      return { label: o.code + ' (' + o.name + ')', value: o.code };
    })
  },
  { key: 'phone', type: 'text', label: 'User Phone', listing: true,},
  { key: 'user_name', type: 'text', label: 'User Name', listing: true },
  { key: 'user_first_name', type: 'text', label: 'User First Name' },
  { key: 'user_last_name', type: 'text', label: 'User Last Name' },
  { key: 'user_gender', type: 'radio', label: 'User Gender', options: [
    { label: "Male", value: "male" },
    { label: "Femail", value: "femail" },
    { label: "Other", value: "other" }
  ], hidden: true},
  { key: 'tester', type: 'radio', label: 'Tester', options: [
    { label: '테스터', value: true },
    { label: '일반', value: false },
  ]},
  { key: 'blocked', type: 'checkbox', label: 'Blocked' , hidden: true},
  { key: 'password', type: 'text', label: 'Password', editable: false},
  { key: 'updated_at', type: 'text', label: 'Updated At', hidden: true },
  { key: 'created_at', type: 'text', label: 'Timestamp', listing: true, readonly: true, alias: (field , row) => { return moment(row.created_at).format('YYYY-MM-DD HH:mm:ss')}},
  { key: 'updated_at', type: 'text', label: 'Updated At', readonly: true },
  { key: 'action', type: 'action', label: 'Actions', listing: true, actions: [
    "edit", "delete",
    {
      action: "open",
      class: "btn-warning",
      label: "Open",
      onAction: (row, { $router, $store, model }) => {

        $store.dispatch(model.types.token.action, row)
          .then((data) => {
            let { token } = data
            let url = `${config.lugstaySiteUrl}/login?token=${token}`
            window.open(url)
          })
          .catch(e => {

          })

      }
    }
  ], hidden: true }
]

const modelName = "User"

const modelData = {
  name: modelName,
  namespace: modelName.toLowerCase(),
  icon: 'user-tie'
}

const actions = {
  list: ['create'],
  form: ['submit', 'toList'],
}

// @TODO user 안의 값을 다 가져오기.

const model = {
  name: modelData.name,
  fields: fields,
  actions: actions,
  types: {
    create: {
      action: `${modelData.namespace}/create`,
      method: 'post',
      endpoint: () => '/v2/admin/users'
    },
    list: {
      action: `${modelData.namespace}/list`,
      method: 'get',
      endpoint: () => '/v2/admin/users'
    },
    token: {
      action: `${modelData.namespace}/token`,
      method: 'post',
      endpoint: (options) => `/v2/admin/users/${options.uid}/token`
    },
    edit: {
      action: `${modelData.namespace}/edit`,
      method: 'put',
      endpoint: (options) => `/v2/admin/users/${options.uid}`
    },
    get: {
      action: `${modelData.namespace}/get`,
      method: 'get',
      endpoint: (options) => `/v2/admin/users/${options.uid}`
    },
    delete: {
      action: `${modelData.namespace}/delete`,
      method: 'delete',
      endpoint: (options) => `/v2/admin/users/${options.uid}`
    },
    export: {
      action: `${modelData.namespace}/export`,
      method: 'get',
      endpoint: (options) => `/v2/admin/users/export`,
      fields: {
        '#': 'uid',
        'complete_tickets': 'complete_tickets',
        'expired_tickets': 'expired_tickets',
        'cancelled_tickets': 'cancelled_tickets',
        'all_tickets': 'all_tickets',
        'Account': 'account',
        'User Email': 'user_email',
        'User Name': 'user_name',
        'Phone Number': 'user_phone',
        'First Name': 'user_first_name',
        'Last Name': 'user_last_name',
        'Nickname': 'user_nickname',
        'Birth': 'user_birth',
        'Gender': 'user_gender',
        'Dial Code': 'user_dial_code',
        'ISO Code': 'user_iso_code',
        'Role Type': 'role_type',
        'Role Level': 'role_level',
        // 'profile_bio': 'profile_bio',
        // 'geo_latitude': 'geo_latitude',
        // 'geo_longitude': 'geo_longitude',
        'Timezone': 'timezone',
        'Blocked': 'blocked',
        'Blocked At': {
          field: 'blocked_at',
          callback: (value) => {
            if(!value) return value
            return moment(value).tz("Asia/Seoul").format("YYYY-MM-DD HH:mm:ss")
          }
        },
        'Agreed': 'agreed',
        'Agreed At': {
          field: 'agreed_at',
          callback: (value) => {
            if(!value) return value
            return moment(value).tz("Asia/Seoul").format("YYYY-MM-DD HH:mm:ss")
          }
        },
        'Extras': {
          field: 'extras',
          callback: (value) => {
            return JSON.stringify(value)
          }
        },
        'created At': {
          field: 'created_at',
          callback: (value) => {
            return moment(value).tz("Asia/Seoul").format("YYYY-MM-DD HH:mm:ss")
          }
        },
        'updated At': {
          field: 'updated_at',
          callback: (value) => {
            return moment(value).tz("Asia/Seoul").format("YYYY-MM-DD HH:mm:ss")
          }
        }
      }
    },
    resetPw: {
      action: `${modelData.namespace}/resetPw`,
      method: 'put',
      endpoint: (options) => `/v2/admin/users/${options.uid}/password`
    },
  },
  views: {
    list: { name: `List${modelData.name}`, title: `${modelData.name} List` },
    create: { name: `Create${modelData.name}`, title: `Create ${modelData.name}`, params: (item) => { return { uid: item.uid } } },
    edit: { name: `Edit${modelData.name}`, title: `Edit ${modelData.name}`, params: (item) => { return { uid: item.uid } } }
  }
}

export default {
  namespaced: true,
  model: model,
  routes: [
    {
      path: `/${modelData.namespace}`,
      component: () => import('@/views/layouts/Layout'),
      redirect: `/${modelData.namespace}/list`,
      name: `${modelData.name}`,
      meta: { title: `${modelData.name}`, icon: `${modelData.icon}`, admin_level: 1 },
      children: [
        {
          path: 'create',
          component: () => import('@/views/basic/Form'),
          name: model.views.create.name,
          meta: { mode: 'create', title: model.views.create.title, model: model, noCache: true },
          // hidden: true
        },
        {
          path: 'edit/:uid(\\d+)',
          component: () => import('@/views/basic/Form'),
          name: model.views.edit.name,
          meta: { mode: 'edit', title: model.views.edit.title, model: model, noCache: true },
          hidden: true
        },
        {
          path: 'list',
          component: () => import('@/views/basic/List'),
          name: model.views.list.name,
          meta: { mode: 'list', title: model.views.list.title, model: model, noCache: true }
        }
      ]
    }
  ],
  state: {
    cached: {},
    items: []
  },
  mutations: {
    LIST(state, { page, size, items }) {
      items.forEach(item => {
        state.cached[item.uid] = item
      })

      state.items = items
    },
    CREATE(state, { item }) {

    },
    EDIT(state, { item }) {

    },
    GET(state, { item }) {
      state.cached[item.uid] = item
    }
  },
  actions: {
    list({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let { group } = options
          let response = await request[model.types.list.method](model.types.list.endpoint(options), { params: options })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          commit('LIST', data)
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    create({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.create.method](model.types.create.endpoint(options), { email : options.account, ...options})
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          commit('CREATE', data)
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    edit({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.edit.method](model.types.edit.endpoint(options), options)
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          commit('EDIT', { item: data })
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    get({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.get.method](model.types.get.endpoint(options), { params: options })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          commit('GET', { item: data })
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    delete({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.delete.method](model.types.delete.endpoint(options), { params: options })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    token({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.token.method](model.types.token.endpoint(options), options)
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    export({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.export.method](model.types.export.endpoint(options), { params: options })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    resetPw({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.resetPw.method](model.types.resetPw.endpoint(options), options)
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    }
  }
}
