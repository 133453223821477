
const _ = require("lodash")
const uuid = require("uuid")
const objectPath = require("object-path")

class Utils {
  get isMobile() {
    return require('is-mobile')()
  }

  uuid() {
    return uuid().replace(/-/gi, "")
  }

  value( obj, path ) {
    try {
      return objectPath.get(obj, path)
    }
    catch(e) {}
    return null
  }

  parseJSON(jsonString, defaultValue = {}) {
    if (jsonString === 'null' || jsonString === null || typeof jsonString === 'undefined') {
      return defaultValue
    }
  
    var result = jsonString && _.isObject(jsonString) ? jsonString : defaultValue;
    if (_.isString(jsonString)) {
      try {
        result = JSON.parse(jsonString || "{}");
      } catch (e) {
        // console.error("parseJSON Error: ", e, jsonString);
        result = defaultValue;
      }
    }
    return result;
  }

  getLocale(json, language = 'base') {
    if (typeof json === 'string') {
      json = this.parseJSON(json)
    }
    
    let value = json[language] || json['base']

    if (!value) {
      let keys = _.keys(json)
      if (keys.length > 0) {
        return json[keys[0]]
      }
    }

    return value
  }

  numberFormat(num) {
    if (num) {
      var regexp = /\B(?=(\d{3})+(?!\d))/g;
      return num.toString().replace(regexp, ',')
    }
    return '0'
  }

  parsePopupOptions(data) {
    let popupOptions = []
    for ( var key in data ) {
      let value = data[key]
      if (typeof value === 'boolean') {
        let flag = value === 'true' ? 'yes' : 'no'
        popupOptions.push(`${key}=${flag}`)
      }
      else {
        popupOptions.push(`${key}=${value}`)
      }
    }
    return popupOptions.join(',')
  }
}

export default new Utils()
