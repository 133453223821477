
import app from "@/store/models/app"
import staff from "@/store/models/staff"
import template from "@/store/models/content.template"
import post from "@/store/models/content.post"
import page from "@/store/models/content.page"
import review from "@/store/models/content.review"
import user from "@/store/models/user"
import resource from "@/store/models/resource"
import store from "@/store/models/manager.store"
import host from "@/store/models/manager.host"
import agent from "@/store/models/agent"
import holiday from "@/store/models/manager.holiday"
import receipt from "@/store/models/luggage.receipt"
import tickets from "@/store/models/tickets"
import mail from "@/store/models/lugstay.mail"
import invite from "@/store/models/campaign.invite"
import prepaidcard from "@/store/models/prepaid.card"
import promotion from "@/store/models/promotion"
import coupon from "@/store/models/promotion.coupon"
import settlement from '@/store/models/settlement'
import payment from '@/store/models/payment'
import keyword from '@/store/models/keyword'
import transaction from '@/store/models/transaction'
import translation from '@/store/models/translation'
// import statistics from "@/store/models/statistics"


const routes = [
    {
        path: "/login",
        name: "Login",
        hidden: true,
        component: () => import("@/views/pages/Login")
    },
    {
        path: '/',
        redirect: '/dashboard',
        component: () => import('@/views/layouts/Layout'),
        name: 'Main',
        breadcrumb: false,
        exact: true,
        children: [
            {
                path: 'dashboard',
                component: () => import('@/views/pages/Dashboard'),
                name: 'Dashboard',
                meta: { title: 'Dashboard', icon: 'th-large', noCache: true }
            }
        ]
    },
    ...app.routes,
    ...staff.routes,
    ...agent.routes,
    ...host.routes,
    ...store.routes,
    ...receipt.routes,
    ...tickets.routes,
    ...settlement.routes,
    ...prepaidcard.routes,
    ...promotion.routes,
    ...coupon.routes,
    ...payment.routes,
    ...invite.routes,
    ...user.routes,
    ...mail.routes,
    ...template.routes,
    ...post.routes,
    ...page.routes,
    // ...review.routes,
    ...holiday.routes,
    ...keyword.routes,
    ...resource.routes,
    ...transaction.routes,
    ...translation.routes,
    // ...statistics.routes,
    {
        path: "*",
        name: "NotFound",
        hidden: true,
        component: () => import("@/views/pages/404")
    },
    {
        name: "RecommendedRegions",
        component: () => import('@/views/layouts/Layout'),
        path: "/regions",
        redirect: '/regions/list',
        meta: { title: 'Regions', icon: 'map', admin_level: 2 },
        children: [
            {
                path: 'list',
                component: () => import('@/views/pages/recommendedRegions/List'),
                name: 'RecommendedRegionsList',
                meta: { title: 'Regions List',  noCache: true }
            },
            {
                path: 'create',
                component: () => import('@/views/pages/recommendedRegions/Create'),
                name: 'CreateRecommendedRegion',
                meta: { title: 'Create Region',  noCache: true }
            }
        ]
    }
]

export default routes
