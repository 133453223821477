import _ from 'lodash'
import request from '@/utils/network'
import maillogs from "@/store/models/log.mailgun"

const fields = [
  { key: 'name', type: 'text', label: 'Mail Template Name', listing: true, editable: false },
  { key: 'description', type: 'text', label: 'Mail Template Description', listing: true },
  { key: 'template', type: 'editor', label: 'Mail Template Content' }
]

const modelName = "Mail"

const modelData = {
  name: modelName,
  namespace: modelName.toLowerCase(),
  icon: 'file-alt'
}

const actions = {
  list: ['create'],
  form: ['submit']
}

const model = {
  name: modelData.name,
  fields: fields,
  actions: actions,
  getters: {
    items: ( component ) => component.$store.getters[`${modelData.namespace}/GET_ITEMS`],
    total: ( component ) => component.$store.getters[`${modelData.namespace}/GET_TOTAL`],
  },
  types: {
    list: {
      action: `${modelData.namespace}/list`,
      method: 'get',
      endpoint: () => '/v2/admin/lugstay/templates'
    },
    create: {
      action: `${modelData.namespace}/create`,
      method: 'post',
      endpoint: () => `/v2/admin/lugstay/templates`
    },
    edit: {
      action: `${modelData.namespace}/edit`,
      method: 'put',
      endpoint: (options) => `/v2/admin/lugstay/templates/${options.name}`
    },
    get: {
      action: `${modelData.namespace}/get`,
      method: 'get',
      endpoint: (options) => `/v2/admin/lugstay/templates/${options.name}`
    },
    send: {
      method: 'post',
      endpoint: (options) => `/v2/admin/lugstay/templates/${options.name}/send`
    },
  },
  views: {
    list: { name: `List${modelData.name}`, title: `${modelData.name} List` },
    create: { name: `Create${modelData.name}`, title: `Create ${modelData.name}`, params: (item) => { return { name: item.name } } },
    edit: { name: `Edit${modelData.name}`, title: `Edit ${modelData.name}`, params: (item) => { return { name: item.name } } }
  }
}

export default {
  namespaced: true,
  model: model,
  routes: [
    {
      path: `/${modelData.namespace}`,
      component: () => import('@/views/layouts/Layout'),
      redirect: `/${modelData.namespace}/list`,
      name: `${modelData.name}`,
      meta: { title: `${modelData.name}`, icon: `${modelData.icon}`, admin_level: 2 },
      children: [

        {
          path: 'logs/view/:key',
          component: () => import('@/views/basic/Form'),
          name: maillogs.model.views.view.name,
          meta: { mode: "view", title: maillogs.model.views.view.title, model: maillogs.model, noCache: true },
          hidden: true
        },
        {
          path: 'logs/sync',
          component: () => import('@/views/pages/logs/Sync'),
          name: maillogs.model.views.sync.name,
          meta: { mode: "sync", title: maillogs.model.views.sync.title, model: maillogs.model, noCache: true }
        },
        {
          path: 'logs/list',
          component: () => import('@/views/basic/List'),
          name: maillogs.model.views.list.name,
          meta: { mode: "list", title: maillogs.model.views.list.title, model: maillogs.model, noCache: true }
        },

        {
          path: 'publish',
          component: () => import('@/views/pages/template/Publish'),
          name: 'Template Publish',
          meta: { mode: "publish", title: 'Publish Template', model: model, noCache: true }
        },
        {
          path: 'create',
          component: () => import('@/views/basic/Form'),
          name: model.views.create.name,
          meta: { mode: "create", title: model.views.create.title, model: model, noCache: true }
        },
        {
          path: 'edit/:name',
          component: () => import('@/views/basic/Form'),
          name: model.views.edit.name,
          meta: { mode: "edit", title: model.views.edit.title, model: model, noCache: true },
          hidden: true
        },
        {
          path: 'list',
          component: () => import('@/views/basic/List'),
          name: model.views.list.name,
          meta: { mode: "list", title: model.views.list.title, model: model, noCache: true }
        }
      ]
    }
  ],
  state: {
    total: 0,
    items: []
  },
  mutations: {
    LIST(state, { items, total }) {
      state.items = items
      state.total = total
    }
  },
  getters: {
    GET_ITEMS: ( state ) => {
      return state.items;
    },
    GET_TOTAL: ( state ) => {
      return state.total;
    }
  },
  actions: {
    list({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let { group } = options
          let response = await request[model.types.list.method](model.types.list.endpoint(options), { params: { post_group: group, ...options } })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          commit('LIST', data)
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    create({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.create.method](model.types.create.endpoint(options), options)
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    edit({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.edit.method](model.types.edit.endpoint(options), options)
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    get({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.get.method](model.types.get.endpoint(options), { params: options })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    send({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.send.method](model.types.send.endpoint(options), options)
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    }
  }
}
