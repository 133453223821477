import _ from 'lodash'
import request from '@/utils/network'

const groups = [
  { label: "전체", value: "" },
  { label: "공지사항", value: "notice" },
  { label: "호스트 공지사항", value: "host_notice"}
]

const fields = [
  { key: 'uid', type: 'uid', label: '#', listing: true, primaryKey: true },
  { key: 'post_type', type: 'text', label: 'Type', default: "story", editable: false, readonly: true, hidden: true },
  { key: 'post_group', type: 'select', label: 'Category', listing: true, hidden: true },
  { key: 'post_parent_id', type: 'text', label: 'Parent ID', readonly: true, hidden: true },
  { key: 'post_depth', type: 'text', label: 'Depth', readonly: true, hidden: true },
  { key: 'post_order', type: 'text', label: 'Order', readonly: true, hidden: true },
  { key: 'post_title', type: 'locale-editor', label: 'Title', listing: true, alias: (field, row) => {
    let value = row[field.key]
    try {
      let json = JSON.parse(value || '{}')
      let keys = _.keys(json)
      return (json['ko'] || json['base'] || value || '')
    }
    catch(e) {}
    return value || ''
  }, localeType: 'text' },
  { key: 'post_summary', type: 'locale-editor', label: 'Summary', listing: true, alias: (field, row) => {
    let value = row[field.key]
    try {
      let json = JSON.parse(value || '{}')
      let keys = _.keys(json)
      return (json['ko'] || json['base'] || value || '')
    }
    catch(e) {}
    return value || ''
  }, llocaleType: 'textarea' },
  { key: 'post_content_locales', type: 'text', label: 'Content Locales', listing: true, alias: (field, row) => {
    let value = row['post_content']
    try {
      let json = JSON.parse(value || '{}')
      let keys = _.keys(json)
      return `{${keys.join(',')}}`
    }
    catch(e) {}
    return '{}'
  }, hidden: true},
  { key: 'post_content', type: 'locale-editor', label: 'Content', localeType: 'editor' },
  {
    key: 'post_group',
    type: 'select',
    label: 'Post Group',
    options: [
      { label: '호스트 공지사항', value: 'host_notice' },
      { label: '공지사항', value: 'notice' },
    ],
    defaultValue: 'notice',
  },
  { key: 'extras', type: 'extra-editor', label: 'Extras',
    fields: [

    ]
  },
  { key: 'resources', type: 'resource-editor', label: 'Resources',
    fields: [
      { name: "thumbnail", type: "single", label: 'Thumbnail Image', description: "" },
      { name: "uploaded", type: "list", label: 'Uploaded Image', description: "" }
    ]
  },
  { key: 'created_at', type: 'text', label: 'Created At', listing: true, readonly: true, hidden: true },
  { key: 'action', type: 'action', label: 'Actions', listing: true, actions: ["edit", "delete"], hidden: true }
]

const modelName = "Post"

const modelData = {
  name: modelName,
  namespace: modelName.toLowerCase(),
  icon: 'newspaper'
}


const actions = {
  list: ['create'],
  form: ['submit']
}

const model = {
  name: modelData.name,
  fields: fields,
  actions: actions,
  groups: groups,
  types: {
    list: {
      action: `${modelData.namespace}/list`,
      method: 'get',
      endpoint: () => '/v2/admin/content/posts/post'
    },
    create: {
      action: `${modelData.namespace}/create`,
      method: 'post',
      endpoint: (options) => `/v2/admin/content/posts/post`
    },
    edit: {
      action: `${modelData.namespace}/edit`,
      method: 'put',
      endpoint: (options) => `/v2/admin/content/posts/post/${options.uid}`
    },
    get: {
      action: `${modelData.namespace}/get`,
      method: 'get',
      endpoint: (options) => `/v2/admin/content/posts/post/${options.uid}`
    },
    delete: {
      action: `${modelData.namespace}/delete`,
      method: 'delete',
      endpoint: (options) => `/v2/admin/content/posts/post/${options.uid}`
    }
  },
  views: {
    list: { name: `List${modelData.name}`, title: `${modelData.name} List` },
    create: { name: `Create${modelData.name}`, title: `Create ${modelData.name}`, params: (item) => { return { uid: item.uid || item.post_id } } },
    edit: { name: `Edit${modelData.name}`, title: `Edit ${modelData.name}`, params: (item) => { return { uid: item.uid || item.post_id} } }
  }
}

export default {
  namespaced: true,
  model: model,
  routes: [
    {
      path: `/${modelData.namespace}`,
      component: () => import('@/views/layouts/Layout'),
      redirect: `/${modelData.namespace}/list`,
      name: `${modelData.name}`,
      meta: { title: `${modelData.name}`, icon: `${modelData.icon}`, admin_level: 2 },
      children: [
        {
          path: 'create',
          component: () => import('@/views/basic/Form'),
          name: model.views.create.name,
          meta: { mode: "create", title: model.views.create.title, model: model, noCache: true }
        },
        {
          path: 'edit/:uid(\\d+)',
          component: () => import('@/views/basic/Form'),
          name: model.views.edit.name,
          meta: { mode: "edit", title: model.views.edit.title, model: model, noCache: true },
          hidden: true
        },
        {
          path: 'list',
          component: () => import('@/views/basic/List'),
          name: model.views.list.name,
          meta: { mode: "list", title: model.views.list.title, model: model, noCache: true }
        }
      ]
    }
  ],
  state: {
    cached: {},
    items: []
  },
  mutations: {
    LIST(state, { page, size, items }) {
      items.forEach(item => {
        state.cached[item.uid] = item
      })

      state.items = items
    },
    CREATE(state, { item }) {

    },
    EDIT(state, { item }) {

    },
    GET(state, { item }) {
      state.cached[item.uid] = item
    }
  },
  actions: {
    list({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let { group } = options
          let response = await request[model.types.list.method](model.types.list.endpoint(options), { params: { post_group: group, ...options } })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          commit('LIST', data)
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    create({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.create.method](model.types.create.endpoint(options), options)
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          commit('CREATE', data)
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    edit({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.edit.method](model.types.edit.endpoint(options), options)
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          commit('EDIT', { item: data })
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    get({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.get.method](model.types.get.endpoint(options), { params: options })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          commit('GET', { item: data })
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    delete({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.delete.method](model.types.delete.endpoint(options))
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    }
  }
}
