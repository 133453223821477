import request from '@/utils/network';
import moment from 'moment'
import utils from '@/utils';

const tabs = [{ id: 'info', name: 'Info' }];

const status = [
	{ label: '발급', value: 'ISSUED' },
	{ label: '사용불가', value: 'BLOCKED' },
];

const fields = [
	{ key: 'uid', type: 'uid', label: '#', listing: true, primaryKey: true, tab: '*' },

	// { key: 'user_id', type: 'text', label: 'User ID' },

	{
		key: 'coupon_name',
		type: 'locale-editor',
		label: 'Coupon Name',
		listing: true,
		alias: (field, row) => {
			let value = row[field.key];
			try {
				let json = JSON.parse(value || '{}');
				let keys = _.keys(json);
				return json['ko'] || json['base'] || value || '';
			} catch (e) {}
			return value || '';
		},
		localeType: 'text',
	},
	{
		key: 'coupon_descript',
		type: 'locale-editor',
		label: 'Coupon Description',
    listing: true,
    class: 'ellipsis',
		alias: (field, row) => {
			let value = row[field.key];
			try {
				let json = JSON.parse(value || '{}');
				let keys = _.keys(json);
				return json['ko'] || json['base'] || value || '';
			} catch (e) {}
			return value || '';
		},
		localeType: 'editor',
	},

	{ key: 'coupon_code', type: 'text', label: 'Coupon Code', listing: true, readonly: true },
	{
		key: 'coupon_date_due',
    label: 'Coupon Date Due',
    type: 'text',
		listing: true,
		hidden: true,
		alias: ( field, row ) => {
			let coupon_date_due = ''

			if(row.coupon_date_due){
				coupon_date_due = moment(row.coupon_date_due).format('YYYY-MM-DD HH:mm:ss')	
			}else{
				coupon_date_due = '..'
			}

			return coupon_date_due
		}
	},
	{
		key: 'coupon_date_due',
    label: 'Coupon Date Due',
    type: 'datetime',
    format: 'YYYY.MM.DD HH:mm:ss',
		getCheckValue(component, data) {
			if (data && data['coupon_date_due_no_expiration'] === true) {
				return true;
			}
			return false;
		},
		onCheck(component, value, data) {
			component.$data.data['coupon_date_due_no_expiration'] = value;
			component.$data.timestamp = +new Date();
		},
	},

	{ key: 'user_id', type: 'user-select', label: 'User ID', listing: true },
	{ key: 'used', type: 'text', label: 'Used', listing: true, readonly: true },
	{ key: 'promotion_id', type: 'promotion-select', label: 'Promotion ID', listing: true, editable: false },
	{
		key: 'promotion_config',
		type: 'extra-editor',
		label: 'Promotion Config',
		readonly: true,
		fields: [
			{
				name: 'type',
				type: 'select',
				label: 'Discount Type',
				readonly: true,
				editable: false,
				options: [
					{ label: '고정', value: 'fix' },
					{ label: '비율', value: 'rate' },
				],
				defaultTable: 'fix',
				description: '',
			},
			{
				name: 'fix_amount',
				type: 'text',
				label: 'Fix Amount (KRW)',
				readonly: true,
				editable: false,
				description: '',
				disable: (data) => {
					if (data && data.type === 'fix') {
						return false;
					}
					return true;
				},
			},
			{
				name: 'rate_amount',
				type: 'text',
				label: 'Rate Amount (%)',
				readonly: true,
				editable: false,
				description: '',
				disable: (data) => {
					if (data && data.type === 'rate') {
						return false;
					}
					return true;
				},
			},
		],
	},

	{ key: 'created_at', type: 'field', dataType: 'datetime', label: 'Created At', format: 'YYYY-MM-DD HH:mm:ss', listing: true, readonly: true },
	{ key: 'updated_at', type: 'field', dataType: 'datetime', label: 'Updated At', format: 'YYYY-MM-DD HH:mm:ss', readonly: true },
	{ key: 'action', type: 'action', label: 'Actions', listing: true, actions: ['edit', 'delete'], hidden: true },
];

const modelName = 'Coupon';

const modelData = {
	name: modelName,
	namespace: modelName.toLowerCase(),
	icon: 'gem',
};

const actions = {
	list: ['create'],
	form: ['submit'],
};

const model = {
	name: modelData.name,
	fields: fields,
	actions: actions,
	tabs: tabs,
	types: {
		list: {
			action: `${modelData.namespace}/list`,
			method: 'get',
			endpoint: () => '/v2/admin/coupons',
		},
		create: {
			action: `${modelData.namespace}/create`,
			method: 'post',
			endpoint: () => `/v2/admin/coupons`,
		},
		generate: {
			action: `${modelData.namespace}/generate`,
			method: 'post',
			endpoint: () => `/v2/admin/coupons/generate`,
		},
		generateAll: {
			action: `${modelData.namespace}/generateAll`,
			method: 'post',
			endpoint: () => `/v2/admin/coupons/generate/all`,
		},
		edit: {
			action: `${modelData.namespace}/edit`,
			method: 'put',
			endpoint: (options) => `/v2/admin/coupons/${options.uid}`,
		},
		get: {
			action: `${modelData.namespace}/get`,
			method: 'get',
			endpoint: (options) => `/v2/admin/coupons/${options.uid}`,
		},
		delete: {
			action: `${modelData.namespace}/delete`,
			method: 'delete',
			endpoint: (options) => `/v2/admin/coupons/${options.uid}`,
		},
		export: {
			action: `${modelData.namespace}/export`,
			method: 'get',
			endpoint: (options) => `/v2/admin/coupons/export`,
			fields: {
				'*': 'uid',
				'쿠폰 코드': 'coupon_code',
				'유저 id': 'user_id',
				'유저 이름': {
					field: 'user_name',
				},
				'유저 이메일': 'user_email',
				'유저 전화번호': 'user_phone',
				'쿠폰 이름': 'coupon_name',
				'쿠폰 타입': {
					field: 'promotion_config',
					callback: (value) => {
						if(utils.parseJSON(value).type == 'rate'){
							return '비율'
						}else if(utils.parseJSON(value).type == 'fix'){
							return '고정'
						}
					}
				},
				'쿠폰 할인율(금액)': {
					field: 'promotion_config',
					callback: (value) => {
						if(utils.parseJSON(value).type == 'rate'){
							return utils.parseJSON(value).rate_amount + '%'
						}else if(utils.parseJSON(value).type == 'fix'){
							return utils.parseJSON(value).fix_amount + '₩'
						}
					}
				},
				'사용 여부': 'used',
				'쿠폰 사용시각': {
					field: 'coupon_used_at',
					callback: (value) => {
						if(value){
							return moment(value)
							.tz('Asia/Seoul')
							.format('YYYY-MM-DD HH:mm');
						}
					}
				},
				'쿠폰 만료시각': {
					field: 'coupon_date_due',
					callback: (value) => {
						return moment(value)
						.tz('Asia/Seoul')
						.format('YYYY-MM-DD HH:mm');
					}
				},
				'만료 여부': 'expired',
				'Timestamp': {
					field: 'created_at',
					callback: (value) => {
						return moment(value)
						.tz('Asia/Seoul')
						.format('YYYY-MM-DD HH:mm');
					}
				},
			}
		}
	},
	views: {
		list: { name: `List${modelData.name}`, title: `${modelData.name} List` },
		create: {
			name: `Create${modelData.name}`,
			title: `Create ${modelData.name}`,
			params: (item) => {
				return { uid: item.uid };
			},
		},
		generate: {
			name: `Generate${modelData.name}`,
			title: `Generate ${modelData.name}`,
			params: (item) => {
				return { uid: item.uid };
			},
		},
		edit: {
			name: `Edit${modelData.name}`,
			title: `Edit ${modelData.name}`,
			params: (item) => {
				return { uid: item.uid };
			},
		},
		schedule: {
			name: `${modelData.name}Schedule`,
			title: `${modelData.name} Schedule`,
			params: (item) => {
				return { uid: item.uid };
			},
		},
	},
};

export default {
	namespaced: true,
	model: model,
	routes: [
		{
			path: `/${modelData.namespace}`,
			component: () => import('@/views/layouts/Layout'),
			redirect: `/${modelData.namespace}/list`,
			name: `${modelData.name}`,
			meta: { title: `${modelData.name}`, icon: `${modelData.icon}`, admin_level: 2 },
			children: [
				{
					path: 'create',
					component: () => import('@/views/basic/Form'),
					name: model.views.create.name,
					meta: { mode: 'create', title: model.views.create.title, model: model, noCache: true },
				},
				{
					path: 'generate',
					component: () => import('@/views/pages/coupon/Generate'),
					name: model.views.generate.name,
					meta: { mode: 'generate', title: model.views.generate.title, model: model, noCache: true },
				},
				{
					path: 'edit/:uid(\\d+)',
					component: () => import('@/views/basic/Form'),
					name: model.views.edit.name,
					meta: { mode: 'edit', title: model.views.edit.title, model: model, noCache: true },
					hidden: true,
				},
				{
					path: 'list',
					component: () => import('@/views/basic/List'),
					name: model.views.list.name,
					meta: { mode: 'list', title: model.views.list.title, model: model, noCache: true, dateLimit: true },
				},
			],
		},
	],
	state: {
		cached: {},
		items: [],
	},
	mutations: {
		LIST(state, { page, size, items }) {
			items.forEach((item) => {
				state.cached[item.uid] = item;
			});

			state.items = items;
		},
		CREATE(state, { item }) {},
		EDIT(state, { item }) {},
		GET(state, { item }) {
			state.cached[item.uid] = item;
		},
	},
	actions: {
		list({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let { group } = options;
					let response = await request[model.types.list.method](model.types.list.endpoint(options), { params: options });
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					commit('LIST', data);
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		create({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.create.method](model.types.create.endpoint(options), options);
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					commit('CREATE', data);
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		edit({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.edit.method](model.types.edit.endpoint(options), options);
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					commit('EDIT', { item: data });
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		get({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.get.method](model.types.get.endpoint(options), { params: options });
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					commit('GET', { item: data });
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		delete({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.delete.method](model.types.delete.endpoint(options), { params: options });
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		generate({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.generate.method](model.types.generate.endpoint(options), options);
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		generateAll({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.generateAll.method](model.types.generateAll.endpoint(options), options);
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		export({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.export.method](model.types.export.endpoint(options), { params: options });
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
	},
};
