import request from '@/utils/network'
import utils from '@/utils'

const tabs = [
  { id: "info", name: "Info" }
]

const fields = [
  { key: 'uid', type: 'uid', label: '#', listing: true, primaryKey: true, tab: "*", },

  { key: 'locale', type: 'select', label: 'Locale', listing: true, options: [
    { label: "KR", value: "KR" }
  ] },
  { key: 'year', type: 'text', label: 'Year', listing: true },
  { key: 'date', type: 'date', label: 'Date', listing: true, format: "YYYY-MM-DD" },

  // { key: 'updated_at', type: 'datetime', label: 'Timestamp', format:'YYYY.MM.DD HH:mm:ss', listing: true, hidden: true },
  { key: 'created_at', type: 'field', dataType: 'datetime', label: 'Created At', format:'YYYY.MM.DD HH:mm:ss', readonly: true },
  { key: 'updated_at', type: 'field', dataType: 'datetime', label: 'Updated At', format:'YYYY.MM.DD HH:mm:ss', readonly: true },
  { key: 'action', type: 'action', label: 'Actions', listing: true, actions: [ "edit", "delete" ], hidden: true }
]

const modelName = "Holiday"

const modelData = {
  name: modelName,
  namespace: modelName.toLowerCase(),
  icon: 'candy-cane'
}

const actions = {
  list: ['create'],
  form: ['submit']
}

const model = {
  name: modelData.name,
  fields: fields,
  actions: actions,
  tabs: tabs,
  types: {
    list: {
      action: `${modelData.namespace}/list`,
      method: 'get',
      endpoint: () => '/v2/admin/holiday'
    },
    create: {
      action: `${modelData.namespace}/create`,
      method: 'post',
      endpoint: () => `/v2/admin/holiday`
    },
    edit: {
      action: `${modelData.namespace}/edit`,
      method: 'put',
      endpoint: (options) => `/v2/admin/holiday/${options.uid}`
    },
    delete: {
      action: `${modelData.namespace}/delete`,
      method: 'delete',
      endpoint: (options) => `/v2/admin/holiday/${options.uid}`
    },
    get: {
      action: `${modelData.namespace}/get`,
      method: 'get',
      endpoint: (options) => `/v2/admin/holiday/${options.uid}`
    }
  },
  views: {
    list: { name: `List${modelData.name}`, title: `${modelData.name} List` },
    create: { name: `Create${modelData.name}`, title: `Create ${modelData.name}`, params: (item) => { return { uid: item.uid } } },
    edit: { name: `Edit${modelData.name}`, title: `Edit ${modelData.name}`, params: (item) => { return { uid: item.uid } } }
  }
}

export default {
  namespaced: true,
  model: model,
  routes: [
    {
      path: `/${modelData.namespace}`,
      component: () => import('@/views/layouts/Layout'),
      redirect: `/${modelData.namespace}/list`,
      name: `${modelData.name}`,
      meta: { title: `${modelData.name}`, icon: `${modelData.icon}`, admin_level: 2 },
      children: [
        {
          path: 'create',
          component: () => import('@/views/basic/Form'),
          name: model.views.create.name,
          meta: { mode: 'create', title: model.views.create.title, model: model, noCache: true }
        },
        {
          path: 'edit/:uid(\\d+)',
          component: () => import('@/views/basic/Form'),
          name: model.views.edit.name,
          meta: { mode: 'edit', title: model.views.edit.title, model: model, noCache: true },
          hidden: true
        },
        {
          path: 'list',
          component: () => import('@/views/basic/List'),
          name: model.views.list.name,
          meta: { mode: 'list', title: model.views.list.title, model: model, noCache: true }
        }
      ]
    }
  ],
  state: {

  },
  mutations: {

  },
  actions: {
    list({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let { group } = options
          let response = await request[model.types.list.method](model.types.list.endpoint(options), { params: options })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    create({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.create.method](model.types.create.endpoint(options), options)
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    edit({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.edit.method](model.types.edit.endpoint(options), options)
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    delete({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.delete.method](model.types.delete.endpoint(options), options)
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    get({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.get.method](model.types.get.endpoint(options), { params: options })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    }
  }
}
