import _ from 'lodash'
import request from '@/utils/network'

const groups = [
  { label: "전체", value: "" },
  { label: "약관", value: "terms" },
  { label: "이벤트", value: "event" }
]

const languages = [
  { label: "영어(base)", value: "base" },
  { label: "한국어", value: "ko" }
]

const fields = [
  { key: 'uid', type: 'uid', label: '#', listing: true, primaryKey: true },
  { key: 'content_type', type: 'text', label: 'Type', hidden: true },
  { key: 'content_group', type: 'select', label: 'Group', listing: true,
    options: _.filter(groups, (group, index) => index > 0),
    locale: (value) => {
      let items = groups.filter(item => item.value === value)
      return items.length > 0 ? items[0].label : value
    }
  },
  { key: 'content_key', type: 'text', label: 'Key', listing: true },
  { key: 'content_language', type: 'select', label: 'Language', listing: true,
    options: languages, defaultValue: 'base',
    locale: (value) => {
      let items = languages.filter(item => item.value === value)
      return items.length > 0 ? items[0].label : value
    }
  },
  { key: 'content_title', type: 'text', label: 'Title', listing: true },
  { key: 'content_html', type: 'editor', label: 'Content' },
  { key: 'extras', type: 'extra-editor', label: 'Extras', fields: [] },
  { key: 'resources', type: 'resource-editor', label: 'Resources', fields: [] },
  { key: 'created_at', type: 'text', label: 'Created At', listing: true, readonly: true, hidden: true },
  { key: 'action', type: 'action', label: 'Actions', listing: true, actions: ["edit"], hidden: true }
]

const modelName = "Page"

const modelData = {
  name: modelName,
  namespace: modelName.toLowerCase(),
  icon: 'sticky-note'
}

const model = {
  name: modelData.name,
  fields: fields,
  groups: groups,
  types: {
    list: {
      action: `${modelData.namespace}/list`,
      method: 'get',
      endpoint: () => '/v2/admin/content/contents/page'
    },
    create: {
      action: `${modelData.namespace}/create`,
      method: 'post',
      endpoint: () => `/v2/admin/content/contents/page`
    },
    edit: {
      action: `${modelData.namespace}/edit`,
      method: 'put',
      endpoint: (options) => `/v2/admin/content/contents/page/${options.uid}`
    },
    get: {
      action: `${modelData.namespace}/get`,
      method: 'get',
      endpoint: (options) => `/v2/admin/content/contents/page/${options.uid}`
    }
  },
  views: {
    list: { name: `List${modelData.name}`, title: `${modelData.name} List` },
    create: { name: `Create${modelData.name}`, title: `Create ${modelData.name}`, params: (item) => { return { uid: item.uid } } },
    edit: { name: `Edit${modelData.name}`, title: `Edit ${modelData.name}`, params: (item) => { return { uid: item.uid } } }
  }
}

export default {
  namespaced: true,
  model: model,
  routes: [
    {
      path: `/${modelData.namespace}`,
      component: () => import('@/views/layouts/Layout'),
      redirect: `/${modelData.namespace}/list`,
      name: `${modelData.name}`,
      meta: { title: `${modelData.name}`, icon: `${modelData.icon}`, admin_level: 2 },
      children: [
        {
          path: 'create',
          component: () => import('@/views/basic/Form'),
          name: model.views.create.name,
          meta: { mode: "create", title: model.views.create.title, model: model, noCache: true }
        },
        {
          path: 'edit/:uid(\\d+)',
          component: () => import('@/views/basic/Form'),
          name: model.views.edit.name,
          meta: { mode: "edit", title: model.views.edit.title, model: model, noCache: true },
          hidden: true
        },
        {
          path: 'list',
          component: () => import('@/views/basic/List'),
          name: model.views.list.name,
          meta: { mode: "list", title: model.views.list.title, model: model, noCache: true }
        }
      ]
    }
  ],
  state: {
    cached: {},
    items: []
  },
  mutations: {
    LIST(state, { page, size, items }) {
      items.forEach(item => {
        state.cached[item.uid] = item
      })

      state.items = items
    },
    CREATE(state, { item }) {

    },
    EDIT(state, { item }) {

    },
    GET(state, { item }) {
      state.cached[item.uid] = item
    }
  },
  actions: {
    list({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let { group } = options
          let response = await request[model.types.list.method](model.types.list.endpoint(options), { params: { content_group: group, ...options } })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          commit('LIST', data)
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    create({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.create.method](model.types.create.endpoint(options), options)
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          commit('CREATE', data)
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    edit({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.edit.method](model.types.edit.endpoint(options), options)
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          commit('EDIT', { item: data })
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    get({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.get.method](model.types.get.endpoint(options), { params: options })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          commit('GET', { item: data })
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    }
  }
}
