import Excel from './Excel.vue'

const plugin = {
  install (Vue, {loadComponent = true} = {}) {
    Vue.component('export-excel', Excel)
  }
}

typeof window.Vue === 'function' && window.Vue.use(plugin)

export default plugin
