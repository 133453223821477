<template>
  <div id="app" class="fixed-nav fixed-nav-basic" :class="{'mini-navbar': isCollapsedSideBar, 'animating': isAnimating}" ref='app'>
    <portal-target name="app-content-before"></portal-target>
    <router-view />
    <portal-target name="app-content-after"></portal-target>
    <resize-observer @notify="onResize" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isInitialized: false,
      isAnimating: false
    }
  },
  created() {
    this.onAppInit()
  },
  mounted() {
    this.$store.dispatch('layout/resizeLayout', {})
    this.updateLayout( this.isCollapsedSideBar )
  },
  watch: {
    isCollapsedSideBar(value, oldValue) {
      this.updateLayout(value)
    }
  },
  computed: {
    isCollapsedSideBar() {
      return this.$store.getters.isCollapsedSideBar
    },
    isMobile() {
      return this.$store.getters.layoutMode === 'mobile'
    }
  },
  methods: {
    onAppInit() {
      if (localStorage.accessToken) {
        this.$store.dispatch('session/verify', {})
          .then((res) => {
            console.log( "res", res );
            this.$data.isInitialized = true
          })
          .catch(e => {
            this.$store.dispatch('session/logout', {})
            this.$data.isInitialized = true
            this.$router.replace({ name: 'Login' })
          })
      }
      else {
        this.$data.isInitialized = true
      }
    },
    onResize(e) {
      this.$store.dispatch('layout/resizeLayout', {})
    },
    updateLayout( isCollapsed ) {
      this.$data.isAnimating = true

      let item = this.$store.getters.expendedSideMenuItem
      this.$store.dispatch('layout/toggleSideMenuItem', { item: null })

      setTimeout(() => {
        this.$data.isAnimating = false

        if (item) {
          this.$store.dispatch('layout/toggleSideMenuItem', { item: item })
        }
      }, 250)
    }
  }
}
</script>
