class Validator {

  validate(options = {}) {
    let {fields, data} = options, result = ''

    if (Array.isArray(fields) && fields.length > 0) {
      fields.forEach(field => {
        if (field.validation && field.validation.length > 0) {
          field.validation.forEach(validationItem => {
            switch (validationItem) {
              case 'number':
                const regExp = /^-?[0-9]+$|^$/;
                const value = field.validateTarget ? field.validateTarget(data) : data[field.key]

                if(!value) return '' // null 객체면 validate 하지 말기

                if (!regExp.test(value)) {
                  result =  `${field.label || field.key || field.name}은 숫자만 입력가능합니다.`
                }
              break;
            }
          })
        }
      })
    }

    return result
  }
}

export default new Validator()
