import config from '@/config';
import axios from 'axios';
import compareVersions from 'compare-versions';
import { register } from 'register-service-worker';

const debug = console;

const VERSION_KEY = '__LUGSTAY_VERSION';
class ServiceWorker {
  loadManifest() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.webServer}/manifest.json?r=${Math.random()}`)
        .then((response) => {
          if (response.data['version']) {
            resolve(response.data);
          } else {
            const error = new Error('version is undefined');
            reject(error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  refreshVersion(reload) {
    debug.log('Content is unregistering.');

    if ('serviceWorker' in window.navigator) {
      window.navigator.serviceWorker.ready.then((registration) => {
        registration.unregister().then(() => {
          debug.log('Service worker has been unregistered.');
          if (reload === true) {
            window.location.reload();
          }
        });
      });

      if (reload === true) {
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    } else {
      if (reload === true) {
        window.location.reload();
      }
    }
  }

  registerServiceWorker() {
    if (process.env.NODE_ENV === 'production') {
      register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {
          debug.log('App is being served from cache by a service worker.\n' + 'For more details, visit https://goo.gl/AFskqB');
        },
        registered() {
          debug.log('Service worker has been registered.');
        },
        cached() {
          debug.log('Content has been cached for offline use.');
        },
        updatefound() {
          debug.log('New content is downloading.');
        },
        updated() {
          // debug.log('New content is available; please refresh.')
        },
        offline() {
          debug.log('No internet connection found. App is running in offline mode.');
        },
        error(error) {
          debug.error('Error during service worker registration:', error);
        },
      });
    }
  }

  initialize() {
    if (process.env.NODE_ENV !== 'production') {
      debug.log('service worker is disabled.');
      return;
    }

    debug.log('initialize');

    this.loadManifest()
      .then((data) => {
        const manifestVersion = data.version;
        const storeVersion = localStorage.getItem(VERSION_KEY);

        let versionString = '';
        versionString += 'Local Version: ' + config.version;
        versionString += ' / Manifest Version: ' + manifestVersion;
        versionString += ' @(' + storeVersion + ')';

        console.log(versionString);

        if (config.version !== manifestVersion) {
          if (storeVersion === manifestVersion) {
            debug.log('Detect new version. try to refresh this page');
            return this.refreshVersion(true);
          }

          debug.log('Detect new version. refreshing this page');
          localStorage.setItem(VERSION_KEY, manifestVersion);
          return this.refreshVersion(true);
        }

        if (!storeVersion) {
          debug.log('First Version');
          localStorage.setItem(VERSION_KEY, manifestVersion);
        }

        this.startVersionCheck();

        return this.registerServiceWorker();
      })
      .catch((e) => {
        debug.log('error', e);
      });
  }

  startVersionCheck() {
    if (this._interval) {
      clearInterval(this._interval);
      this._interval = null;
    }

    this._interval = setInterval(() => {
      this.loadManifest().then((data) => {
        const minimumVersion = data['minimumVersion'];
        if (compareVersions(config.version, minimumVersion) < 0) {
          this.refreshVersion(true);
        }
      });
    }, 1000 * 60);
  }
}

const serviceWorker = new ServiceWorker();
serviceWorker.initialize();
export default serviceWorker;
