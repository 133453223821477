import request from '@/utils/network';
import utils from '@/utils';

const tabs = [{ id: 'info', name: 'Info' }];

const status = [
	{ label: '발급', value: 'ISSUED' },
	{ label: '사용불가', value: 'BLOCKED' },
];

const fields = [
	{ key: 'uid', type: 'uid', label: '#', listing: true, primaryKey: true, tab: '*' },

	// { key: 'user_id', type: 'text', label: 'User ID' },

	{ key: 'promotion_linked', type: 'text', label: 'Promotion Linked', listing: true, hidden: true },

	{ key: 'promotion_name', type: 'text', label: 'Promotion Name', listing: true },
	{ key: 'promotion_descript', type: 'textarea', label: 'Promotion Description', listing: true },
	{
		key: 'promotion_type',
		type: 'select',
		label: 'Promotion Type',
		listing: true,
		options: [
			{ label: '할인코드', value: 'discount-code' },
			{ label: '쿠폰', value: 'coupon' },
			{ label: '기기쿠폰', value: 'device-coupon' },
		],
		ediable: false,
	},

	{
		key: 'extras',
		type: 'extra-editor',
		label: 'Extras',
		fields: [
			{
				name: 'coupon_name',
				type: 'locale-editor',
				label: 'Coupon Name',
				alias: (field, row) => {
					let value = row[field.key];
					try {
						let json = JSON.parse(value || '{}');
						let keys = _.keys(json);
						return json['ko'] || json['base'] || value || '';
					} catch (e) {}
					return value || '';
				},
				localeType: 'text',
			},
			{
				name: 'coupon_descript',
				type: 'locale-editor',
				label: 'Coupon Description',
				alias: (field, row) => {
					let value = row[field.key];
					try {
						let json = JSON.parse(value || '{}');
						let keys = _.keys(json);
						return json['ko'] || json['base'] || value || '';
					} catch (e) {}
					return value || '';
				},
				localeType: 'editor',
			},

			{
				name: 'coupon_expiration_type',
				type: 'select',
				label: 'Coupon Expiration Type',
				description: '',
				options: [
					{ label: '프로모션 유효기간으로 설정', value: 'fixed' },
					{ label: '발급일로부터 유효기간 설정', value: 'days' },
				],
				defaultTable: 'fixed',
				description: '',
			},

			{ name: 'coupon_expiration_days', type: 'text', label: 'Coupon Expiration Days', description: '' },
		],
	},

	{ key: 'promotion_code', type: 'text', label: 'Promotion Code', listing: true, readonly: true },
	{
		key: 'promotion_date_due',
		type: 'promotion-datetime',
		label: 'Promotion Date Due',
		listing: true,
		getCheckValue(component, data) {
			if (data && data['promotion_date_due_no_expiration'] === true) {
				return true;
			}
			return false;
		},
		onCheck(component, value, data) {
			component.$data.data['promotion_date_due_no_expiration'] = value;
			component.$data.timestamp = +new Date();
		},
	},

	{
		key: 'promotion_config',
		type: 'extra-editor',
		label: 'Promotion Config',
		fields: [
			{
				name: 'type',
				type: 'select',
				label: 'Discount Type',
				options: [
					{ label: '고정', value: 'fix' },
					{ label: '비율', value: 'rate' },
				],
				defaultTable: 'fix',
				description: '',
			},
			{
				name: 'fix_amount',
				type: 'text',
				label: 'Fix Amount (KRW)',
				description: '',
				disable: (data) => {
					if (data && data.type === 'fix') {
						return false;
					}
					return true;
				},
			},
			{
				name: 'rate_amount',
				type: 'text',
				label: 'Rate Amount (%)',
				description: '',
				disable: (data) => {
					if (data && data.type === 'rate') {
						return false;
					}
					return true;
				},
			},
		],
	},

	// { key: 'promotion_limit_user_count', type: 'text', label: 'User Limit', description: '사용가능 유저수', listing: true, defaultValue: '0' },
	{ key: 'promotion_limit_used', type: 'text', label: 'Used Limit', listing: true, description: '발급 가능 횟수 / 0 인경우 무제한 발급', defaultValue: '0' },

	{ key: 'status', type: 'select', label: 'Status', listing: true, options: status, defaultValue: '' },

	{ key: 'updated_at', type: 'datetime', label: 'Timestamp', format: 'YYYY.MM.DD HH:mm:ss', listing: true, hidden: true },
	{
		key: 'created_at',
		type: 'field',
		dataType: 'datetime',
		label: 'Created At',
		format: 'YYYY.MM.DD HH:mm:ss',
		readonly: true,
	},
	{ key: 'updated_at', type: 'field', dataType: 'datetime', label: 'Updated At', format: 'YYYY.MM.DD HH:mm:ss', readonly: true },
	{ key: 'action', type: 'action', label: 'Actions', listing: true, actions: ['edit', 'delete'], hidden: true },
];

const modelName = 'Promotion';

const modelData = {
	name: modelName,
	namespace: modelName.toLowerCase(),
	icon: 'hat-cowboy',
};

const actions = {
	list: ['create'],
	form: ['submit'],
};

const model = {
	name: modelData.name,
	fields: fields,
	actions: actions,
	tabs: tabs,
	types: {
		list: {
			action: `${modelData.namespace}/list`,
			method: 'get',
			endpoint: () => '/v2/admin/promotions',
		},
		create: {
			action: `${modelData.namespace}/create`,
			method: 'post',
			endpoint: () => `/v2/admin/promotions`,
		},
		edit: {
			action: `${modelData.namespace}/edit`,
			method: 'put',
			endpoint: (options) => `/v2/admin/promotions/${options.uid}`,
		},
		get: {
			action: `${modelData.namespace}/get`,
			method: 'get',
			endpoint: (options) => `/v2/admin/promotions/${options.uid}`,
		},
	},
	views: {
		list: { name: `List${modelData.name}`, title: `${modelData.name} List` },
		create: {
			name: `Create${modelData.name}`,
			title: `Create ${modelData.name}`,
			params: (item) => {
				return { uid: item.uid };
			},
		},
		edit: {
			name: `Edit${modelData.name}`,
			title: `Edit ${modelData.name}`,
			params: (item) => {
				return { uid: item.uid };
			},
		},
		schedule: {
			name: `${modelData.name}Schedule`,
			title: `${modelData.name} Schedule`,
			params: (item) => {
				return { uid: item.uid };
			},
		},
	},
};

export default {
	namespaced: true,
	model: model,
	routes: [
		{
			path: `/${modelData.namespace}`,
			component: () => import('@/views/layouts/Layout'),
			redirect: `/${modelData.namespace}/list`,
			name: `${modelData.name}`,
			meta: { title: `${modelData.name}`, icon: `${modelData.icon}`, admin_level: 2 },
			children: [
				{
					path: 'create',
					component: () => import('@/views/basic/Form'),
					name: model.views.create.name,
					meta: { mode: 'create', title: model.views.create.title, model: model, noCache: true },
				},
				{
					path: 'edit/:uid(\\d+)',
					component: () => import('@/views/basic/Form'),
					name: model.views.edit.name,
					meta: { mode: 'edit', title: model.views.edit.title, model: model, noCache: true },
					hidden: true,
				},
				{
					path: 'list',
					component: () => import('@/views/basic/List'),
					name: model.views.list.name,
					meta: { mode: 'list', title: model.views.list.title, model: model, noCache: true },
				},
			],
		},
	],
	state: {
		cached: {},
		items: [],
	},
	mutations: {
		LIST(state, { page, size, items }) {
			items.forEach((item) => {
				state.cached[item.uid] = item;
			});

			state.items = items;
		},
		CREATE(state, { item }) {},
		EDIT(state, { item }) {},
		GET(state, { item }) {
			state.cached[item.uid] = item;
		},
	},
	actions: {
		list({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let { group } = options;
					let response = await request[model.types.list.method](model.types.list.endpoint(options), { params: options });
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					commit('LIST', data);
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		create({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.create.method](model.types.create.endpoint(options), options);
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					commit('CREATE', data);
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		edit({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.edit.method](model.types.edit.endpoint(options), options);
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					commit('EDIT', { item: data });
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		get({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.get.method](model.types.get.endpoint(options), { params: options });
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					commit('GET', { item: data });
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
	},
};
