<template>
  <div class="material-radio__component" :class="computedClasses">

    <div class="material-radio">
      <div class="material-radio-wrapper" @click="onCheck">
        <input type="radio" :id="uniqueId" :name="name" :value="value" :checked="isChecked" :disabled="disabled" />
        <MaterialRipple v-if="useRipple" :center="true" :size="46"></MaterialRipple>
      </div>
    </div>

    <label :for="uniqueId" class="material-radio-label" v-if="$slots.default" @click="onCheck"> <slot></slot> </label>
  </div>
</template>

<script>
  import MaterialRipple from 'vue-material-ripple'
  // Component declaration:
  export default {
    mounted() {
      this.$data.uniqueId = this.generateID()
    },
    beforeUmount() {
      if (this._timeout) {
        clearTimeout(this._timeout)
        this._timeout = null
      }
    },
    data() {
      return {
        uniqueId: '',
        animating: false,
        checked: this.isEqualExpectedValue(this.value)
      }
    },
    model: {
      prop: 'value',
      event: 'change'
    },
    watch: {
      value(value) {
        this.$data.checked = this.isEqualExpectedValue(value)
      },
    },
    computed: {
      isChecked() {
        return this.isEqualExpectedValue(this.value)
      },
      useRipple() {
        if (this.disabled || this.readonly) {
          return false
        }

        if (this.animating === true) {
          return this.ripple
        }

        return this.ripple && !this.checked
      },
      computedClasses () {
        return {
          'material-radio--checked': this.isChecked,
          'material-radio--disabled': this.disabled || this.readonly
        }
      }
    },
    methods: {
      generateID() {
        if (this.id === undefined || typeof String) {
          this.uniqueId = `m-readio--${Math.random().toString(36).substring(2,10)}`
        }
        else {
          this.uniqueId = this.id
        }
      },
      isEqualExpectedValue(value) {
        return typeof value !== 'undefined' && value === this.expected ? true : false
      },
      onCheck(e) {
        if (this.disabled || this.readonly) {
          return
        }

        if (this._timeout) {
          this.$data.animating = false
          clearTimeout(this._timeout)
          this._timeout = null
        }

        this.$data.animating = true
        this._timeout = setTimeout(() => {
          this._timeout = null
          this.$data.animating = false
        }, 500)

        this.$emit('change', this.expected, e)
        this.$emit('input', this.expected, e)
      }
    },
    props: {
      id: {
        type: String
      },
      name: {
        type: String
      },
      expected: {
        type: [String, Boolean, Number],
        required: true
      },
      value: {
        type: [String, Boolean, Number],
        default: undefined
      },
      disabled: {
        type: Boolean,
        default: false
      },
      readonly: {
        type: Boolean,
        default: false
      },
      ripple: {
        type: Boolean,
        default: false
      }
    },
    components: {
      MaterialRipple
    }
  }
</script>

<style lang="scss">
  // Installing ripple styles:
  @import "~vue-material-ripple/dist/style";
  // Transitions:
  // Thanks to Angular Material and vue-material
  $swift-ease-out-duration: .4s;
  $swift-ease-out-timing-function: cubic-bezier(.25, .8, .25, 1);
  $swift-ease-out: all $swift-ease-out-duration $swift-ease-out-timing-function;
  $swift-ease-in-duration: .3s !default;
  $swift-ease-in-timing-function: cubic-bezier(.55, 0, .55, .2);
  $swift-ease-in: all $swift-ease-in-duration $swift-ease-in-timing-function;
  // Sizes:
  $size-radio: 26px;
  $size-ripple: 48px;
  // Component:
  .material-radio__component {
    * {
      box-sizing: border-box;
    }
    width: auto;
    margin: 0 16px 0 0;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    user-select: none;

    &:not(.material-radio--disabled) {
      .material-radio {
        cursor: pointer;
      }

      .material-radio-label {
        cursor: pointer;
      }
    }

    &.material-radio--checked {  // Checked state
      .material-radio-wrapper {
        &:after {
          opacity: 1;
          transform: scale3D(1, 1, 1);
          transition: $swift-ease-out;
        }
      }
    }

    .material-radio {
      display: inline-block;
      vertical-align: middle;
    }

    .material-radio-wrapper {
      width: $size-radio;
      height: $size-radio;
      border-radius: 50%;
      border-width: 2px;
      border-style: solid;
      transition: $swift-ease-out;
      position: relative;
      &:after {
        position: absolute;
        top: 3px;
        right: 3px;
        bottom: 3px;
        left: 3px;
        border-radius: 50%;
        opacity: 0;
        transform: scale3D(.38, .38, 1);
        transition: $swift-ease-in;
        content: " ";
      }
      input {
        position: absolute;
        left: -999em;
      }
    }
    .material-radio-label {
      height: $size-radio;
      line-height: $size-radio;
      padding-left: 8px;
      display: inline-block;
      vertical-align: middle;
      margin: 0;
    }
    // Ripple:
    .material-ripple__component {
      margin-left: -2px;
      margin-top: -2px;
      pointer-events: none;

      &.ripple--animation {
        // This should be removed when
        // https://github.com/wemake-services/vue-material-ripple/issues/11
        // will be fixed.
        animation: ripple 1s $swift-ease-out-timing-function,
      }
    }
  }

  // Style:
  $color-dark-grey: rgba(0, 0, 0, .54);
  $color-grey: rgba(0, 0, 0, .26);
  $color-blue: #2196F3;
  .material-radio__component {
    &.material-radio--checked {  // highlighted style
      .material-radio-wrapper {
        border-color: $color-blue;
        &:after {
          background-color: $color-blue;
        }
      }
    }
    &.material-radio--disabled {  // disabled style
      .material-radio-wrapper {
        border-color: $color-grey;
        &:after {
          background-color: $color-grey;
        }
      }
    }

    .material-radio-wrapper {
      // Basic styles:
      border-color: $color-dark-grey;

      .material-radio-label {
        color: $color-grey;
      }
    }
    // Ripple:
    .material-ripple__component {
      background-color: $color-blue;
    }
  }
  @keyframes ripple {
    to {
      opacity: 0;
      transform: scale(1);
    }
  }
</style>
