const info = require('./info.json')

class Config {
  get mode() {
    return this._mode
  }

  get version() {
    return info.version
  }

  get apiServer() {
    return this._config.apiServer
  }

  get webServer() {
    return this._config.webServer
  }

  get lugstaySiteUrl() {
    return this._config.lugstaySiteUrl;
  }

  _parseConfig(options) {
    let {mode} = options;

    try {
      this._mode = mode
      this._config = require("./conf.d/config." + mode + ".json")
    } catch (e) {
      this._mode = 'production'
      this._config = require("./conf.d/config.production.json")
    }
  }

  constructor(options) {
    this._parseConfig(options)
  }

  get(name) {
    return this._config[name]
  }
}

const mode = process.env.VUE_APP_MODE || process.env.NODE_ENV
const config = new Config({
  mode: mode
})

export default config
