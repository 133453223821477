import Json from './Json.vue'

const plugin = {
  install (Vue, {loadComponent = true} = {}) {
    Vue.component('export-json', Json)
  }
}

typeof window.Vue === 'function' && window.Vue.use(plugin)

export default plugin
