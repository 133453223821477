import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ko';

const plugin = {
  install (Vue, {loadComponent = true} = {}) {
    Vue.component('datetime', DatePicker)
  }
}

typeof window.Vue === 'function' && window.Vue.use(plugin)

export default plugin
