<template>
  <div class="material-checkbox__component" :class="computedClasses" >

    <div class="material-checkbox" :data-value="this.value">
      <div class="material-checkbox-wrapper" @click="onToggle" :aria-use-ripple="useRipple">
        <div class="material-checkbox-icon">
          <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 24 24">
            <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/>
          </svg>
        </div>

        <input type="checkbox" :id="uniqueId" :name="name" :value="value" :readonly="readonly" :disabled="disabled" :checked="checked" />

        <MaterialRipple v-if="useRipple" :center="true" :size="46"></MaterialRipple>
      </div>
    </div>

    <label :for="uniqueId" class="material-checkbox-label" v-if="$slots.default" @click="onToggle"> <slot></slot> </label>
  </div>
</template>

<script>
  import MaterialRipple from 'vue-material-ripple'
  // Component declaration:
  export default {
    mounted() {
      this.$data.uniqueId = this.generateID()

      if (this.checked !== this.isChecked) {
        this.toggle()
      }
    },
    beforeUmount() {
      if (this._timeout) {
        clearTimeout(this._timeout)
        this._timeout = null
      }
    },
    data() {
      return {
        animating: false,
        uniqueId: '',
        halfed: this.isEqualIndeterminatedValue(this.value),
        checked: this.isEqualExpectedValue(this.value),
      }
    },
    model: {
      prop: 'value',
      event: 'change'
    },
    watch: {
      value(value) {
        if (typeof this.indeterminated !== 'undefined') {
          this.$data.halfed = this.isEqualIndeterminatedValue(value)
          this.$data.checked = this.isEqualExpectedValue(value)
        }
        else {
          this.$data.checked = this.isEqualExpectedValue(value)
        }
      },
      checked(value) {
        this.$emit('change', this.data)
      }
    },
    computed: {
      data() {
        if (typeof this.indeterminated !== 'undefined') {
          let halfedValue = this.halfed ? this.indeterminated : ( typeof this.expected === 'boolean' ? false :  undefined )
          return this.checked ? this.expected : halfedValue
        }

        if(typeof this.expected == 'number'){
          return this.checked ? this.expected : 0  
        }
        
        return this.checked ? this.expected : ( typeof this.expected === 'boolean' ? false :  undefined )
      },
      isChecked() {
        return this.isEqualExpectedValue(this.value)
      },
      isHalfed() {
        return this.isEqualIndeterminatedValue(this.value)
      },
      useRipple() {
        if (this.disabled || this.readonly) {
          return false
        }

        if (this.animating === true) {
          return this.ripple
        }

        return this.ripple && !this.checked && !this.halfed
      },
      computedClasses () {
        return {
          'material-checkbox--checked': this.checked,
          'material-checkbox--halfed': this.halfed,
          'material-checkbox--disabled': this.disabled || this.readonly
        }
      }
    },
    methods: {
      generateID() {
        if (this.id === undefined || typeof String) {
          this.uniqueId = `m-checkbox--${Math.random().toString(36).substring(2,10)}`
        }
        else {
          this.uniqueId = this.id
        }
      },
      isEqualExpectedValue(value) {
        return typeof value !== 'undefined' && value === this.expected ? true : false
      },
      isEqualIndeterminatedValue(value) {
        return typeof value !== 'undefined' && value === this.indeterminated ? true : false
      },
      onToggle(e) {
        if (this.disabled || this.readonly) {
          return
        }

        this.toggle( true )

        e.value = this.data
        this.$emit('click', e)
      },
      toggle( animation = false ) {
        let wasChecked = this.checked
        let wasHalfed = this.halfed

        if (typeof this.indeterminated !== 'undefined') {
          if (wasHalfed) {
            this.$data.halfed = false
            this.$data.checked = false
          }
          else {
            this.$data.halfed = false
            this.$data.checked = !wasChecked
          }
        }
        else {
          this.$data.checked = !wasChecked
        }

        if (this._timeout) {
          this.$data.animating = false
          clearTimeout(this._timeout)
          this._timeout = null
        }

        if (animation && this.checked) {
          this.$data.animating = true
          this._timeout = setTimeout(() => {
            this._timeout = null
            this.$data.animating = false
          }, 500)
        }

        this.$emit('input', this.data)
      }
    },
    props: {
      id: {
        type: String
      },
      name: {
        type: String
      },
      expected: {
        type: [String, Boolean, Number],
        default: true
      },
      indeterminated: {
        type: [String, Boolean, Number],
        default: undefined
      },
      value: {
        type: [String, Boolean, Number],
        default: undefined
      },
      readonly: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      ripple: {
        type: Boolean,
        default: false
      }
    },
    components: {
      MaterialRipple
    }
  }
</script>

<style lang="scss">
  // Installing ripple styles:
  @import "~vue-material-ripple/dist/style";
  // Transitions:
  // Thanks to Angular Material and vue-material
  $swift-ease-out-duration: .4s;
  $swift-ease-out-timing-function: cubic-bezier(.25, .8, .25, 1);
  $swift-ease-out: all $swift-ease-out-duration $swift-ease-out-timing-function;
  $swift-ease-in-duration: .3s !default;
  $swift-ease-in-timing-function: cubic-bezier(.55, 0, .55, .2);
  $swift-ease-in: all $swift-ease-in-duration $swift-ease-in-timing-function;
  // Sizes:
  $size-icon: 26px;
  $size-ripple: 48px;
  // Component:
  .material-checkbox__component {
    * {
      box-sizing: border-box;
    }
    width: auto;
    margin: 0 16px 0 0;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    user-select: none;

    &:not(.material-checkbox--disabled) {
      .material-checkbox {
        cursor: pointer;
      }

      .material-checkbox-label {
        cursor: pointer;
      }
    }

    .material-checkbox-icon {
      opacity: 0;
    }

    &.material-checkbox--halfed {  // Checked state
      .material-checkbox-wrapper {
        &:after {
          opacity: 1;
          transform: scale3D(0.8, 0.8, 0.8);
          transition: $swift-ease-out;
        }

        .material-checkbox-icon {
          opacity: 0;
        }
      }
    }

    &.material-checkbox--checked {  // Checked state
      .material-checkbox-wrapper {
        &:after {
          opacity: 1;
          transform: scale3D(1, 1, 1);
          transition: $swift-ease-out;
        }

        .material-checkbox-icon {
          opacity: 1;
        }
      }
    }

    .material-checkbox {
      display: inline-block;
      vertical-align: middle;
    }

    .material-checkbox-wrapper {
      width: $size-icon;
      height: $size-icon;
      border-radius: 1px;
      border-width: 1px;
      border-style: solid;
      transition: $swift-ease-out;
      position: relative;
      &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%; height: 100%;
        border-radius: 0;
        opacity: 0;
        z-index: 1;
        transform: scale3D(.38, .38, 1);
        transition: $swift-ease-in;
        content: " ";
      }
      input {
        position: absolute;
        left: -999em;
      }
    }
    .material-checkbox-icon {
      position: absolute;
      z-index: 2;
      width: 100%; height: 100%;
    }
    .material-checkbox-label {
      height: $size-icon;
      line-height: $size-icon;
      padding-left: 8px;
      display: inline-block;
      vertical-align: middle;
      margin: 0;
    }
    // Ripple:
    .material-ripple__component {
      margin-left: -2px;
      margin-top: -2px;
      border-radius: 0;
      pointer-events: none;

      &.ripple--animation {
        // This should be removed when
        // https://github.com/wemake-services/vue-material-ripple/issues/11
        // will be fixed.
        animation: ripple 1s $swift-ease-out-timing-function,
      }
    }
  }

  $color-border: #e5e6e7;
  $color-checked: #2196F3;
  $color-disabled: #efefef;

  .material-checkbox__component {
    &.material-checkbox--checked {  // highlighted style
      .material-checkbox-wrapper {
        border-color: $color-checked;
        &:after {
          background-color: $color-checked;
        }
      }
    }
    &.material-checkbox--halfed {  // highlighted style
      .material-checkbox-wrapper {
        border-color: $color-checked;
        &:after {
          background-color: $color-checked;
        }
      }
    }
    &.material-checkbox--disabled {  // disabled style
      .material-checkbox-wrapper {
        border-color: $color-border;
        background-color: $color-disabled;
        &:after {
          background-color: rgb(233, 236, 239);
        }
      }
    }

    .material-checkbox-wrapper {
      border-color: $color-border;

      .material-checkbox-label {
        color: rgb(103, 106, 108);
      }
    }

    // Ripple:
    .material-ripple__component {
      background-color: $color-checked;
    }
  }
  @keyframes ripple {
    to {
      opacity: 0;
      transform: scale(1);
    }
  }
</style>
