import request from '@/utils/network';
import utils from '@/utils';
import moment from 'moment';

const groups = [
	{ label: '전체', value: '' },
	{ label: '발급완료', value: 'ISSUED' },
	{ label: '체크인', value: 'CHECK_IN' },
	{ label: '체크아웃', value: 'CHECK_OUT' },
	{ label: '예약취소', value: 'CANCELLED' },
	{ label: '만료', value: 'EXPIRED' }
];

const issue_groups = [
	{ label: '후불결제', value: 'RESERVE'},
	{ label: '오픈API', value: 'RESERVE_O'},
	{ label: '선불결제', value: 'PAID'},
]

const fields = [
	{ key: 'uid', type: 'uid', label: '#', listing: true, primaryKey: true },
	{ key: 'ticket_type', type: 'text', label: 'Ticket Type', readonly: true },
	{ key: 'ticket_code', type: 'text', label: 'Ticket Code', listing: true, readonly: true },
	{
		key: 'store_name',
		type: 'text',
		label: 'Store Name',
		listing: true,
		alias: (field, row) => {
			let value = row[field.key];
			try {
				let json = JSON.parse(value || '{}');
				let keys = _.keys(json);
				return json['ko'] || json['base'] || value || '';
			} catch (e) {}
			return value || '';
		},
		hidden: true,
	},
  {
		key: 'user_name',
		type: 'text',
		label: 'User Name (User Id)',
		alias: (field, row) => {
      const reserve = utils.parseJSON(row.reserve);
			return reserve.user_first_name && reserve.user_last_name ? `${ reserve.user_first_name || ''} ${ reserve.user_last_name || ''} (${row.user_id})` : `${row.user_name}(${row.user_id})`;
		},
		listing: true,
		hidden: true,
	},
	{
		key: 'user_id',
		type: 'link',
		label: 'User ID',
		readonly: true,
		link: (item, { $router }) => {
			$router.push({
				name: 'EditUser',
				params: { uid: item.user_id },
			});
		},
	},
	{
		key: 'store_id',
		type: 'link',
		label: 'Store ID',
		readonly: true,
		link: (item, { $router }) => {
			$router.push({
				name: 'EditStore',
				params: { uid: item.store_id },
			});
		},
  },
  {
    key: 'district_main',
    type: 'text',
    label: '시/도',
    listing: true,
    nested: true,
    parentUri: 'extra'
  },
  {
    key: 'district_sub',
    type: 'text',
    label: '시/군/구',
    listing: true,
    nested: true,
    parentUri: 'extra'
  },
	{
		key: 'receipt_id',
		type: 'link',
		label: 'Receipt ID',
		readonly: true,
		link: (item, { $router }) => {
			$router.push({
				name: 'ViewReceipt',
				params: { uid: item.receipt_id },
			});
		},
	},
	{
		key: 'paid_total',
		type: 'text',
		label: '결제금액',
		alias: (field, row) => {
			if (row && row.paid_total && (parseInt(row.paid_total) > 0)) {
				return parseInt(row.paid_total).toLocaleString() + '₩'
			}

			if (row && row.payment_total && (parseInt(row.payment_total) > 0)) {
				return parseInt(row.payment_total).toLocaleString() + '₩'
			}

			return null;
		},
		listing: true,
		readonly: true
	},
	{
		key: 'promotion',
		type: 'link',
		label: '할인액',
		listing: true,
		alias: (field, row) => {
			let promotion = utils.parseJSON(row.promotion);
			return promotion.fix_amount ? `${promotion.fix_amount}₩` : '';
		}
	},
	{
		key: 'ticket_date',
		type: 'text',
		label: 'Ticket Date',
		alias: (field, row) => {
			let start = moment(row.ticket_date_start);
			let end = moment(row.ticket_date_end);
			if (start.format('YYYYMMDD') === end.format('YYYYMMDD')) {
				return `${moment(row.ticket_date_start).format('YYYY-MM-DD HH시')} ~ ${moment(row.ticket_date_end).format('HH시')}`;
			}
			return `${moment(row.ticket_date_start).format('YYYY-MM-DD HH시')} ~ ${moment(row.ticket_date_end).format('YYYY-MM-DD HH시')}`;
		},
		listing: true,
		hidden: true,
	},
	{ key: 'datetime_issued', type: 'text', label: '예약일', hidden: true, listing: true, alias: (field, row) => moment(row.datetime_issued).format('YYYY-MM-DD HH:mm') },
	{ key: 'ticket_date_start', type: 'text', label: 'Ticket Start', readonly: true },
	{ key: 'ticket_date_end', type: 'text', label: 'Ticket End', readonly: true },
	{ key: 'ticket_quantity', type: 'text', label: 'Ticket Quantity', readonly: true },
	{ key: 'ticket_over_quantity', type: 'text', label: 'Ticket Over Quantity', readonly: true },
	{ 
		key: 'issue_type', 
		type: 'text', 
		label: 'Issue Type', 
		listing: true, 		
		locale: (value) => {
		let items = issue_groups.filter((item) => item.value === value);
		return items.length > 0 ? items[0].label : value;
		},
		readonly: true 
	},

	{ key: 'datetime_issued', type: 'field', dataType: 'datetime', label: 'Issued At', format: 'YYYY.MM.DD HH:mm:ss', readonly: true },
	{ key: 'datetime_check_in', type: 'field', dataType: 'datetime', label: 'CheckIn At', format: 'YYYY.MM.DD HH:mm:ss', readonly: true },
	{ key: 'datetime_check_out', type: 'field', dataType: 'datetime', label: 'CheckOut At', format: 'YYYY.MM.DD HH:mm:ss', readonly: true },
	{ key: 'datetime_cancelled', type: 'field', dataType: 'datetime', label: 'Canceled At', format: 'YYYY.MM.DD HH:mm:ss', readonly: true },

	{
		key: 'status',
		type: 'text',
		label: 'Status',
		listing: true,
		readonly: true,
		options: _.filter(groups, (group, index) => index > 0),
		locale: (value) => {
			let items = groups.filter((item) => item.value === value);
			return items.length > 0 ? items[0].label : value;
		},
	},

	// { key: 'updated_at', type: 'datetime', label: 'Timestamp', format:'YYYY.MM.DD HH:mm:ss', listing: true, hidden: true },
	{ key: 'created_at', type: 'field', dataType: 'datetime', label: 'Created At', format: 'YYYY.MM.DD HH:mm:ss', readonly: true },
	{ key: 'updated_at', type: 'field', dataType: 'datetime', label: 'Updated At', format: 'YYYY.MM.DD HH:mm:ss', readonly: true },
	{
		key: 'action',
		type: 'action',
		label: 'Actions',
		listing: true,
		actions: [
			{
				action: 'manage',
				class: 'btn-info',
				label: '관리',
				onAction: (row, { $router, model }) => {
					$router.push({
						name: model.views.manage.name,
						params: model.views.manage.params(row),
					});
				},
      },
		],
		hidden: true,
	},
];

const modelName = 'Locker';

const modelData = {
	name: modelName,
	namespace: modelName.toLowerCase(),
	icon: 'door-closed',
};

const actions = {};

const model = {
	name: modelData.name,
	fields: fields,
	actions: actions,
	groups: groups,
	types: {
		list: {
			action: `${modelData.namespace}/list`,
			method: 'get',
			endpoint: () => '/v2/admin/locker/tickets',
		},
		get: {
			action: `${modelData.namespace}/get`,
			method: 'get',
			endpoint: (options) => `/v2/admin/locker/tickets/${options.uid}`,
		},
		checkin: {
			action: `${modelData.namespace}/checkin`,
			method: 'put',
      endpoint: (options) => `/v2/admin/locker/tickets/${options.uid}/checkin`,
      message: (options) =>  `${options.uid} 티켓을 체크인으로 처리하시겠습니까?`
		},
		checkout: {
			action: `${modelData.namespace}/checkout`,
			method: 'put',
			endpoint: (options) => `/v2/admin/locker/tickets/${options.uid}/checkout`,
		},
		checkoutAfter: {
			action: `${modelData.namespace}/checkoutAfter`,
			method: 'put',
			endpoint: (options) => `/v2/admin/locker/tickets/${options.uid}/after/checkout`,
    },
    reverseStatus: {
			action: `${modelData.namespace}/reverseStatus`,
			method: 'post',
			endpoint: (options) => `/v2/admin/locker/tickets/${options.uid}/reverseStatus`,
		},
		export: {
			action: `${modelData.namespace}/export`,
			method: 'get',
			endpoint: (options) => `/v2/admin/locker/tickets/export`,
			fields: {
				'#': 'uid',

				'Ticket Type': 'ticket_type',
				'Ticket Code': 'ticket_code',
				'Store Id': 'store_id',
				'Store Name': {
					field: 'store_name',
					callback: (value) => {
						try {
							let json = JSON.parse(value || '{}');
							let keys = _.keys(json);
							return json['ko'] || json['base'] || value || '';
						} catch (e) {}
						return value || '';
					},
				},

				// 'Store Market Place': 'store_market_place',
				// 'Store Area Name': 'store_area_name',
				'Store Nearest Station': 'store_nearest_station',
				'Store Type': 'store_type',

				'User Name': 'user_name',
				'User Email': {
					field: 'reserve',
					callback: (value) => {
						return utils.parseJSON(value).user_email;
					},
				},
				'User Phone': {
					field: 'reserve',
					callback: (value) => {
						return utils.parseJSON(value).user_phone;
					},
				},
				// 'Paid TImes': 'paid_times',

				'Paid Unit': 'paid_unit',
				'Paid Total Amount': 'paid_total',

				'Paid Datetime': {
					field: 'paid_at',
					callback: (value) => {
						return moment(value)
							.tz('Asia/Seoul')
							.format('YYYY-MM-DD HH:mm:ss');
					},
				},
				'Paid Year': {
					field: 'paid_at',
					callback: (value) => {
						return moment(value)
							.tz('Asia/Seoul')
							.format('YYYY');
					},
				},
				'Paid Month': {
					field: 'paid_at',
					callback: (value) => {
						return moment(value)
							.tz('Asia/Seoul')
							.format('MM');
					},
				},
				'Paid Day': {
					field: 'paid_at',
					callback: (value) => {
						return moment(value)
							.tz('Asia/Seoul')
							.format('DD');
					},
				},
				// 'Paid WeekName': {
				//   field: 'paid_at',
				//   callback: (value) => {
				//     let week = moment(value).tz("Asia/Seoul").weekday()
				//     let weekNames = ['SUN','MON','TUE','WED','THU','FRI','SAT']
				//     return weekNames[week]
				//   }
				// },
				'Paid Time': {
					field: 'paid_at',
					callback: (value) => {
						return moment(value)
							.tz('Asia/Seoul')
							.format('HH:mm:ss');
					},
				},
				'Paid Week': {
					field: 'paid_at',
					callback: (value) => {
						return moment(value)
							.tz('Asia/Seoul')
							.format('W');
					},
				},

				'Ticket Start': 'ticket_date_start',

				'Ticket Start Year': {
					field: 'ticket_date_start',
					callback: (value) => {
						return moment(value)
							.tz('Asia/Seoul')
							.format('YYYY');
					},
				},

				'Ticket Start Month': {
					field: 'ticket_date_start',
					callback: (value) => {
						return moment(value)
							.tz('Asia/Seoul')
							.format('MM');
					},
				},
				'Ticket Start Day': {
					field: 'ticket_date_start',
					callback: (value) => {
						return moment(value)
							.tz('Asia/Seoul')
							.format('DD');
					},
				},
				'Ticket Start WeekName': {
					field: 'ticket_date_start',
					callback: (value) => {
						let week = moment(value)
							.tz('Asia/Seoul')
							.weekday();
						let weekNames = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
						return weekNames[week];
					},
				},
				'Ticket Start Time': {
					field: 'ticket_date_start',
					callback: (value) => {
						return moment(value)
							.tz('Asia/Seoul')
							.format('HH:mm:ss');
					},
				},
				'Ticket Start Week': {
					field: 'ticket_date_start',
					callback: (value) => {
						return moment(value)
							.tz('Asia/Seoul')
							.format('W');
					},
				},

				'Ticket End': 'ticket_date_end',

				'Ticket End Year': {
					field: 'ticket_date_end',
					callback: (value) => {
						return moment(value)
							.tz('Asia/Seoul')
							.format('YYYY');
					},
				},
				'Ticket End Month': {
					field: 'ticket_date_end',
					callback: (value) => {
						return moment(value)
							.tz('Asia/Seoul')
							.format('MM');
					},
				},
				'Ticket End Day': {
					field: 'ticket_date_end',
					callback: (value) => {
						return moment(value)
							.tz('Asia/Seoul')
							.format('DD');
					},
				},
				'Ticket End WeekName': {
					field: 'ticket_date_end',
					callback: (value) => {
						let week = moment(value)
							.tz('Asia/Seoul')
							.weekday();
						let weekNames = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
						return weekNames[week];
					},
				},
				'Ticket End Time': {
					field: 'ticket_date_end',
					callback: (value) => {
						return moment(value)
							.tz('Asia/Seoul')
							.format('HH:mm:ss');
					},
				},
				'Ticket End Week': {
					field: 'ticket_date_end',
					callback: (value) => {
						return moment(value)
							.tz('Asia/Seoul')
							.format('W');
					},
				},

				'Ticket Quantity': 'ticket_quantity',
				// 'Ticket Over Quantity': 'ticket_over_quantity',
				'Issue Type': 'issue_type',
				'Reservation Time': {
					field: 'reservation_time',
					callback: (value) => {
						if (value === '') return;
						if (value < 60) return `${value}분`;
						return `${parseInt(value / 60)}시간 ${value % 60}분`;
					},
				},
				'Usage Time': {
					field: 'usage_time',
					callback: (value) => {
						if (value === '') return;
						if (value < 60) return `${value}분`;
						return `${parseInt(value / 60)}시간 ${value % 60}분`;
					},
				},
				'Issued At': {
					field: 'datetime_issued',
					callback: (value) => {
						return (
							moment(value)
								.tz('Asia/Seoul')
								.format('YYYY-MM-DD HH:mm:ss') || ''
						);
					},
				},
				'CheckIn At': {
					field: 'datetime_check_in',
					callback: (value) => {
						return (
							(value &&
								moment(value)
									.tz('Asia/Seoul')
									.format('YYYY-MM-DD HH:mm:ss')) ||
							''
						);
					},
				},

				'CheckIn At Year': {
					field: 'datetime_check_in',
					callback: (value) => {
						return (
							(value &&
								moment(value)
									.tz('Asia/Seoul')
									.format('YYYY')) ||
							''
						);
					},
				},
				'CheckIn At Month': {
					field: 'datetime_check_in',
					callback: (value) => {
						return (
							(value &&
								moment(value)
									.tz('Asia/Seoul')
									.format('MM')) ||
							''
						);
					},
				},
				'CheckIn At Day': {
					field: 'datetime_check_in',
					callback: (value) => {
						return (
							(value &&
								moment(value)
									.tz('Asia/Seoul')
									.format('DD')) ||
							''
						);
					},
				},
				'CheckIn At WeekName': {
					field: 'datetime_check_in',
					callback: (value) => {
						if (value) {
							let week = moment(value)
								.tz('Asia/Seoul')
								.weekday();
							let weekNames = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
							return weekNames[week];
						}
						return '';
					},
				},
				'CheckIn At Time': {
					field: 'datetime_check_in',
					callback: (value) => {
						return (
							(value &&
								moment(value)
									.tz('Asia/Seoul')
									.format('HH:mm:ss')) ||
							''
						);
					},
				},
				'CheckIn At Week': {
					field: 'datetime_check_in',
					callback: (value) => {
						return (
							(value &&
								moment(value)
									.tz('Asia/Seoul')
									.format('W')) ||
							''
						);
					},
				},
				'CheckOut At': {
					field: 'datetime_check_out',
					callback: (value) => {
						return (
							(value &&
								moment(value)
									.tz('Asia/Seoul')
									.format('YYYY-MM-DD HH:mm:ss')) ||
							''
						);
					},
				},

				'CheckOut At Year': {
					field: 'datetime_check_out',
					callback: (value) => {
						return (
							(value &&
								moment(value)
									.tz('Asia/Seoul')
									.format('YYYY')) ||
							''
						);
					},
				},
				'CheckOut At Month': {
					field: 'datetime_check_out',
					callback: (value) => {
						return (
							(value &&
								moment(value)
									.tz('Asia/Seoul')
									.format('MM')) ||
							''
						);
					},
				},
				'CheckOut At Day': {
					field: 'datetime_check_out',
					callback: (value) => {
						return (
							(value &&
								moment(value)
									.tz('Asia/Seoul')
									.format('DD')) ||
							''
						);
					},
				},
				'CheckOut At WeekName': {
					field: 'datetime_check_out',
					callback: (value) => {
						if (value) {
							let week = moment(value)
								.tz('Asia/Seoul')
								.weekday();
							let weekNames = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
							return weekNames[week];
						}
						return '';
					},
				},
				'CheckOut At Time': {
					field: 'datetime_check_out',
					callback: (value) => {
						return (
							(value &&
								moment(value)
									.tz('Asia/Seoul')
									.format('HH:mm:ss')) ||
							''
						);
					},
				},
				'CheckOut At Week': {
					field: 'datetime_check_out',
					callback: (value) => {
						return (
							(value &&
								moment(value)
									.tz('Asia/Seoul')
									.format('W')) ||
							''
						);
					},
				},
				'Canceled At': {
					field: 'datetime_cancelled',
					callback: (value) => {
						return (
							(value &&
								moment(value)
									.tz('Asia/Seoul')
									.format('YYYY-MM-DD HH:mm:ss')) ||
							''
						);
					},
				},
				'Payment Region': 'payment_region',
				'Payment Method': 'payment_method',
				'Reserve Locale': 'reserve_language',
				Status: 'status',
				Memo: 'memo',
				'created At': {
					field: 'created_at',
					callback: (value) => {
						return moment(value)
							.tz('Asia/Seoul')
							.format('YYYY-MM-DD HH:mm:ss');
					},
				},
				'updated At': {
					field: 'updated_at',
					callback: (value) => {
						return moment(value)
							.tz('Asia/Seoul')
							.format('YYYY-MM-DD HH:mm:ss');
					},
				},
			},
		},
	},
	views: {
		list: { name: `List${modelData.name}`, title: `${modelData.name} List` },
		view: {
			name: `View${modelData.name}`,
			title: `View ${modelData.name}`,
			params: (item) => {
				return { uid: item.uid };
			},
		},
		manage: {
			name: `Manage${modelData.name}`,
			title: `Manage ${modelData.name}`,
			params: (item) => {
				return { uid: item.uid };
			},
		},
	},
};

export default {
	namespaced: true,
	model: model,
	routes: [
		{
			path: `/${modelData.namespace}`,
			component: () => import('@/views/layouts/Layout'),
			redirect: `/${modelData.namespace}/list`,
			name: `${modelData.name}`,
			meta: { title: `${modelData.name}`, icon: `${modelData.icon}` },
			children: [
				{
					path: 'view/:uid(\\d+)',
					component: () => import('@/views/basic/Form'),
					name: model.views.view.name,
					meta: { mode: 'view', title: model.views.view.title, model: model, noCache: true },
					hidden: true,
				},
				{
					path: 'manage/:uid(\\d+)',
					component: () => import('@/views/pages/locker/Manage'),
					name: model.views.manage.name,
					meta: { mode: 'manage', title: model.views.manage.title, model: model, noCache: true },
					hidden: true,
				},
				{
					path: 'list',
					component: () => import('@/views/basic/List'),
					name: model.views.list.name,
					meta: { mode: 'list', title: model.views.list.title, model: model, noCache: true },
				},
			],
		},
	],
	state: {
		cached: {},
		items: [],
	},
	mutations: {
		LIST(state, { page, size, items }) {
			items.forEach((item) => {
				state.cached[item.uid] = item;
			});

			state.items = items;
		},
		CREATE(state, { item }) {},
		EDIT(state, { item }) {},
		GET(state, { item }) {
			state.cached[item.uid] = item;
		},
	},
	actions: {
		list({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let { group } = options;
          let response = await request[model.types.list.method](model.types.list.endpoint(options), { params: { status: group, ...options } });
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					commit('LIST', data);
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		create({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.create.method](model.types.create.endpoint(options), options);
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					commit('CREATE', data);
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		edit({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.edit.method](model.types.edit.endpoint(options), options);
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					commit('EDIT', { item: data });
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		get({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.get.method](model.types.get.endpoint(options), { params: options });
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					commit('GET', { item: data });
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		checkin({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.checkin.method](model.types.checkin.endpoint(options), options);
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		checkout({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.checkout.method](model.types.checkout.endpoint(options), options);
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
		checkoutAfter({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.checkoutAfter.method](model.types.checkoutAfter.endpoint(options), options);
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					resolve(data);
				} catch (e) {
					reject(e);
				}
      });
    },
    reverseStatus({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.reverseStatus.method](model.types.reverseStatus.endpoint(options), options);
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					resolve(data);
				} catch (e) {
					reject(e);
				}
      });
    },
		export({ commit, rootGetters }, options) {
			return new Promise(async (resolve, reject) => {
				try {
					let response = await request[model.types.export.method](model.types.export.endpoint(options), { params: options });
					let { error, data } = response.data;
					if (error) {
						return reject(new Error(error));
					}
					resolve(data);
				} catch (e) {
					reject(e);
				}
			});
		},
	},
};
