
import request from '@/utils/network'

const SET_AUTHENTICATED = "SET_AUTHENTICATED"
const SET_PROFILE = "SET_PROFILE"
const LOGOUT = "LOGOUT"

export default {
  namespaced: true,
  state: {
    isAuthenticated: localStorage.accessToken ? true : false,
    profile: localStorage.cachedProfile ? JSON.parse(localStorage.cachedProfile) : {}
  },
  mutations: {
    [SET_AUTHENTICATED](state, { isAuthenticated }) {
      if (isAuthenticated) {
        state.isAuthenticated = isAuthenticated
      } else {
        state.isAuthenticated = false
      }
    },
    [SET_PROFILE](state, { profile, access_token }) {
      if (access_token) {
        localStorage.accessToken = access_token
      }

      if (profile) {
        localStorage.cachedProfile = JSON.stringify(profile)
        state.profile = profile
      } else {
        state.profile = {}
      }
    },
    [LOGOUT](state) {
      localStorage.removeItem("eleToken")
      state.isAuthenticated = false
    }
  },
  actions: {
    verify({commit, rootGetters}, options) {
      return new Promise(async (resolve, reject) => {
        try {
          let response = await request.put(`/v2/admin/verify`, options)
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }

          commit(SET_PROFILE, data)
          return resolve(data)
        }
        catch(e) {
          return reject(e)
        }
      })
    },
    login({commit, rootGetters}, options) {
      return new Promise(async (resolve, reject) => {
        try {
          let response = await request.post(`/v2/admin/login`, options)
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }

          commit(SET_PROFILE, data)
          return resolve(data)
        }
        catch(e) {
          return reject(e)
        }
      })
    },
    logout({commit, getters}, options) {
      return new Promise(async (resolve, reject) => {
        try {
          commit(LOGOUT, {})
          return resolve({})
        }
        catch(e) {
          return reject(e)
        }
      })
    },
    setAuthenticated({ commit }, options) {
      commit(SET_AUTHENTICATED, options)
    },
    setProfile({ commit }, options) {
      commit(SET_PROFILE, options)
    }
  }
}
