import _ from 'lodash'
import request from '@/utils/network'

const groups = [
  { label: "전체", value: "" },
  { label: "Keyword", value: "keyword" },
  { label: "Address", value: "address" }
]

const fields = [
  { key: 'uid', type: 'text', label: '#', listing: true, hidden: true },
  { key: 'keyword', type: 'text', label: 'Keyword', listing: true, editable: false },
  { key: 'keyword_group', type: 'text', label: 'Keyword Group', listing: true, editable: false },
  { key: 'keyword_reference', type: 'text', label: 'Reference', listing: true, hidden: true },
  { key: 'zoom', type: 'text', label: 'Zoom', listing: true, description: '' },

  { key: 'latitude', type: 'text', label: 'Latitude', description: '' },
  { key: 'longitude', type: 'text', label: 'Longitude', description: '' },

  { key: 'created_at', type: 'text', label: 'Created At', format:'YYYY.MM.DD HH:mm:ss', readonly: true },
  { key: 'updated_at', type: 'text', label: 'Updated At', format:'YYYY.MM.DD HH:mm:ss', readonly: true },
  { key: 'action', type: 'action', label: 'Actions', listing: true, actions: ["edit", "delete"] }
]

const modelName = "Keyword"

const modelData = {
  name: modelName,
  namespace: modelName.toLowerCase(),
  icon: 'file-alt'
}

const actions = {
  list: ['create'],
  form: ['submit']
}

const model = {
  name: modelData.name,
  fields: fields,
  actions: actions,
  groups: groups,
  getters: {
    items: ( component ) => component.$store.getters[`${modelData.namespace}/GET_ITEMS`],
    total: ( component ) => component.$store.getters[`${modelData.namespace}/GET_TOTAL`],
  },
  types: {
    list: {
      action: `${modelData.namespace}/list`,
      method: 'get',
      endpoint: () => '/v2/admin/keywords'
    },
    create: {
      action: `${modelData.namespace}/create`,
      method: 'post',
      endpoint: () => `/v2/admin/keywords`
    },
    edit: {
      action: `${modelData.namespace}/edit`,
      method: 'put',
      endpoint: (options) => `/v2/admin/keywords/${options.uid}`
    },
    get: {
      action: `${modelData.namespace}/get`,
      method: 'get',
      endpoint: (options) => `/v2/admin/keywords/${options.uid}`
    },
    delete: {
      action: `${modelData.namespace}/delete`,
      method: 'delete',
      endpoint: (options) => `/v2/admin/keywords/${options.uid}`
    },
  },
  views: {
    list: { name: `List${modelData.name}`, title: `${modelData.name} List` },
    create: { name: `Create${modelData.name}`, title: `Create ${modelData.name}`, params: (item) => { return { uid: item.uid } } },
    edit: { name: `Edit${modelData.name}`, title: `Edit ${modelData.name}`, params: (item) => { return { uid: item.uid } } }
  }
}

export default {
  namespaced: true,
  model: model,
  routes: [
    {
      path: `/${modelData.namespace}`,
      component: () => import('@/views/layouts/Layout'),
      redirect: `/${modelData.namespace}/list`,
      name: `${modelData.name}`,
      meta: { title: `${modelData.name}`, icon: `${modelData.icon}`, admin_level: 2 },
      children: [
        {
          path: 'create',
          component: () => import('@/views/basic/Form'),
          name: model.views.create.name,
          meta: { mode: 'create', title: model.views.create.title, model: model, noCache: true }
        },
        {
          path: 'edit/:uid(\\d+)',
          component: () => import('@/views/basic/Form'),
          name: model.views.edit.name,
          meta: { mode: 'edit', title: model.views.edit.title, model: model, noCache: true },
          hidden: true
        },
        {
          path: 'list',
          component: () => import('@/views/basic/List'),
          name: model.views.list.name,
          meta: { mode: 'list', title: model.views.list.title, model: model, noCache: true }
        }
      ]
    }
  ],
  state: {
    total: 0,
    items: []
  },
  mutations: {
    LIST(state, { items, total }) {
      state.items = items
      state.total = total
    }
  },
  getters: {
    GET_ITEMS: ( state ) => {
      return state.items;
    },
    GET_TOTAL: ( state ) => {
      return state.total;
    }
  },
  actions: {
    list({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let { group } = options
          let response = await request[model.types.list.method](model.types.list.endpoint(options), { params: options })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          commit('LIST', data)
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    create({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.create.method](model.types.create.endpoint(options), options)
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          commit('CREATE', data)
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    edit({commit, rootGetters}, options) {
      options["latitude"] = Number(options.latitude)
      options["longitude"] = Number(options.longitude)
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.edit.method](model.types.edit.endpoint(options), options)
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          data["latitude"] = data.extras.latitude
          data["longitude"] = data.extras.longitude
          commit('EDIT', { item: data })
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    get({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.get.method](model.types.get.endpoint(options), { params: options })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          data["latitude"] = data.extras.latitude
          data["longitude"] = data.extras.longitude
          commit('GET', { item: data })
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    delete({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.delete.method](model.types.delete.endpoint(options), { params: options })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
  }
}
