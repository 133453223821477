import _ from 'lodash'
import request from '@/utils/network'
import moment from 'moment'

const groups = [
  { label: "전체", value: "" },
  { label: "개인", value: "individual" },
  { label: "회사", value: "company" }
]

const types = [
  { label: "리워드", value: "REWARD" },
  { label: "친구초대", value: "FRIEND"}
]

const fields = [
  { key: 'uid', type: 'uid', label: '#', listing: true, primaryKey: true },
  { key: 'user_id', type: 'user-select', label: 'User ID', listing: true },
  { key: 'campaign_type', type: 'text', label: 'Campaign Type', readonly: true, listing: true,
    alias: (field, row) => {
      let type = row.campaign_type
      let items = types.filter((item) => item.value === type);
      let result = items.length > 0 ? items[0].label : type;
      return result || type;
    }
  },

  { key: 'group_type', type: 'text', label: 'Group Type', listing: true, hidden: true, 
    alias: (field, row) => {
      let type = ''
      if(row.extras.group_type) {
        type = row.extras.group_type
      }
      if(type === '') return ''
      let items = groups.filter((item) => item.value === type);
      let result = items.length > 0 ? items[0].label : type;
      return result || type;
    }
  },
  { key: 'group_name', type: 'text', label: 'Group Name', listing: true, hidden: true, alias: "extras.group_name" },
  { key: 'group', type: 'text', defaultValue: 'invite', listing: false, hidden: true },

  { key: 'campaign_code', type: 'text', label: 'Campaign Code', readonly: true, listing: true },
  { key: 'campaign_blueprint_id', type: 'text', label: 'Campaign Blueprint ID', readonly: true, listing: true },

  { key: 'extras', type: 'extra-editor', label: 'Extras',
    fields: [
      { name: 'group_type', type: 'select', label: 'Group Type',  options: [
        { label: "개인", value: "individual"},
        { label: "기업", value: "company"}
      ], defaultValue: 'individual' },
      { name: 'group_name', type: 'text', label: 'Group Name' },
      { name: 'campaign_type', type: 'select', label: 'Campaign Type',  options: [
        { label: '친구초대', value: 'FRIEND'},
        { label: '리워드', value: 'REWARD'}
      ], defaultValue: 'REWARD' },
      { name: 'host_promotion_id', type: 'promotion-select', label: 'Promotion ID - host' },
      { name: 'promotion_id', type: 'promotion-select', label:'Promotion ID - invitee' }
    ]
  },

  { key: 'joined_at', type: 'datetime', label: 'Joined', format:'YYYY.MM.DD HH:mm:ss', listing: true, hidden: true },
  { key: 'created_at', type: 'datetime', label: 'Created', format:'YYYY.MM.DD HH:mm:ss', listing: true, hidden: true },
  { key: 'link', type: 'text', label: 'Link', listing: true, hidden: true },
  { key: 'user_name', type: 'text', label: 'User Name', listing: true, hidden: true },
  { key: 'user_email', type: 'text', label: 'Email', listing: true, hidden: true },
  { key: 'user_phone', type: 'text', label: 'Phone', listing: true, hidden: true },

  { key: 'invited', type: 'text', label: 'Invited', listing: true, hidden: true },
  { key: 'ordered', type: 'text', label: 'Ordered', listing: true, hidden: true },

  // { key: 'total_unit', type: 'text', label: 'Total Unit', listing: true, hidden: true },
  { key: 'total_paid', type: 'text', label: 'Total Paid', listing: true, hidden: true },

  { key: 'action', type: 'action', label: 'Actions', listing: true, actions: ["edit", {
    action: 'view',
    class: 'btn-info',
    label: '내역',
    onAction: (row, { $router, model }) => {
      $router.push({
        name: model.views.view.name,
        params: model.views.view.params(row),
        query: { code : row.campaign_code }
      })
    },
  }], hidden: true }
]

const modelName = "Invite"

const modelData = {
  name: modelName,
  namespace: modelName.toLowerCase(),
  icon: 'user-friends'
}

const actions = {
  list: ['create'],
  form: ['submit']
}

const model = {
  name: modelData.name,
  fields: fields,
  actions: actions,
  groups: groups,
  types: {
    list: {
      action: `${modelData.namespace}/list`,
      method: 'get',
      endpoint: () => '/v2/admin/campaign'
    },
    create: {
      action: `${modelData.namespace}/create`,
      method: 'post',
      endpoint: () => `/v2/admin/campaign`
    },
    edit: {
      action: `${modelData.namespace}/edit`,
      method: 'put',
      endpoint: (options) => `/v2/admin/campaign/${options.uid}`
    },
    get: {
      action: `${modelData.namespace}/get`,
      method: 'get',
      endpoint: (options) => `/v2/admin/campaign/${options.uid}`
    },
    invitedList: {
      action: `${modelData.namespace}/invitedList`,
      method: 'get',
      endpoint: (options) => `/v2/admin/campaign/${options.uid}/invites`
    },
    paidList: {
      action: `${modelData.namespace}/paidList`,
      method: 'get',
      endpoint: (options) => `/v2/admin/campaign/${options.uid}/paid`
    },
    export: {
      action: `${modelData.namespace}/export`,
      method: 'get',
      endpoint: (options) => `/v2/admin/campaign/export`,
      fields: {
        '#': 'uid',
        'User ID': 'user_id',
        'User Name': 'user_name',
        'Group Type': 'group_type',
        'Group Name': 'group_name',
        'Campaign Code': 'campaign_code',
        'Campaign Blueprint ID': 'campaign_blueprint_id',
        'Joined': {
          field: 'joined_at',
          callback: (value) => {
            return moment(value).tz("Asia/Seoul").format("YYYY-MM-DD HH:mm:ss")
          }
        },
        'Created': {
          field: 'created_at',
          callback: (value) => {
            return moment(value).tz("Asia/Seoul").format("YYYY-MM-DD HH:mm:ss")
          }
        },
        'Link': 'link',
        'Email': 'user_email',
        'Phone': 'user_phone',
        'Invited': 'invited',
        'Ordered': 'ordered',
        'Total Unit': 'total_unit',
        'Total Paid': 'total_paid',
        'created At': {
          field: 'created_at',
          callback: (value) => {
            return moment(value).tz("Asia/Seoul").format("YYYY-MM-DD HH:mm:ss")
          }
        },
        'updated At': {
          field: 'updated_at',
          callback: (value) => {
            return moment(value).tz("Asia/Seoul").format("YYYY-MM-DD HH:mm:ss")
          }
        }
      }
    },
    invitedExport: {
      action: `${modelData.namespace}/invitedExport`,
      method: 'get',
      endpoint: (options) => `/v2/admin/campaign/${options.uid}/invites/export`,
      fields: {
        '#': 'uid',
        'User ID': 'user_id',
        'User Name': 'user_name',
        'User Email': 'user_email',
        'User Phone': 'user_phone',
        'Campaign Code': 'campaign_code',
        'Invited At': {
          field: 'invited_at',
          callback: (value) => {
            return moment(value).tz("Asia/Seoul").format("YYYY-MM-DD HH:mm:ss")
          }
        },
      }
    },
    paidExport: {
      action: `${modelData.namespace}/invitedExport`,
      method: 'get',
      endpoint: (options) => `/v2/admin/campaign/${options.uid}/paid/export`,
      fields: {
        'User ID': 'user_id',
        'User Name': 'user_name',
        'User Phone': 'user_phone',
        'Ticket ID': 'ticket_id',
        'Ticket Status': 'ticket_status',
        'Reserved At': 'reserved_at',
        'Ticket Date': 'ticket_date',
        'Paid Total': 'paid_total'
      }
    }
  },
  views: {
    list: { name: `List${modelData.name}`, title: `${modelData.name} List` },
    create: { name: `Create${modelData.name}`, title: `Create ${modelData.name}`, params: (item) => { return { uid: item.uid } } },
    edit: { name: `Edit${modelData.name}`, title: `Edit ${modelData.name}`, params: (item) => { return { uid: item.uid } } },
    view: { name: `View${modelData.name}`, title: `View ${modelData.name}`, params: (item) => { return { uid: item.uid, code : item.campaign_code } } }
  }
}

export default {
  namespaced: true,
  model: model,
  routes: [
    {
      path: `/${modelData.namespace}`,
      component: () => import('@/views/layouts/Layout'),
      redirect: `/${modelData.namespace}/list`,
      name: `${modelData.name}`,
      meta: { title: `${modelData.name}`, icon: `${modelData.icon}`, admin_level: 2 },
      children: [
        {
          path: 'create',
          component: () => import('@/views/basic/Form'),
          name: model.views.create.name,
          meta: { mode: "create", title: model.views.create.title, model: model, noCache: true }
        },
        {
          path: 'edit/:uid(\\d+)',
          component: () => import('@/views/basic/Form'),
          name: model.views.edit.name,
          meta: { mode: "edit", title: model.views.edit.title, model: model, noCache: true },
          hidden: true
        },
        {
          path: 'list',
          component: () => import('@/views/basic/List'),
          name: model.views.list.name,
          meta: { mode: "list", title: model.views.list.title, model: model, noCache: true }
        },
        {
          path: 'view/:uid(\\d+)',
          component: () => import('@/views/pages/invite/View'),
          name: model.views.view.name,
          meta: { mode: "view", title: model.views.view.title, model: model, noCache: true },
          hidden: true,
        },
      ]
    }
  ],
  state: {
    cached: {},
    items: []
  },
  mutations: {
    LIST(state, { page, size, items }) {
      items.forEach(item => {
        state.cached[item.uid] = item
      })

      state.items = items
    },
    CREATE(state, { item }) {

    },
    EDIT(state, { item }) {

    },
    GET(state, { item }) {
      state.cached[item.uid] = item
    }
  },
  actions: {
    list({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let { group } = options
          let response = await request[model.types.list.method](model.types.list.endpoint(options), { params: { group_type: group, ...options } })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          commit('LIST', data)
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    create({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.create.method](model.types.create.endpoint(options), options)
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          commit('CREATE', data)
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    edit({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.edit.method](model.types.edit.endpoint(options), options)
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          commit('EDIT', { item: data })
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    get({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.get.method](model.types.get.endpoint(options), { params: options })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          commit('GET', { item: data })
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    export({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.export.method](model.types.export.endpoint(options), { params: options })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    invitedList({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let { group } = options
          let response = await request[model.types.invitedList.method](model.types.invitedList.endpoint(options), { params: { group_type: group, ...options } })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    invitedExport({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let response = await request[model.types.invitedExport.method](model.types.invitedExport.endpoint(options), { params: options })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    paidList({commit, rootGetters}, options) {
      return new Promise( async (resolve, reject) => {
        try {
          let { group } = options
          let response = await request[model.types.paidList.method](model.types.paidList.endpoint(options), { params: { group_type: group, ...options } })
          let { error, data } = response.data
          if (error) {
            return reject(new Error(error))
          }
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
  }
}
